export const deathCause = [
  { label: "Death certificate", Value: "Death certificate" },
  {
    label: "NHS Central Register Follow-up",
    Value: "NHS Central Register Follow-up",
  },
  { label: "Hospital records", Value: "Hospital records" },
  { label: "Verbal communication", Value: "Verbal communication" },
  { label: "Post mortem", Value: "Post mortem" },
];

export const deathLocation = [
  { label: "Hospital", Value: "Hospital" },
  { label: "Private Residence", Value: "Private Residence" },
  { label: "PATIENT's own home", Value: "PATIENT's own home" },
  {
    label: "Other private residence (e.g. relative's home, Carer's home)",
    Value: "Other private residence (e.g. relative's home, Carer's home)",
  },
  { label: "Hospice", Value: "Hospice" },
  { label: "Care_Home", Value: "Care_Home" },
  { label: "Care_Home_With_Nursing", Value: "Care_Home_With_Nursing" },
  { label: "Care_Home_Without_Nursing", Value: "Care_Home_Without_Nursing" },
  { label: "Other (not listed)", Value: "Other (not listed)" },
];

export const deathNoPrefered = [
  {
    label: "Family decided to move PATIENT to hospital",
    Value: "Family decided to move PATIENT to hospital",
  },
  {
    label: "PATIENT was moved to hospital for clinical reasons",
    Value: "PATIENT was moved to hospital for clinical reasons",
  },
  { label: "PATIENT changed their mind", Value: "PATIENT changed their mind" },
  {
    label: "Capacity not available at preferred LOCATION",
    Value: "Capacity not available at preferred LOCATION",
  },
  { label: "Transfer delays", Value: "Transfer delays" },
  { label: "Social support issues", Value: "Social support issues" },
  {
    label: "Need for access to adequate pain relief",
    Value: "Need for access to adequate pain relief",
  },
  { label: "Other (not listed)", Value: "Other (not listed)" },
];

export const deathIndicator = [
  {
    label: "Yes - PATIENT at risk of unexpected death",
    Value: "Yes - PATIENT at risk of unexpected death",
  },
  {
    label: "No - PATIENT not at risk of unexpected death",
    Value: "No - PATIENT not at risk of unexpected death",
  },
];

export const locationIndicator = [
  {
    label:
      "Yes - the preferred LOCATION of death was discussed with the PATIENT or Patient_Proxy",
    Value:
      "Yes - the preferred LOCATION of death was discussed with the PATIENT or Patient_Proxy",
  },
  {
    label:
      "No - the preferred LOCATION of death was not discussed with the PATIENT or Patient_Proxy",
    Value:
      "No - the preferred LOCATION of death was not discussed with the PATIENT or Patient_Proxy",
  },
];
