import React, { useEffect, useState, useRef } from "react";
import { AddressFinder } from "@ideal-postcodes/address-finder";

export default function AwsLocation(props) {
  const { awsLocationHandler, defaultVal, placeholder } = props;

  const textInput = useRef();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    AddressFinder.setup({
      inputField: textInput.current,
      apiKey: "ak_kzmts5lkFMX16SRbizwvv5A1rNiLI",
      onAddressRetrieved: (address) => {
        console.log(address, 'fullAddress');
        let fullAddress = `${address.line_1}, ${address.post_town}, ${address.postcode}, ${address.native?.order8_name ?? address.country}`
        setSearchValue(fullAddress);
        awsLocationHandler({
          Label: fullAddress,
          latitude: address.latitude,
          longitude: address.longitude,
          uprn: address.uprn,
        })
      },
      // apiKey: "ak_test",
    });
    if (defaultVal) {
      setSearchValue(defaultVal);
    }
  }, []);

  function handleSearchLocation(value) {
    setSearchValue(value);
  }

  return (
    <>
      <div className="position-relative">
        <input
          type="text"
          className="form-control react-dropdown"
          placeholder={placeholder ?? "Search Location"}
          value={searchValue}
          ref={textInput}
          onChange={(e) => handleSearchLocation(e.target.value)}
        />
      </div>
    </>
  );
}

