export const relationshipValues = {
  Personal: [
    { value: "CHLDADOPT", label: "adopted child" },
    { value: "DAUADOPT", label: "adopted daughter" },
    { value: "SONADOPT", label: "adopted son" },
    { value: "ADOPTF", label: "adoptive father" },
    { value: "ADOPTM", label: "adoptive mother" },
    { value: "ADOPTP", label: "adoptive parent" },
    { value: "Agent", label: "Agent of patient" },
    { value: "AUNT", label: "aunt" },
    { value: "BP", label: "Billing contact person" },
    { value: "BRO", label: "brother" },
    { value: "BROINLAW", label: "brother-in-law" },
    { value: "CARER", label: "Carer of patient" },
    { value: "CHILD", label: "child" },
    { value: "CHLDINLAW", label: "child-in-law" },
    { value: "CP", label: "Contact person" },
    { value: "COUSN", label: "cousin" },
    { value: "DAUC", label: "daughter" },
    { value: "DAUINLAW", label: "daughter in-law" },
    { value: "Dependant", label: "Dependant of patient" },
    { value: "DOMPART", label: "domestic partner" },
    { value: "C", label: "Emergency Contact" },
    { value: "EP", label: "Emergency contact person" },
    { value: "E", label: "Employer" },
    { value: "EXT", label: "extended family member" },
    { value: "FAMMEMB", label: "family member" },
    { value: "FTH", label: "father" },
    { value: "FTHINLAW", label: "father-in-law" },
    { value: "FMRSPS", label: "former spouse" },
    { value: "CHLDFOST", label: "foster child" },
    { value: "DAUFOST", label: "foster daughter" },
    { value: "FTHFOST", label: "foster father" },
    { value: "MTHFOST", label: "foster mother" },
    { value: "PRNFOST", label: "foster parent" },
    { value: "SONFOST", label: "foster son" },
    { value: "FTWIN", label: "fraternal twin" },
    { value: "FTWINBRO", label: "fraternal twin brother" },
    { value: "FTWINSIS", label: "fraternal twin sister" },
    { value: "GESTM", label: "gestational mother" },
    { value: "GRNDCHILD", label: "grandchild" },
    { value: "GRNDDAU", label: "granddaughter" },
    { value: "GRFTH", label: "grandfather" },
    { value: "GRMTH", label: "grandmother" },
    { value: "GRPRN", label: "grandparent" },
    { value: "GRNDSON", label: "grandson" },
    { value: "GGRFTH", label: "great grandfather" },
    { value: "GGRMTH", label: "great grandmother" },
    { value: "GGRPRN", label: "great grandparent" },
    { value: "Guardian", label: "Guardian of patient" },
    { value: "HBRO", label: "half-brother" },
    { value: "HSIB", label: "half-sibling" },
    { value: "HSIS", label: "half-sister" },
    { value: "HUSB", label: "husband" },
    { value: "ITWIN", label: "identical twin" },
    { value: "ITWINBRO", label: "identical twin brother" },
    { value: "ITWINSIS", label: "identical twin sister" },
    { value: "INLAW", label: "inlaw" },
    { value: "I", label: "Insurance Company" },
    { value: "MAUNT", label: "maternal aunt" },
    { value: "MCOUSN", label: "maternal cousin" },
    { value: "MGRFTH", label: "maternal grandfather" },
    { value: "MGRMTH", label: "maternal grandmother" },
    { value: "MGRPRN", label: "maternal grandparent" },
    { value: "MGGRFTH", label: "maternal great-grandfather" },
    { value: "MGGRMTH", label: "maternal great-grandmother" },
    { value: "MGGRPRN", label: "maternal great-grandparent" },
    { value: "MUNCLE", label: "maternal uncle" },
    { value: "MTH", label: "mother" },
    { value: "MTHINLAW", label: "mother-in-law" },
    { value: "NBRO", label: "natural brother" },
    { value: "NCHILD", label: "natural child" },
    { value: "DAU", label: "natural daughter" },
    { value: "NFTH", label: "natural father" },
    { value: "NFTHF", label: "natural father of fetus" },
    { value: "NMTH", label: "natural mother" },
    { value: "NMTHF", label: "natural mother of fetus" },
    { value: "NPRN", label: "natural parent" },
    { value: "NSIB", label: "natural sibling" },
    { value: "NSIS", label: "natural sister" },
    { value: "SON", label: "natural son" },
    { value: "NBOR", label: "neighbor" },
    { value: "NEPHEW", label: "nephew" },
    { value: "N", label: "Next-of-Kin" },
    { value: "NIECE", label: "niece" },
    { value: "NIENEPH", label: "niece/nephew" },
    { value: "NonDependant", label: "Non Dependant of patient" },
    { value: "NotSpecified", label: "Not Specified" },
    { value: "PRN", label: "parent" },
    { value: "PRNINLAW", label: "parent in-law" },
    { value: "PAUNT", label: "paternal aunt" },
    { value: "PCOUSN", label: "paternal cousin" },
    { value: "PGRFTH", label: "paternal grandfather" },
    { value: "PGRMTH", label: "paternal grandmother" },
    { value: "PGRPRN", label: "paternal grandparent" },
    { value: "PGGRFTH", label: "paternal great-grandfather" },
    { value: "PGGRMTH", label: "paternal great-grandmother" },
    { value: "PGGRPRN", label: "paternal great-grandparent" },
    { value: "PUNCLE", label: "paternal uncle" },
    { value: "PR", label: "Person preparing referral" },
    { value: "Personal", label: "Personal relationship with the patient" },
    { value: "PolygamousPartner", label: "Polygamous Partner of patient" },
    { value: "ProxyCommunication", label: "Proxy Communication for patient" },
    { value: "ProxyContactCommunication", label: "Proxy Contact and Communication for patient" },
    { value: "ProxyContact", label: "Proxy Contact for patient" },
    { value: "ROOM", label: "Roommate" },
    { value: "ONESELF", label: "self" },
    { value: "SIB", label: "sibling" },
    { value: "SIBINLAW", label: "sibling in-law" },
    { value: "SIGOTHR", label: "significant other" },
    { value: "SIS", label: "sister" },
    { value: "SISINLAW", label: "sister-in-law" },
    { value: "SONC", label: "son" },
    { value: "SONINLAW", label: "son in-law" },
    { value: "SPS", label: "spouse" },
    { value: "S", label: "State Agency" },
    { value: "STPCHLD", label: "step child" },
    { value: "STPPRN", label: "step parent" },
    { value: "STPSIB", label: "step sibling" },
    { value: "STPBRO", label: "stepbrother" },
    { value: "STPDAU", label: "stepdaughter" },
    { value: "STPFTH", label: "stepfather" },
    { value: "STPMTH", label: "stepmother" },
    { value: "STPSON", label: "stepson" }
  ],
  Professional: [],
  old: [
    "adopted child", "adopted daughter", "adopted son", "adoptive father",
    "adoptive mother", "adoptive parent", "Agent of patient", "aunt",
    "Billing contact person", "brother", "brother-in-law", "Carer of patient",
    "child", "child-in-law", "Contact person", "cousin", "daughter",
    "daughter in-law", "Dependant of patient", "domestic partner",
    "Emergency Contact", "Emergency contact person", "Employer",
    "extended family member", "family member", "father", "father-in-law",
    "former spouse", "foster child", "foster daughter", "foster father",
    "foster mother", "foster parent", "foster son", "fraternal twin",
    "fraternal twin brother", "fraternal twin sister", "gestational mother",
    "grandchild", "granddaughter", "grandfather", "grandmother",
    "grandparent", "grandson", "great grandfather", "great grandmother",
    "great grandparent", "Guardian of patient", "half-brother", "half-sibling",
    "half-sister", "husband", "identical twin", "identical twin brother",
    "identical twin sister", "inlaw", "Insurance Company", "maternal aunt",
    "maternal cousin", "maternal grandfather", "maternal grandmother",
    "maternal grandparent", "maternal great-grandfather",
    "maternal great-grandmother", "maternal great-grandparent",
    "maternal uncle", "mother", "mother-in-law", "natural brother",
    "natural child", "natural daughter", "natural father",
    "natural father of fetus", "natural mother", "natural mother of fetus",
    "natural parent", "natural sibling", "natural sister", "natural son",
    "neighbor", "nephew", "Next-of-Kin", "niece", "niece/nephew",
    "Non Dependant of patient", "Not Specified", "parent", "parent in-law",
    "paternal aunt", "paternal cousin", "paternal grandfather",
    "paternal grandmother", "paternal grandparent", "paternal great-grandfather",
    "paternal great-grandmother", "paternal great-grandparent",
    "paternal uncle", "Person preparing referral",
    "Personal relationship with the patient",
    "Polygamous Partner of patient", "Proxy Communication for patient",
    "Proxy Contact and Communication for patient", "Proxy Contact for patient",
    "Roommate", "self", "sibling", "sibling in-law", "significant other",
    "sister", "sister-in-law", "son", "son in-law", "spouse", "State Agency",
    "step child", "step parent", "step sibling", "stepbrother",
    "stepdaughter", "stepfather", "stepmother", "stepsister", "stepson",
    "twin", "twin brother", "twin sister", "uncle", "Unknown",
    "unrelated friend", "wife"
  ]
};
