import React, { useEffect, useState } from "react";

const initialFormulation = {
  formulationDate: "",
  formulationLocation: "",
  performingProfessional: "",
  formulation: "",
  personCompleteRecord: "",
};

export default function FormulationModal({
  cx,
  setAddedFormulation,
  addedFormulation,
}) {
  const [formulationData, setFormulationData] = useState(
    addedFormulation?.formulationDate ? addedFormulation : initialFormulation
  );

  useEffect(() => {
    setAddedFormulation({ ...formulationData });
  }, [formulationData]);

  return (
    <>
      <div className="col-md-12 mb-3">
        <div className={`${cx.safeBox} col-md-12`}>
          <div className={`col-12 position-relative ${cx.reactAutocomplete}`}>
            <label className="mb-1">Date</label>
            <input
              type="date"
              className="form-control"
              value={formulationData.formulationDate}
              onChange={(e) =>
                setFormulationData({
                  ...formulationData,
                  formulationDate: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12">
            <label>Location</label>
            <input
              type="text"
              className="form-control"
              placeholder="The location where the formulation was made"
              title="The location where the formulation was made"
              value={formulationData.formulationLocation}
              onChange={(e) =>
                setFormulationData({
                  ...formulationData,
                  formulationLocation: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12">
            <label>Performing professional</label>
            <input
              type="text"
              className="form-control"
              placeholder="The professional who made the formulation"
              title="The professional who made the formulation"
              value={formulationData.performingProfessional}
              onChange={(e) =>
                setFormulationData({
                  ...formulationData,
                  performingProfessional: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12">
            <label>Formulation</label>
            <textarea
              type="text"
              className="form-control"
              rows={4}
              value={formulationData.formulation}
              placeholder="An account, shared by a therapist and person, of the personal meaning and origins of a person’s difficulties. This is viewed in the context of multiple factors including relationships, social circumstances and life events and will indicate the most helpful way forward"
              title="An account, shared by a therapist and person, of the personal meaning and origins of a person’s difficulties. This is viewed in the context of multiple factors including relationships, social circumstances and life events and will indicate the most helpful way forward"
              onChange={(e) =>
                setFormulationData({
                  ...formulationData,
                  formulation: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12">
            <label>Person completing record</label>
            <input
              type="text"
              className="form-control"
              placeholder="Details of the person completing the record."
              title="Details of the person completing the record."
              value={formulationData.personCompleteRecord}
              onChange={(e) =>
                setFormulationData({
                  ...formulationData,
                  personCompleteRecord: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
