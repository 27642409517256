import React, { useEffect, useMemo, useState } from "react";
import EditClients from "../EditClients";
import AddEditContact from "../AddEditContact";
import AddEditComplianceDocs from "../AddEditComplianceDocs";
import ClientCarePlans from "../ClientCarePlans";
import ClientJournalAsApp from "../ClientJournalAsApp";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { currentCareSiteId } from "../../../redux-toolkit/reducer/switchSites";
import { getAccessList } from "../../../utils/CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import NoAccessMsg from "../../../components/NoAccessMsg";
import { createAccessEvent } from "../../../redux-toolkit/reducer/eventLogsReducer";
import AboutMe from "../AboutMe";
import AdminSection from "../AdminSection";

export default function ClientRoutes() {
  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(
      (item) => item.moduleName === "Clients"
    );
    const accesssList = getAccessList(findCurrentModule?.children);
    console.log(accesssList, "accesssList");
    return accesssList;
  }, [accessRoles]);

  const { topicId } = useParams();
  // let params = useParams();
  const location = useLocation();
  const clientId = location.state?.clientId;

  const [clientData, setClientData] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    // create access event log
    const logData = {
      documentType: `client ${topicId}`,
      description: `Client ${topicId} page visited`,
      documentId: clientId,
    };
    dispatch(createAccessEvent(logData));
  }, [topicId]);

  useEffect(() => {
    getClientbyId();
  }, []);

  const getClientbyId = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/clientbyId?clientId=${clientId}&careHomeId=${currentCareSiteId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let alldata = res.data.clientData;
        setClientData(alldata);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  return (
    <>
      {topicId === "profile" && (
        <>
          {moduleAccess?.Profile === "full" ||
          moduleAccess?.Profile === "view" ? (
            <EditClients
              clientAllData={clientData}
              clientId={clientId}
              getClientbyId={getClientbyId}
              Profileaccess={moduleAccess?.Profile}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
      {topicId === "about-me" && (
        <>
          {moduleAccess?.Profile === "full" ||
          moduleAccess?.Profile === "view" ? (
            <AboutMe
              clientAllData={clientData}
              clientId={clientId}
              getClientbyId={getClientbyId}
              Profileaccess={moduleAccess?.Profile}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
      {topicId === "admin-details" && (
        <>
          {moduleAccess?.Profile === "full" ||
          moduleAccess?.Profile === "view" ? (
            <AdminSection
              clientAllData={clientData}
              clientId={clientId}
              getClientbyId={getClientbyId}
              Profileaccess={moduleAccess?.Profile}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
      {topicId === "contacts" && (
        <>
          {moduleAccess?.Contacts === "full" ||
          moduleAccess?.Contacts === "view" ? (
            <AddEditContact
              clientAllData={clientData}
              clientId={clientId}
              getClientbyId={getClientbyId}
              Contactaccess={moduleAccess?.Contacts}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
      {topicId === "docs" && (
        <>
          {moduleAccess?.["Compliance Docs"] === "full" ||
          moduleAccess?.["Compliance Docs"] === "view" ? (
            <AddEditComplianceDocs
              clientAllData={clientData}
              clientId={clientId}
              ComplianceDocsaccess={moduleAccess?.["Compliance Docs"]}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
      {topicId === "care-plan" && (
        <>
          {moduleAccess?.["Care Plan"] === "full" ||
          moduleAccess?.["Care Plan"] === "view" ? (
            <ClientCarePlans
              clientAllData={clientData}
              clientId={clientId}
              CareplanAccess={moduleAccess?.["Care Plan"]}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
      {topicId === "journal" && (
        <>
          {moduleAccess?.Journal === "full" ||
          moduleAccess?.Journal === "view" ? (
            <ClientJournalAsApp
              clientId={clientId}
              JournalAccess={moduleAccess?.Journal}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
    </>
  );
}
