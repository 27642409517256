import { FaPlus } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import DownloadCarePlan from "./DownloadCarePlan";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { useDispatch, useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import { BsPrinter } from "react-icons/bs";
import { GoDownload } from "react-icons/go";
import {
  addCarePlanTemplate,
  createCarePlanDirect,
  deleteCarePlanDirect,
  editClientCarePlanPdf,
  getCarePlanQueAndAns,
  getClientCarePlanPdf,
  plusButtonStatusHandler,
  questionAnswerStatementCollection,
} from "../../redux-toolkit/reducer/carePlanReducer";
import { DropdownButton } from "react-bootstrap";
import SavedPlanHistory from "./SavedPlanHistory";
import { ShowInfoSwal, ShowSwal } from "../../utils/alertSwal";
import { CarePlanOptions } from "../../constants/constants";
import cx from "./clients.module.css";
import { getCurrentTimeZone, toTitleCase } from "../../utils/CommonFunctions";
import { httpAdmin } from "../../Apis/commonApis";
import FormulationModal from "./FormulationModal";
import ContigencyModal from "./ContigencyModal";
import AdditionalPlanModal from "./AdditionalPlanModal";
import { reverseTransformDataWithKey, transformData } from "./carePlanConstant";
import { formatDate, formatToDateString } from "../../utils/datechangehandler";
import { riskProfileColorCodes } from "./care_plan/constants";
import ClientCarePlanModal from "./ClientCarePlanModal";

const ClientCarePlans = (props) => {
  const { clientId, CareplanAccess } = props;
  const [customError, setCustomError] = useState("");
  // const { clientAllData } = props;

  const { currentPlanName } = useSelector((state) => state.caresiteDataReducer);
  const isPlanBase = currentPlanName === "Base";

  // --------------------------  LOCAL STORAGE DATA  --------------------------- //
  let urlID = JSON.parse(localStorage.getItem("userData"));
  const divRef = useRef(null);

  const dispatch = useDispatch();
  let {
    plusButtonStatus,
    templateName,
    savebuttonstatus,
    lastupdate,
    whichModalStatus,
    whichModalType,
    updatedBy,
    activetemplateriskClass,
    activeriskClass,
    globalClientSpecificCarePlanTemplate,
    existingCategoryTemplates,
    selectedCategory,
    activeclassOnClick,
    categoryType,
    templateId,
    careteGoryId,
    questionAndAnswer,
    review,
    questionAndAnswersList,
    // answerId,
    globalCarePlanTemplate,
    savedPlanHistoryStatus,
    getClientCarePlanPdfData,
    saveCarePlanPopupStatus,
    carePlanRemainderDatePopupStatus,
  } = useSelector((state) => state?.carePlanReducer);

  const closecreateCareplanTemplate = () => {
    closePlusBtnPop();
    setCarePlanFormVals(initialCarePlanFormVals);
    setTemplateName("");
    setAddedFormulation([]);
    if (whichModalType === "newCarePlanToCategory") {
      dispatch(
        addCarePlanTemplate({
          plusButtonStatus: false,
          whichModalStatus: false,
          activeclassOnClick: false,
          activetemplateriskClass: "",
          whichModalType: "",
          templateName: "",
          lastupdate: "",
          updatedBy: "",
          questionType: "",
        })
      );
    } else {
      dispatch(
        addCarePlanTemplate({
          whichModalStatus: false,
          activeclassOnClick: false,
          whichModalType: "",
          activetemplateriskClass: "",
          templateName: "",
          lastupdate: "",
          updatedBy: "",
          questionType: "",
        })
      );
    }
  };

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [addedFormulation, setAddedFormulation] = useState([]);
  const [additionalPlan, setAdditionalPlan] = useState([]);
  const [addedContigency, setAddedContigency] = useState([]);
  const [TemplateName, setTemplateName] = useState("");

  const initialCarePlanFormVals = {
    TemplateName: "",
    risk_profile: "",
    riskProfileCategory: "",
    updated_by: "",
    questions: [],
    obeservation_needs_comments: "",
    outcomes: "",
    wishes: "",
    strength: "",
    Safeguarding: [],
    risk_interventions: [],
    Task: [],
    timeZone: getCurrentTimeZone(),
  };
  const [carePlanFormVals, setCarePlanFormVals] = useState(
    initialCarePlanFormVals
  );

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // ---------------- Create Care plan Templates based on currentCareSiteId -------------------//

  const createCarePlanTemplate = async () => {
    let user_data = JSON.parse(localStorage.getItem("userData"));
    const finalValues = {
      careHomeId: currentCareSiteId,
      clientId: clientId,
      categoryType: categoryType,
      updated_by: `${user_data.firstName?.trim()} ${user_data?.lastName}`,
      questionAndAnswer: questionAndAnswer,
      ...carePlanFormVals,
      last_update_date: new Date(),
      TemplateName,
    };
    const result = await httpAdmin(
      "createCarePlanTemplate",
      "POST",
      finalValues,
      null
    );
    if (result.status) {
      closecreateCareplanTemplate();
      getClientSpecificByCategoryCarePlan();
      getCarePlanTemplate();
    }
  };

  // ---------------- Add Care plan Templates on Specific Client based on Category -------------------//
  const addCarePlanTemplateToClientSpecificCategory = async () => {
    let user_data = JSON.parse(localStorage.getItem("userData"));
    const finalValues = {
      careHomeId: currentCareSiteId,
      clientId: clientId,
      categoryType: categoryType,
      updated_by: `${user_data.firstName?.trim()} ${user_data?.lastName}`,
      questionAndAnswer: questionAndAnswer,
      ...carePlanFormVals,
      last_update_date: new Date(),
      TemplateName,
    };
    const endpoint = `updateClientCarePlan?careHomeId=${currentCareSiteId}&clientId=${clientId}&objectId=${templateId}&careplanObjId=${careteGoryId}`;
    const result = await httpAdmin(endpoint, "POST", finalValues, null);
    if (result.status) {
      closecreateCareplanTemplate();
      getClientSpecificByCategoryCarePlan();
      getCarePlanTemplate();
    }
  };

  // ---------------- Delete Template From Templates Listing -------------------//
  function DeleteTemplateFromListing(objectId, mainId) {
    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/deleteCarePlanTemplate?objectId=${objectId}&careHomeId=${currentCareSiteId}&mainId=${mainId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config)
      .then(function (response) {
        closecreateCareplanTemplate();
        getClientSpecificByCategoryCarePlan();
        getCarePlanTemplate();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // -----------------  Get Care plan Template details based on CarehomeId  -------------//

  function getCarePlanTemplate() {
    setPageLoading(true);
    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/getCarePlanTemplate?careHomeId=${currentCareSiteId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config)
      .then((response) => {
        dispatch(
          addCarePlanTemplate({
            globalCarePlanTemplate: response.data.data,
          })
        );
        setPageLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPageLoading(false);
      });
  }

  // -----------------  Get Care plan Template details based on Specific Client Category  -------------//

  function getClientSpecificByCategoryCarePlan() {
    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/getClientCarePlan?clientId=${clientId}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config)
      .then((response) => {
        dispatch(
          addCarePlanTemplate({
            globalClientSpecificCarePlanTemplate:
              response.data.data[0].carePlan,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getTaskList();
    getCarePlanTemplate();
    getClientSpecificByCategoryCarePlan();
    dispatch(getCarePlanQueAndAns());
    dispatch(
      getClientCarePlanPdf({
        adminId: urlID?._id,
        currentCareSiteId,
        clientId: clientId,
      })
    );
  }, []);

  const { clientProfileData } = useSelector((state) => state.carePlanReducer);
  function saveToDownloads(type) {
    setLoading(true);
    const input = document.getElementById("pagetoDownload");
    html2canvas(input, { proxy: clientProfileData.image, scrollY: 0 })
      .then((canvas) => {
        const image = { type: "jpeg", quality: 0.98 };
        const margin = [0.6, 0.5];
        const filename = "myfile.pdf";

        var imgWidth = 8.5;
        var pageHeight = 11;

        var innerPageWidth = imgWidth - margin[0] * 2;
        var innerPageHeight = pageHeight - margin[1] * 2;

        // Calculate the number of pages.
        var pxFullHeight = canvas.height;
        var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
        var nPages = Math.ceil(pxFullHeight / pxPageHeight);

        // Define pageHeight separately so it can be trimmed on the final page.
        var pageHeight = innerPageHeight;

        // Create a one-page canvas to split up the full image.
        var pageCanvas = document.createElement("canvas");
        var pageCtx = pageCanvas.getContext("2d");
        pageCanvas.width = canvas.width;
        pageCanvas.height = pxPageHeight;

        // Initialize the PDF.
        var pdf = new jsPDF("p", "in", [8.5, 11]);

        for (var page = 0; page < nPages; page++) {
          // Trim the final page to reduce file size.
          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight =
              (pageCanvas.height * innerPageWidth) / pageCanvas.width;
          }

          // Display the page.
          var w = pageCanvas.width;
          var h = pageCanvas.height;
          pageCtx.fillStyle = "white";
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          // Add the page to the PDF.
          if (page > 0) pdf.addPage();
          // debugger;
          var imgData = pageCanvas.toDataURL(
            "image/" + image.type,
            image.quality
          );
          pdf.addImage(
            imgData,
            image.type,
            margin[1],
            margin[0],
            innerPageWidth,
            pageHeight
          );
        }

        if (type === "download") {
          pdf.save();
          setLoading(true);
        } else {
          const savepdftobase64format = new Blob([pdf.output("blob")], {
            type: "application/pdf",
          });
          savetocareplan(savepdftobase64format);
        }
      })
      .catch((error) => console.log(error));
  }

  async function savetocareplan(pdfFile) {
    const data = new FormData();

    data.append("pdfLink", pdfFile, "careplan.pdf");
    data.append("adminId", urlID._id);
    data.append("careHomeId", currentCareSiteId);
    data.append("clientId", clientId);
    data.append("createdBy", `${urlID.firstName?.trim()} ${urlID?.lastName}`);

    const result = await httpAdmin(
      `createClientCarePlanPdf`,
      "POST",
      data,
      "formdata"
    );
    setLoading(false);
    ShowSwal(result.status, result.message);
    if (result.status) {
      dispatch(
        getClientCarePlanPdf({
          adminId: urlID?._id,
          currentCareSiteId,
          clientId: clientId,
        })
      );
      dispatch(addCarePlanTemplate({ saveCarePlanPopupStatus: false }));
      handleClose4();
    }
  }

  async function saveToPlansDirect() {
    let user_data = JSON.parse(localStorage.getItem("userData"));
    const finalValues = {
      careHomeId: currentCareSiteId,
      clientId: clientId,
      categoryType: categoryType,
      updated_by: `${user_data.firstName?.trim()} ${user_data?.lastName}`,
      questionAndAnswer: questionAndAnswer,
      ...carePlanFormVals,
      last_update_date: new Date(),
      TemplateName,
    };
    const result = await httpAdmin(
      "createCarePlanDirect",
      "POST",
      finalValues,
      null
    );
    if (result.status) {
      closecreateCareplanTemplate();
      getClientSpecificByCategoryCarePlan();
      getCarePlanTemplate();
    }
  }

  async function deleteFromCarePlans() {
    const result = await dispatch(
      deleteCarePlanDirect({
        careHomeId: currentCareSiteId,
        clientId: clientId,
        careteGoryId: careteGoryId,
        templateId: templateId,
      })
    );
    if (result.payload.status) {
      closecreateCareplanTemplate();
      getClientSpecificByCategoryCarePlan();
      getCarePlanTemplate();
    }
  }

  function getTaskList() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getTask`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
    };
    axios(config)
      .then((res) => {
        setTaskList(res.data?.taskData ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const allTasksClient = globalClientSpecificCarePlanTemplate?.reduce(
    (sum, obj) => {
      obj?.template?.forEach((innerObj) => {
        sum += innerObj.Task?.length;
      });
      return sum;
    },
    0
  );
  const allRisksClient = globalClientSpecificCarePlanTemplate?.reduce(
    (sum, obj) => {
      obj?.template?.forEach((innerObj) => {
        sum += innerObj.risk_interventions?.length;
      });
      return sum;
    },
    0
  );
  const adminTemp = globalClientSpecificCarePlanTemplate.find(
    (careplan) => careplan?.categoryType === "Administrator"
  );
  const adminCarePlan = globalCarePlanTemplate.find(
    (careplan) => careplan?.planType === "Administrative"
  );

  /// administrator section

  async function handleAdministratorSubmit(formData) {
    if (!TemplateName) {
      setCustomError("This field is required");
      return;
    }

    // const addedContigencytransform = transformData(addedContigency);
    const transformDataObj = transformData(
      whichModalType === "Contingency Plan"
        ? addedContigency
        : whichModalType === "Additional Support Plan"
        ? additionalPlan
        : formData
    );

    const payload = {
      ...transformDataObj,
      careHomeId: currentCareSiteId,
      clientId: clientId,
      categoryType: "Administrator",
      last_update_date: new Date(),
      updated_by: `${urlID.firstName?.trim()} ${urlID?.lastName}`,
      // formulationRecordEntry: addedFormulation,
      carePlanType: whichModalType,
      TemplateName,
    };
    if (whichModalType === "Formulation") {
      payload.formulationDate = addedFormulation?.formulationDate;
      payload.formulationLocation = addedFormulation?.formulationLocation;
      payload.performingProfessional = addedFormulation?.performingProfessional;
      payload.formulation = addedFormulation?.formulation;
      payload.personCompleteRecord = addedFormulation?.personCompleteRecord;
    }
    if (templateId) {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_BASEURL}/updateClientCarePlan?careHomeId=${currentCareSiteId}&clientId=${clientId}&objectId=${templateId}&careplanObjId=${careteGoryId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("care_admin_token"),
        },
        data: JSON.stringify(payload),
      };

      axios(config)
        .then(function (response) {
          closecreateCareplanTemplate();
          getClientSpecificByCategoryCarePlan();
          getCarePlanTemplate();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const result = await dispatch(createCarePlanDirect(payload));
      if (result.payload.status) {
        closecreateCareplanTemplate();
        getClientSpecificByCategoryCarePlan();
        getCarePlanTemplate();
      }
    }
  }

  const carePlanModalTitle =
    whichModalType === "Formulation" ||
    whichModalType === "Contingency Plan" ||
    whichModalType === "Additional Support Plan"
      ? whichModalType
      : "Care Plan";

  const addSupportPlanPlaceholder =
    "The name of the particular additional supporting plan, e.g. dieticians plan, wound management plan, discharge management plan and behaviour support plan.";
  const carePlanNamePlaceholder =
    whichModalType === "Formulation" || whichModalType === "Contingency Plan"
      ? `${whichModalType} Record`
      : whichModalType === "Additional Support Plan"
      ? addSupportPlanPlaceholder
      : "Care Plan Template";

  const handleCarePlanInputChange = (event) => {
    const { name, value } = event.target;
    setCarePlanFormVals((prev) => ({ ...prev, [name]: value }));
  };

  const handleValueChangeByName = (fName, fVal) => {
    handleCarePlanInputChange({ target: { name: fName, value: fVal } });
  };

  const handleCarePlanClick = (
    cPlanData,
    resultantClientSpecificCarePlanTemplate
  ) => {
    const cTempVals = {
      ...cPlanData,
      templateId: cPlanData._id,
      careteGoryId: resultantClientSpecificCarePlanTemplate._id,
    };
    setCarePlanFormVals((prev) => ({ ...prev, ...cTempVals }));
    setTemplateName(cTempVals.TemplateName);
    dispatch(
      addCarePlanTemplate({
        // strength: cPlanData?.strength,
        // wishes: cPlanData?.wishes,
        whichModalStatus: true,
        whichModalType: "viewTemplate",
        templateName: cPlanData.TemplateName,
        updatedBy: cPlanData.updated_by,
        // activetemplateriskClass: cPlanData.risk_profile,
        lastupdate: formatToDateString(cPlanData.last_update_date),
        savebuttonstatus: "edit",
        // editTemplateData: cPlanData,
        // activeriskClass:
        //   resultantClientSpecificCarePlanTemplate.riskProfileCategory,
        templateId: cPlanData._id,
        careteGoryId: resultantClientSpecificCarePlanTemplate._id,
        // categoryType,
      })
    );
  };

  const handlePreTemplateClick = (tempVals, resultantCarePlan) => {
    if (isPlanBase) {
      ShowInfoSwal(
        `This feature is not available in the ${currentPlanName} Plan`
      );
    } else {
      dispatch(
        addCarePlanTemplate({
          activeclassOnClick: !activeclassOnClick,
          whichModalType: "QuestionAnswerTemplate",
          whichModalStatus: true,
          questionAndAnswersList: tempVals.allQuestionswithAnswers,
          templateName: tempVals.name,
        })
      );
    }
  };

  const handleSelectedOptsNext = () => {
    const selectedanwsersuggestion = questionAndAnswersList
      .map((item) => {
        const answer = item.options.filter((elem) => {
          return (
            elem &&
            Object.keys(elem).includes("answerValue") &&
            elem?.answerValue === true
          );
        });
        return answer;
      })
      .flat()
      .filter((item) => item !== undefined)
      .map((item) => {
        return item.suggestion;
      })
      .join(" \n");
    handleValueChangeByName(
      "obeservation_needs_comments",
      selectedanwsersuggestion
    );
    dispatch(
      addCarePlanTemplate({
        whichModalStatus: true,
        whichModalType: "newCarePlanToCategory",
        savebuttonstatus: "add",
        categoryType: categoryType,
      })
    );
  };

  const handleExistTemplateClick = (tempObj, resultantCarePlan) => {
    const updateddate = new Date(tempObj.last_update_date).toDateString();
    setCarePlanFormVals((prev) => ({ ...prev, tempObj }));
    setTemplateName(tempObj.TemplateName);
    dispatch(
      addCarePlanTemplate({
        activeclassOnClick: !activeclassOnClick,
        whichModalStatus: true,
        whichModalType: "newCarePlanToCategory",
        savebuttonstatus: "add",
        categoryType: categoryType,
        templateName: tempObj.TemplateName,
        updatedBy: tempObj.updated_by,
        activetemplateriskClass: tempObj.risk_profile,
        lastupdate: updateddate,
        savebuttonstatus: "add",
        editTemplateData: tempObj,
        templateId: tempObj._id,
        categoryType,
      })
    );
  };

  const handleAccordionClick = (clientCarePlan) => {
    dispatch(
      plusButtonStatusHandler({
        plusButtonStatus: false,
      })
    );
    dispatch(
      addCarePlanTemplate({
        selectedCategory: clientCarePlan.apiCategoryName,
        categoryType: clientCarePlan.Ctg,
      })
    );
  };

  const handlePlusBtnClick = () => {
    dispatch(
      plusButtonStatusHandler({
        plusButtonStatus: !plusButtonStatus,
      })
    );
    dispatch(
      addCarePlanTemplate({
        riskProfileCategory: activeriskClass,
      })
    );
  };

  const closePlusBtnPop = () => {
    dispatch(
      plusButtonStatusHandler({
        plusButtonStatus: false,
      })
    );
  };

  const handleCreateTemplateClick = () => {
    if (isPlanBase) {
      ShowInfoSwal(
        `This feature is not available in the ${currentPlanName} Plan`
      );
    } else {
      dispatch(
        addCarePlanTemplate({
          whichModalStatus: true,
          whichModalType: "addCarePlan",
          savebuttonstatus: "add",
        })
      );
      closePlusBtnPop();
    }
  };

  const handleAdminstorTemplateClick = (cstemplate, adminTemp) => {
    console.log(cstemplate, 'ccccccccccccc')
    setAddedContigency(reverseTransformDataWithKey(cstemplate));
    setAdditionalPlan(reverseTransformDataWithKey(cstemplate));
    console.log(reverseTransformDataWithKey(cstemplate))

    const updateddate = new Date(cstemplate.last_update_date).toDateString();
    const formulationDate = formatDate(new Date(cstemplate?.formulationDate));
    setAddedFormulation({
      formulationDate: formulationDate,
      formulationLocation: cstemplate?.formulationLocation,
      performingProfessional: cstemplate?.performingProfessional,
      formulation: cstemplate?.formulation,
      personCompleteRecord: cstemplate?.personCompleteRecord,
    });
    setTemplateName(cstemplate.TemplateName);
    dispatch(
      addCarePlanTemplate({
        whichModalStatus: true,
        whichModalType: cstemplate?.carePlanType,
        templateName: cstemplate.TemplateName,
        updatedBy: cstemplate.updated_by,
        activetemplateriskClass: cstemplate.risk_profile,
        lastupdate: updateddate,
        savebuttonstatus: "edit",
        editTemplateData: cstemplate,
        activeriskClass: adminTemp.riskProfileCategory,
        templateId: cstemplate._id,
        careteGoryId: adminTemp._id,
        categoryType,
      })
    );
  };

  return (
    <>
      {pageLoading && <p>Loading...</p>}
      {savedPlanHistoryStatus === false && pageLoading === false && (
        <>
          <div className="row btns_head mt-3 alignText justify-content-between">
            <div className="col-md-4 mt-2">
              <h5 className="tb_title d-flex justify-content-between mt-0">
                Plan Creation and Modification Tool
              </h5>
            </div>
            <div className="col-md-6 d-flex col-xl-5 justify-content-between">
              <p className="alltaskCounts">
                <strong>All Tasks: {allTasksClient ?? "0"}</strong>
                <strong className="ms-3">
                  All Risks: {allRisksClient ?? "0"}
                </strong>
              </p>
              {!isPlanBase && (
                <span
                  onClick={() => {
                    dispatch(
                      addCarePlanTemplate({ savedPlanHistoryStatus: true })
                    );
                  }}
                >
                  {" "}
                  Saved Plans History
                </span>
              )}
              <div className="btns_head">
                <DropdownButton id="dropdown-basic-button" title="Actions">
                  <Dropdown.Item
                    disabled={CareplanAccess !== "full"}
                    onClick={() => {
                      isPlanBase
                        ? ShowInfoSwal(
                            `This feature is not available in the ${currentPlanName} Plan`
                          )
                        : handleShow4();
                    }}
                  >
                    Preview Care Plan
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    onClick={() => {
                      dispatch(
                        addCarePlanTemplate({
                          saveCarePlanPopupStatus: true,
                        })
                      );
                    }}
                  >
                    Save Care Plan
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    disabled={CareplanAccess !== "full"}
                    onClick={() => {
                      isPlanBase
                        ? ShowInfoSwal(
                            `This feature is not available in the ${currentPlanName} Plan`
                          )
                        : dispatch(
                            addCarePlanTemplate({
                              carePlanRemainderDatePopupStatus: true,
                            })
                          );
                    }}
                  >
                    Set Reminder for Next Review{" "}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>

          <div className="accordion care_plan_list" id="accordionExample">
            {CarePlanOptions.map((clientCarePlan) => {
              const resultantCarePlan = globalCarePlanTemplate.find(
                (careplan) => careplan?.categoryType === clientCarePlan?.Ctg
              );
              const resultantClientSpecificCarePlanTemplate =
                globalClientSpecificCarePlanTemplate.find(
                  (careplan) => careplan?.categoryType === clientCarePlan?.Ctg
                );
              const totalCatTasks =
                resultantClientSpecificCarePlanTemplate?.template.reduce(
                  (sum, obj) => sum + obj?.Task?.length,
                  0
                );
              const totalCatRisks =
                resultantClientSpecificCarePlanTemplate?.template.reduce(
                  (sum, obj) => sum + obj?.risk_interventions?.length,
                  0
                );
              // console.log(resultantClientSpecificCarePlanTemplate?.template, 'resultantClientSpecificCarePlanTemplate?.template');

              return (
                <div
                  className={`${cx.accordionItems} accordion-item`}
                  key={clientCarePlan.value}
                >
                  <h2
                    className="accordion-header"
                    id={`careplan_${clientCarePlan.value}`}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse_careplan_${clientCarePlan.value}`}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                      onClick={() => handleAccordionClick(clientCarePlan)}
                    >
                      <img
                        style={{
                          width: clientCarePlan.value === 7 ? "31px" : "30px",
                        }}
                        alt=""
                        src={clientCarePlan.image}
                      />{" "}
                      {clientCarePlan.Ctg}
                      <p className="taskCounts">
                        <span>Tasks: {totalCatTasks ?? "0"}</span>
                        <span className="ms-3">
                          Risks: {totalCatRisks ?? "0"}
                        </span>
                      </p>
                    </button>
                  </h2>
                  <div
                    id={`collapse_careplan_${clientCarePlan.value}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`careplan_${clientCarePlan.value}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row align-items-center mt-2">
                        {resultantClientSpecificCarePlanTemplate?.template.map(
                          (cstemplate) => {
                            return (
                              <div className="col-md-3">
                                <div
                                  className="living_box"
                                  onClick={() =>
                                    handleCarePlanClick(
                                      cstemplate,
                                      resultantClientSpecificCarePlanTemplate
                                    )
                                  }
                                >
                                  <h5>{cstemplate.TemplateName}</h5>
                                  <p>
                                    {formatToDateString(
                                      cstemplate?.last_update_date
                                    )}
                                  </p>
                                  {cstemplate.risk_profile && (
                                    <div
                                      className="fAction"
                                      style={{
                                        backgroundColor:
                                          riskProfileColorCodes[
                                            cstemplate.risk_profile
                                          ],
                                      }}
                                    >
                                      {toTitleCase(cstemplate.risk_profile)}
                                      <br />
                                      Risk
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}

                        <div className="col-md-3">
                          <Dropdown className="addPlus" show={plusButtonStatus}>
                            <Dropdown.Toggle variant="success">
                              <FaPlus onClick={() => handlePlusBtnClick()} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Header>
                                <div className="actionBtns">
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      dispatch(
                                        addCarePlanTemplate({
                                          whichModalStatus: true,
                                          whichModalType:
                                            "newCarePlanToCategory",
                                          savebuttonstatus: "add",
                                          categoryType: categoryType,
                                        })
                                      );
                                      closePlusBtnPop();
                                    }}
                                  >
                                    Create New
                                  </button>

                                  <button
                                    className="btn"
                                    onClick={handleCreateTemplateClick}
                                  >
                                    Create Template
                                  </button>

                                  <button
                                    className="btn"
                                    onClick={() => {
                                      closePlusBtnPop();
                                      // dispatch(
                                      //   addCarePlanTemplate({
                                      //     riskProfileCategory: "",
                                      //   })
                                      // );
                                    }}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Dropdown.Header>
                              <div className="row">
                                <div className="col-md-12 mt-3">
                                  <h6>
                                    Choose from Predefined Assessment Templates
                                  </h6>
                                </div>
                                {existingCategoryTemplates
                                  .find(
                                    (temp) => temp.name === clientCarePlan.Ctg
                                  )
                                  ?.subCategory?.map((template) => {
                                    return (
                                      <div
                                        className="col-md-6"
                                        onClick={() =>
                                          handlePreTemplateClick(
                                            template,
                                            resultantCarePlan
                                          )
                                        }
                                      >
                                        {/* <div className={`living_box ${activeclassOnClick ? 'active' : ''}`}> */}
                                        <div
                                          className={`living_box ${
                                            activeclassOnClick ? "active" : ""
                                          }`}
                                        >
                                          <h5>{template.name}</h5>
                                          <p>Updated: ---- ----- -----</p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {resultantCarePlan?.template.map((template) => {
                                  const updateddate = new Date(
                                    template?.last_update_date
                                  ).toDateString();
                                  if (
                                    template?.carePlanType === "administrator"
                                  ) {
                                    return;
                                  }
                                  return (
                                    <div className="col-md-6">
                                      <div
                                        className={`living_box`}
                                        onClick={() =>
                                          handleExistTemplateClick(
                                            template,
                                            resultantCarePlan
                                          )
                                        }
                                      >
                                        <TiDelete
                                          style={{
                                            position: "absolute",
                                            right: "3px",
                                            top: "2px",
                                          }}
                                          onClick={() => {
                                            DeleteTemplateFromListing(
                                              template._id,
                                              resultantCarePlan._id
                                            );
                                          }}
                                        />
                                        <h5>{template.TemplateName}</h5>
                                        <p>Updated: {updateddate}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={`${cx.accordionItems} accordion-item`}>
              <h2
                className="accordion-header"
                id={`careplan_${CarePlanOptions?.length + 1}`}
              >
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse_careplan_${
                    CarePlanOptions?.length + 1
                  }`}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  // onClick={() => {
                  //   dispatch(
                  //     addCarePlanTemplate({
                  //       selectedCategory: clientCarePlan.apiCategoryName,
                  //       categoryType: clientCarePlan.Ctg,
                  //     })
                  //   );
                  // }}
                >
                  <img
                    style={{ width: "31px" }}
                    alt=""
                    src={"/images/s12.svg"}
                  />{" "}
                  Administrative
                  <p className="taskCounts"></p>
                </button>
              </h2>
              <div
                id={`collapse_careplan_${CarePlanOptions?.length + 1}`}
                className="accordion-collapse collapse"
                aria-labelledby={`careplan_${CarePlanOptions?.length + 1}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="row align-items-center mt-2">
                    {adminTemp?.template.map((cstemplate) => {
                      const updateddate = new Date(
                        cstemplate?.last_update_date
                      ).toDateString();

                      return (
                        <div className="col-md-3">
                          <div
                            className="living_box"
                            onClick={() =>
                              handleAdminstorTemplateClick(cstemplate, adminTemp)
                            }
                          >
                            <h5 className="mb-0 pb-0">
                              {cstemplate.TemplateName}
                            </h5>
                            <small style={{ fontSize: "10px" }}>
                              {cstemplate?.carePlanType}
                            </small>
                            <p>{updateddate}</p>
                          </div>
                        </div>
                      );
                    })}

                    <div className="col-md-3">
                      <Dropdown className="addPlus" show={plusButtonStatus}>
                        <Dropdown.Toggle variant="success">
                          <FaPlus
                            onClick={() => {
                              dispatch(
                                plusButtonStatusHandler({
                                  plusButtonStatus: !plusButtonStatus,
                                })
                              );
                              dispatch(
                                addCarePlanTemplate({
                                  riskProfileCategory: activeriskClass,
                                })
                              );
                            }}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Header>
                            <div className="actionBtns">
                              <button
                                className="btn"
                                onClick={() => {
                                  dispatch(
                                    addCarePlanTemplate({
                                      whichModalStatus: true,
                                      whichModalType: "Formulation",
                                      savebuttonstatus: "add",
                                      categoryType: categoryType,
                                      templateId: null,
                                    })
                                  );
                                }}
                              >
                                Formulation
                              </button>

                              <button
                                className="btn"
                                onClick={() => {
                                  setAddedContigency({});
                                  isPlanBase
                                    ? ShowInfoSwal(
                                        `This feature is not available in the ${currentPlanName} Plan`
                                      )
                                    : dispatch(
                                        addCarePlanTemplate({
                                          whichModalStatus: true,
                                          whichModalType: "Contingency Plan",
                                          savebuttonstatus: "add",
                                          templateId: null,
                                        })
                                      );
                                }}
                              >
                                Contingency Plan
                              </button>
                              <button
                                className="btn"
                                onClick={() => {
                                  setAdditionalPlan({});
                                  isPlanBase
                                    ? ShowInfoSwal(
                                        `This feature is not available in the ${currentPlanName} Plan`
                                      )
                                    : dispatch(
                                        addCarePlanTemplate({
                                          whichModalStatus: true,
                                          whichModalType:
                                            "Additional Support Plan",
                                          savebuttonstatus: "add",
                                          templateId: null,
                                        })
                                      );
                                }}
                              >
                                Additional Support Plan
                              </button>

                              <button
                                className="btn"
                                onClick={() => {
                                  dispatch(
                                    plusButtonStatusHandler({
                                      plusButtonStatus: false,
                                    })
                                  );
                                  // dispatch(
                                  //   addCarePlanTemplate({
                                  //     riskProfileCategory: "",
                                  //   })
                                  // );
                                }}
                              >
                                Close
                              </button>
                            </div>
                          </Dropdown.Header>
                          <div className="row">
                            {adminCarePlan?.template.map((template) => {
                              const updateddate = new Date(
                                template?.last_update_date
                              ).toDateString();
                              return (
                                <div className="col-md-6">
                                  <TiDelete
                                    onClick={() => {
                                      DeleteTemplateFromListing(
                                        template._id,
                                        adminCarePlan._id
                                      );
                                    }}
                                  />
                                  {/* <div className={`living_box ${activeclassOnClick ? 'active' : ''}`}> */}
                                  <div
                                    className={`living_box`}
                                    onClick={() => {
                                      const updateddate = new Date(
                                        template.last_update_date
                                      ).toDateString();
                                      dispatch(
                                        addCarePlanTemplate({
                                          activeclassOnClick:
                                            !activeclassOnClick,
                                          whichModalStatus: true,
                                          whichModalType:
                                            "newCarePlanToCategory",
                                          savebuttonstatus: "add",
                                          categoryType: categoryType,
                                          templateName: template.TemplateName,
                                          updatedBy: template.updated_by,
                                          activetemplateriskClass:
                                            template.risk_profile,
                                          lastupdate: updateddate,
                                          savebuttonstatus: "add",
                                          editTemplateData: template,
                                          templateId: template._id,
                                          categoryType,
                                        })
                                      );
                                    }}
                                  >
                                    <h5>{template.TemplateName}</h5>
                                    <p>Updated: {updateddate}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {savedPlanHistoryStatus === true && (
        <>
          <div className="btns_head mt-3 setPostion">
            <a
              onClick={() => {
                dispatch(
                  addCarePlanTemplate({ savedPlanHistoryStatus: false })
                );
              }}
            >
              Back{" "}
            </a>
          </div>
          <SavedPlanHistory rows={getClientCarePlanPdfData} />
        </>
      )}

      <Modal className="viewModal" show={show4} size="xl" onHide={handleClose4}>
        <Modal.Header>
          <Modal.Title>
            <span></span>
            <div className="d-flex">
              <div className="iconDownLoad me-4">
                <span>
                  <BsPrinter />
                </span>
                <span>
                  <GoDownload
                    className="curser_point"
                    onClick={() => saveToDownloads("download")}
                  />
                </span>
              </div>
              <button
                className="btn"
                onClick={() => {
                  dispatch(
                    addCarePlanTemplate({
                      saveCarePlanPopupStatus: true,
                    })
                  );
                }}
              >
                Save Care Plan
              </button>

              {/* <span>
                <AiOutlineClose
                  className="curser_point"
                  onClick={handleClose4}
                />
              </span> */}
              <button className="btn" onClick={handleClose4}>
                Close
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadCarePlan divRef={divRef} />
        </Modal.Body>
      </Modal>

      <Modal
        className="viewModal"
        show={whichModalStatus}
        size="lg"
        onHide={closecreateCareplanTemplate}
      >
        {(whichModalType === "addCarePlan" ||
          whichModalType === "Formulation" ||
          whichModalType === "Contingency Plan" ||
          whichModalType === "newCarePlanToCategory" ||
          whichModalType === "Additional Support Plan" ||
          whichModalType === "viewTemplate") && (
          <div className="predefineTemplate">
            <h3>{carePlanModalTitle}</h3>
            {isPlanBase && (
              <p className="mb-0">
                Only Template Name and Task Addition is allowed in Base Plan
              </p>
            )}
            <div className="row mt-4 mb-5 align-items-center">
              <div className="col-md-6">
                <input
                  title={`${carePlanNamePlaceholder}`}
                  placeholder={`${carePlanNamePlaceholder} Name`}
                  type="text"
                  className="form-control"
                  name="TemplateName"
                  value={TemplateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />{" "}
              </div>
              <div className="col-md-6">
                <ul className="menuListTop">
                  <li>
                    {(whichModalType === "addCarePlan" ||
                      whichModalType === "Formulation" ||
                      whichModalType === "Contingency Plan" ||
                      whichModalType === "Additional Support Plan") &&
                      savebuttonstatus === "add" && (
                        <button
                          className="btn"
                          onClick={() => {
                            whichModalType === "Additional Support Plan" ||
                            whichModalType === "Formulation" ||
                            whichModalType === "Contingency Plan"
                              ? handleAdministratorSubmit()
                              : createCarePlanTemplate();
                          }}
                        >
                          Save
                        </button>
                      )}
                    {savebuttonstatus === "edit" && (
                      <button className="btn" onClick={deleteFromCarePlans}>
                        Delete
                      </button>
                    )}{" "}
                    {savebuttonstatus === "edit" && (
                      <button
                        className="btn"
                        onClick={() => {
                          whichModalType === "Additional Support Plan" ||
                          whichModalType === "Formulation" ||
                          whichModalType === "Contingency Plan"
                            ? handleAdministratorSubmit()
                            : addCarePlanTemplateToClientSpecificCategory(
                                "edit"
                              );
                        }}
                      >
                        Update to plan
                      </button>
                    )}{" "}
                    {whichModalType === "newCarePlanToCategory" &&
                      savebuttonstatus === "add" && (
                        <button className="btn" onClick={saveToPlansDirect}>
                          Save to plan
                        </button>
                      )}
                  </li>
                  <li>
                    <button
                      className="btn"
                      onClick={closecreateCareplanTemplate}
                    >
                      Close
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mb-3">
                  {whichModalType !== "Formulation" &&
                    whichModalType !== "Contingency Plan" &&
                    whichModalType !== "Additional Support Plan" && (
                      <ul className="catList">
                        <h6 className="mb-0">Select Risk Profile: </h6>
                        {Object.keys(riskProfileColorCodes).map((elem) => {
                          return (
                            <li
                              key={elem}
                              className={
                                carePlanFormVals?.risk_profile === elem
                                  ? elem
                                  : ""
                              }
                              style={{
                                backgroundColor: riskProfileColorCodes[elem],
                              }}
                              onClick={(e) => {
                                if (!isPlanBase) {
                                  handleValueChangeByName("risk_profile", elem);
                                  handleValueChangeByName(
                                    "riskProfileCategory",
                                    elem
                                  );
                                }
                              }}
                            >
                              {toTitleCase(elem)} <br /> Risk
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  <h6>Last Update Date: {lastupdate}</h6>
                  <h6>Updated By: {updatedBy}</h6>
                </div>
                {/* Questions and Answers Dynamic Form  */}

                <>
                  <div className="col-md-12">
                    <div className="row">
                      {whichModalType !== "Additional Support Plan" &&
                        whichModalType !== "Formulation" &&
                        whichModalType !== "Contingency Plan" && (
                          <ClientCarePlanModal
                            isPlanBase={isPlanBase}
                            categoryType={categoryType}
                            activetemplateriskClass={activetemplateriskClass}
                            taskList={taskList}
                            carePlanFormVals={carePlanFormVals}
                            handleCarePlanInputChange={
                              handleCarePlanInputChange
                            }
                            handleValueChangeByName={handleValueChangeByName}
                          />
                        )}
                      {whichModalType === "Additional Support Plan" && (
                        <AdditionalPlanModal
                          setAdditionalPlan={setAdditionalPlan}
                          additionalPlan={additionalPlan}
                        />
                      )}
                      {whichModalType === "Contingency Plan" && (
                        <ContigencyModal
                          setAddedContigency={setAddedContigency}
                          addedContigency={addedContigency}
                        />
                      )}
                      {whichModalType === "Formulation" && (
                        <FormulationModal
                          cx={cx}
                          setAddedFormulation={setAddedFormulation}
                          addedFormulation={addedFormulation}
                        />
                      )}
                    </div>
                  </div>
                </>
              </div>
            </Modal.Body>
          </div>
        )}

        {whichModalType === "QuestionAnswerTemplate" && (
          <div className="predefineTemplate">
            <Modal.Body>
              <div className="col-md-12">
                <h5>{templateName}</h5>
                <p>Please answer below questions</p>
                <p>
                  Click Next when you finish answering. Your input will be used
                  to auto-fill the observations section which you can
                  accordingly edit, complete and save in the plan.{" "}
                </p>
                <button
                  className="btn nextBtn"
                  type="button"
                  onClick={() => handleSelectedOptsNext()}
                >
                  Next
                </button>
              </div>
              <div className="col-md-12">
                {questionAndAnswersList?.map(
                  ({ questions, options, _id }, index) => {
                    return (
                      <>
                        {questions.includes(
                          "(you can select more than one)"
                        ) && (
                          <div className="quationBox">
                            <h5>{questions} </h5>
                            <ul>
                              {options.map((option) => {
                                return (
                                  <li>
                                    {/* <label className="checkbox active"> */}
                                    <label
                                      className={`checkbox ${
                                        Object.keys(option).includes(
                                          "answerValue"
                                        ) && option?.answerValue
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <input
                                        type="checkbox"
                                        name={_id}
                                        onChange={(event) => {
                                          dispatch(
                                            questionAnswerStatementCollection({
                                              selectedAnswerId: option._id,
                                              questionId: _id,
                                              questionAndAnswersList,
                                              selectedAnswerEvent:
                                                event.target.checked,
                                              questionType: "checkbox",
                                            })
                                          );
                                          // dispatch(
                                          //   addCarePlanTemplate({
                                          //     answerId: option._id,
                                          //   })
                                          // );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      {option.answer}
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}

                        {/*question that have single select  */}
                        {!questions.includes(
                          "(you can select more than one)"
                        ) && (
                          <div className="quationBox">
                            <h5>{questions} </h5>
                            <ul>
                              {options.map((option) => {
                                return (
                                  <li>
                                    {/* <label className="checkbox active"> */}
                                    <label
                                      className={`checkbox ${
                                        Object.keys(option).includes(
                                          "answerValue"
                                        ) && option?.answerValue
                                          ? "active"
                                          : ""
                                      }`}
                                    >
                                      <input
                                        type="radio"
                                        name={_id}
                                        onChange={(event) => {
                                          dispatch(
                                            questionAnswerStatementCollection({
                                              selectedAnswerId: option._id,
                                              questionId: _id,
                                              questionAndAnswersList,
                                              selectedAnswerEvent:
                                                event.target.checked,
                                              questionType: "radio",
                                            })
                                          );
                                          // dispatch(
                                          //   addCarePlanTemplate({
                                          //     answerId: option._id,
                                          //   })
                                          // );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                      {option.answer}
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </>
                    );
                  }
                )}
              </div>
            </Modal.Body>
          </div>
        )}
      </Modal>

      <Modal
        className="viewModal"
        show={saveCarePlanPopupStatus}
        onHide={() => {
          dispatch(addCarePlanTemplate({ saveCarePlanPopupStatus: false }));
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Save Client Care Plan</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <p>Are you sure you want to save this care plan?</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              className="btn submitBtn"
              onClick={() => {
                dispatch(
                  addCarePlanTemplate({ saveCarePlanPopupStatus: false })
                );
              }}
            >
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={() => saveToDownloads("save")}
              disabled={loading}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        className="viewModal"
        show={carePlanRemainderDatePopupStatus}
        onHide={() => {
          dispatch(
            addCarePlanTemplate({ carePlanRemainderDatePopupStatus: false })
          );
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Set Next Review Date</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label">Date</label>
              <input
                type="date"
                className="form-control"
                value={review}
                onChange={(e) => {
                  // if (getClientCarePlanPdfData[0]?._id) {
                  dispatch(addCarePlanTemplate({ review: e.target.value }));
                  // } else {
                  // swal("Failed", 'error.response.data.message', "error");
                  // }
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              className="btn submitBtn"
              onClick={() => {
                dispatch(
                  addCarePlanTemplate({
                    carePlanRemainderDatePopupStatus: false,
                  })
                );
              }}
            >
              No
            </button>
            <button
              className="btn submitBtn"
              onClick={async () => {
                if (review && getClientCarePlanPdfData[0]?._id) {
                  dispatch(
                    editClientCarePlanPdf({
                      review,
                      documentId: getClientCarePlanPdfData[0]?._id,
                    })
                  );
                  swal("Success", "Review Date added successfully", "success");
                  await dispatch(
                    getClientCarePlanPdf({
                      adminId: urlID?._id,
                      currentCareSiteId,
                      clientId: clientId,
                    })
                  );
                } else {
                  swal("Failed", "Document not found", "error");
                }
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientCarePlans;
