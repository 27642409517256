export const admissionMethods = [
  {
    label: "Elective Admission: Waiting list",
    value: "Elective Admission: Waiting list",
  },
  { label: "Elective Admission: Booked", value: "Elective Admission: Booked" },
  {
    label: "Elective Admission: Planned",
    value: "Elective Admission: Planned",
  },
  {
    label:
      "Emergency Admission: Emergency Care Department or dental casualty department of the Health Care Provider",
    value:
      "Emergency Admission: Emergency Care Department or dental casualty department of the Health Care Provider",
  },
  {
    label:
      "Emergency Admission: General Practitioner: after a request for immediate admission has been made direct to a Hospital Provider, i.e. not through a Bed bureau, by a General Practitioner or deputy",
    value:
      "Emergency Admission: General Practitioner: after a request for immediate admission has been made direct to a Hospital Provider, i.e. not through a Bed bureau, by a General Practitioner or deputy",
  },
  {
    label: "Emergency Admission: Bed bureau",
    value: "Emergency Admission: Bed bureau",
  },
  {
    label:
      "Emergency Admission: Consultant Clinic, of this or another Health Care Provider",
    value:
      "Emergency Admission: Consultant Clinic, of this or another Health Care Provider",
  },
  {
    label:
      "Emergency Admission: Admission via Mental Health Crisis Resolution Team",
    value:
      "Emergency Admission: Admission via Mental Health Crisis Resolution Team",
  },
  {
    label:
      "Emergency Admission: Emergency Care Department of another provider where the Patient had not been admitted",
    value:
      "Emergency Admission: Emergency Care Department of another provider where the Patient had not been admitted",
  },
  {
    label:
      "Emergency Admission: Transfer of an admitted Patient from another Hospital Provider in an emergency",
    value:
      "Emergency Admission: Transfer of an admitted Patient from another Hospital Provider in an emergency",
  },
  {
    label: "Emergency Admission: Baby born at home as intended",
    value: "Emergency Admission: Baby born at home as intended",
  },
  {
    label: "Emergency Admission: Other emergency admission",
    value: "Emergency Admission: Other emergency admission",
  },
  {
    label:
      "Emergency Admission: Other means, examples are: - admitted from the Emergency Care Department of another provider where they had not been admitted - transfer of an admitted Patient from another Hospital Provider in an emergency - baby born at home as intended",
    value:
      "Emergency Admission: Other means, examples are: - admitted from the Emergency Care Department of another provider where they had not been admitted - transfer of an admitted Patient from another Hospital Provider in an emergency - baby born at home as intended",
  },
  {
    label: "Maternity Admission: Admitted ante partum",
    value: "Maternity Admission: Admitted ante partum",
  },
  {
    label: "Maternity Admission: Admitted post partum",
    value: "Maternity Admission: Admitted post partum",
  },
  {
    label: "Other Admission: The birth of a baby in this Health Care Provider",
    value: "Other Admission: The birth of a baby in this Health Care Provider",
  },
  {
    label:
      "Other Admission: Baby born outside the Health Care Provider except when born at home as intended",
    value:
      "Other Admission: Baby born outside the Health Care Provider except when born at home as intended",
  },
  {
    label:
      "Other Admission: Transfer of any admitted Patient from other Hospital Provider other than in an emergency",
    value:
      "Other Admission: Transfer of any admitted Patient from other Hospital Provider other than in an emergency",
  },
  { label: "Not applicable", value: "Not applicable" },
  { label: "Admission Method not known", value: "Admission Method not known" },
];

export const sourceOfAdmission = [
  {
    label:
      "Usual place of residence unless listed below, for example, a private dwelling whether owner occupied or owned by Local Authority, housing association or other landlord. This includes wardened accommodation but not residential accommodation where health care is provided. It also includes Patients with no fixed abode.",
    value:
      "Usual place of residence unless listed below, for example, a private dwelling whether owner occupied or owned by Local Authority, housing association or other landlord. This includes wardened accommodation but not residential accommodation where health care is provided. It also includes Patients with no fixed abode.",
  },
  {
    label:
      "Temporary place of residence when usually resident elsewhere (e.g. hotels, residential Educational Establishments)",
    value:
      "Temporary place of residence when usually resident elsewhere (e.g. hotels, residential Educational Establishments)",
  },
  {
    label:
      "Penal establishment, Court, or Police Station / Police Custody Suite",
    value:
      "Penal establishment, Court, or Police Station / Police Custody Suite",
  },
  {
    label:
      "NHS other Hospital Provider - high security psychiatric accommodation in an NHS Hospital Provider (NHS Trust or NHS Foundation Trust)",
    value:
      "NHS other Hospital Provider - high security psychiatric accommodation in an NHS Hospital Provider (NHS Trust or NHS Foundation Trust)",
  },
  {
    label:
      "NHS other Hospital Provider - Ward for general Patients or the younger physically disabled or Emergency Care Department",
    value:
      "NHS other Hospital Provider - Ward for general Patients or the younger physically disabled or Emergency Care Department",
  },
  {
    label:
      "NHS other Hospital Provider - Ward for maternity Patients or Neonates",
    value:
      "NHS other Hospital Provider - Ward for maternity Patients or Neonates",
  },
  {
    label:
      "NHS other Hospital Provider - Ward for Patients who are mentally ill or have Learning Disabilities",
    value:
      "NHS other Hospital Provider - Ward for Patients who are mentally ill or have Learning Disabilities",
  },
  { label: "NHS run Care Home", value: "NHS run Care Home" },
  {
    label:
      "Local Authority residential accommodation i.e. where care is provided",
    value:
      "Local Authority residential accommodation i.e. where care is provided",
  },
  {
    label: "Local Authority foster care",
    value: "Local Authority foster care",
  },
  {
    label: "Babies born in or on the way to hospital",
    value: "Babies born in or on the way to hospital",
  },
  {
    label: "Non-NHS (other than Local Authority) run Care Home",
    value: "Non-NHS (other than Local Authority) run Care Home",
  },
  { label: "Non NHS run hospital", value: "Non NHS run hospital" },
  {
    label: "Non-NHS (other than Local Authority) run Hospice",
    value: "Non-NHS (other than Local Authority) run Hospice",
  },
  { label: "Not applicable", value: "Not applicable" },
  {
    label: "Source of admission not known",
    value: "Source of admission not known",
  },
];
export const descriptions = [
  "Has Advance Decision to Refuse Treatment (Mental Capacity Act 2005) - Refuses all blood transfusion and administration of primary blood components and minor fractions (finding)",
  "Has advance decision to refuse life sustaining treatment (Mental Capacity Act 2005) (finding)",
  "Advance decision to refuse treatment retracted (finding)",
  "Has advance decision to refuse treatment (Mental Capacity Act 2005) (finding)",
  "Advance decision to refuse treatment signed (finding)",
];

export const cprDecision = [
  "For cardiopulmonary resuscitation (finding)",
  "Not for cardiopulmonary resuscitation (finding)",
];

export const CPRInformer = [
  "Reference to Subject of Care",
  "Carer",
  "Care Professional",
  "Proxy",
  "Organisation",
  "Personal Contact",
];

export const informedConsentStatus = [
  "planned:Need for informed consent is identified.",
  "postponed:Intent to request informed consent has been postponed.",
  "cancelled:Intent to request informed consent has been cancelled, prior to requesting consent from patient or patient's proxy.",
  "scheduled:An appointment has been scheduled to request consent.",
  "requested:Informed consent has been requested from the patient or patient's proxy, but no response has been received.",
  "provided:Informed consent has been provided by the patient or patient's proxy.",
  "not obtained:Informed consent was not obtainable from either the patient or patient's proxy.",
  "refused:In response to a request for informed consent, it has been refused by patient or patient's proxy.",
  "withdrawn:Following initial provision of informed consent, it has been withdrawn by the patient or patient's proxy.",
  "completed:Informed consent has been provided by the patient or patient's agent and the activity is now complete.",
];

export const mentalHealthAct = [
  { label: "Informal", value: "Informal" },
  {
    label: "Formally detained under Mental Health Act Section 2",
    value: "Formally detained under Mental Health Act Section 2",
  },
  {
    label: "Formally detained under Mental Health Act Section 3",
    value: "Formally detained under Mental Health Act Section 3",
  },
  {
    label: "Formally detained under Mental Health Act Section 4",
    value: "Formally detained under Mental Health Act Section 4",
  },
  {
    label: "Formally detained under Mental Health Act Section 5(2)",
    value: "Formally detained under Mental Health Act Section 5(2)",
  },
  {
    label: "Formally detained under Mental Health Act Section 5(4)",
    value: "Formally detained under Mental Health Act Section 5(4)",
  },
  {
    label: "Formally detained under Mental Health Act Section 35",
    value: "Formally detained under Mental Health Act Section 35",
  },
  {
    label: "Formally detained under Mental Health Act Section 36",
    value: "Formally detained under Mental Health Act Section 36",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 37 with section 41 restrictions",
    value:
      "Formally detained under Mental Health Act Section 37 with section 41 restrictions",
  },
  {
    label: "Formally detained under Mental Health Act Section 37",
    value: "Formally detained under Mental Health Act Section 37",
  },
  {
    label: "Formally detained under Mental Health Act Section 38",
    value: "Formally detained under Mental Health Act Section 38",
  },
  {
    label: "Formally detained under Mental Health Act Section 44",
    value: "Formally detained under Mental Health Act Section 44",
  },
  {
    label: "Formally detained under Mental Health Act Section 46",
    value: "Formally detained under Mental Health Act Section 46",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 47 with section 49 restrictions",
    value:
      "Formally detained under Mental Health Act Section 47 with section 49 restrictions",
  },
  {
    label: "Formally detained under Mental Health Act Section 47",
    value: "Formally detained under Mental Health Act Section 47",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 48 with section 49 restrictions",
    value:
      "Formally detained under Mental Health Act Section 48 with section 49 restrictions",
  },
  {
    label: "Formally detained under Mental Health Act Section 48",
    value: "Formally detained under Mental Health Act Section 48",
  },
  {
    label: "Formally detained under Mental Health Act Section 135",
    value: "Formally detained under Mental Health Act Section 135",
  },
  {
    label: "Formally detained under Mental Health Act Section 136",
    value: "Formally detained under Mental Health Act Section 136",
  },
  {
    label:
      "Formally detained under Criminal Procedure(Insanity) Act 1964 as amended by the Criminal Procedures (Insanity and Unfitness to Plead) Act 1991",
    value:
      "Formally detained under Criminal Procedure(Insanity) Act 1964 as amended by the Criminal Procedures (Insanity and Unfitness to Plead) Act 1991",
  },
  {
    label: "Formally detained under other acts",
    value: "Formally detained under other acts",
  },
  {
    label:
      "Supervised Discharge (Mental Health (Patients in the Community) Act 1995) (Retired 03 November 2008 - but may apply to some patients until 3 May 2009)",
    value:
      "Supervised Discharge (Mental Health (Patients in the Community) Act 1995) (Retired 03 November 2008 - but may apply to some patients until 3 May 2009)",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 45A (Retired 01 September 2014)",
    value:
      "Formally detained under Mental Health Act Section 45A (Retired 01 September 2014)",
  },
  {
    label: "Subject to guardianship under Mental Health Act Section 7",
    value: "Subject to guardianship under Mental Health Act Section 7",
  },
  {
    label: "Subject to guardianship under Mental Health Act Section 37",
    value: "Subject to guardianship under Mental Health Act Section 37",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 45A (Limited direction in force)",
    value:
      "Formally detained under Mental Health Act Section 45A (Limited direction in force)",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 45A (Limitation direction ended)",
    value:
      "Formally detained under Mental Health Act Section 45A (Limitation direction ended)",
  },
];
