import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PayrollStats from "./PayrollStats";
import { httpAdmin } from "../../Apis/commonApis";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import Scheduler from "./Calender/CalenderSchedule";
import OverviewDetails from "./OverviewDetails";
import Overview from "./Overview";
import {
  formatDate,
  getFullName,
  getMondayDate,
  handleDataPrint,
} from "../../utils/CommonFunctions";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { useDispatch } from "react-redux";
import { createAccessEvent } from "../../redux-toolkit/reducer/eventLogsReducer";

let today = new Date();

export default function PayrollRoutes(props) {
  const {
    filterType,
    dateRange,
    searchText,
    overviewId,
    handleDetailShowHide,
  } = props;
  const { topicId } = useParams();

  const [payrollData, setPayrollData] = useState([]);
  const [overallCount, setoverallCount] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [startDate, endDate] = dateRange;

  const dispatch = useDispatch();
  useEffect(() => {
    // create access event log
    const logData = {
      documentType: `payroll`,
      description: `Payroll page visited`,
      // documentId: careteamId,
    };
    dispatch(createAccessEvent(logData));
  }, [topicId]);

  useEffect(() => {
    if (filterType === "custom" && endDate) {
      getPayrollCareTeam(formatDate(startDate), formatDate(endDate));
    } else if (filterType === "week") {
      let mondayDate = getMondayDate(new Date());
      getPayrollCareTeam(formatDate(mondayDate), formatDate(today));
    } else if (filterType === "today") {
      // getPayrollCareTeam(today, today);
    }
  }, [filterType, dateRange]);

  const getPayrollCareTeam = async (start, end) => {
    let res = await httpAdmin(
      `getPayroll?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`,
      "get",
      null,
      null
    );
    if (res?.careteamListing?.data) {
      let allcount = res?.careteamListing?.overallCount;
      setPayrollData(res?.careteamListing?.data);
      setoverallCount({
        totalCareTeam: allcount.totalCareTeam ?? 0,
        totalGrossWages: allcount.totalGrossWages ?? 0,
        totalHours: allcount.totalHours ?? 0,
        totalOverTimeHours: allcount.totalOverTimeHours ?? 0,
        totalRegularHours: allcount.totalRegularHours ?? 0,
      });
      let downloadData = [];
      res?.careteamListing?.data?.forEach((elem) => {
        downloadData.push({
          Name: getFullName(elem),
          ["Start Date"]: elem.startDate,
          ["Regular Hrs"]: elem.regularHours ?? 0,
          ["Overtime Hrs"]: elem.overTimeHours ?? 0,
          ["Total Hrs"]: elem.totalHours ?? 0,
          ["Hr rate"]: elem.wagesPerhour ?? 0,
          ["OT Rate"]: elem.overtimeRate ?? 0,
          ["Gross  Wage"]: elem.grossWage ?? 0,
          ["Net Wage"]: elem.netWage ?? 0,
        });
      });
      setDataToDownload(downloadData);
    }
  };

  return (
    <>
      {topicId === "overview" && (
        <>
          <PayrollStats overallCount={overallCount} />
          <div>
            <AiFillPrinter
              className="me-3 ms-1 curser_point"
              onClick={() => handleDataPrint(dataToDownload)}
            />
            <a className="curser_point">
              <JsonDownload
                fileName="payroll-excel"
                downloadfile={dataToDownload}
              />
            </a>
          </div>
          <Overview
            searchText={searchText}
            rows={payrollData}
            handleDetailShowHide={handleDetailShowHide}
            dateRange={dateRange}
            filterType={filterType}
          />
        </>
      )}
      {topicId === "calendar" && (
        <>
          <PayrollStats overallCount={overallCount} />
          <Scheduler text={searchText} />
        </>
      )}
      {topicId === "overview-payslip" && (
        <>
          <OverviewDetails
            overviewId={overviewId}
            handleDetailShowHide={handleDetailShowHide}
            dateRange={dateRange}
            filterType={filterType}
          />
        </>
      )}
    </>
  );
}
