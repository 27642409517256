import { Box, Select } from "@mui/material";
import CareMagnusTable from "../../utils/CareMagnusTable";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { MedicalConditionOptions } from "../../constants/constants";
import { ShowInfoSwal, ShowSwal } from "../../utils/alertSwal";
import cx from "./ClientGP.module.css";
import { httpAdmin } from "../../Apis/commonApis";

export default function ClientGpData({ Profileaccess, clientId }) {
  const columns = [
    { field: "practiceIdentifiers", headerName: "Code" },
    { field: "gpName", headerName: "GP Name" },
    { field: "primaryRoleName", headerName: "Primary Role Name" },
    { field: "postcode", headerName: "Postcode" },
    { field: "address", headerName: "GP Address" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [editMedConId, setEditMedConId] = useState("");
  const [gpListRows, setGpListRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [gpList, setGpList] = useState([]);
  const [gpName, setGpName] = useState("");
  const [nhsId, setNhsId] = useState("");

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  function handleCloseAddGPPopup() {
    setIsOpen(false);
    setGpList([]);
    setEditMedConId("");
    setGpName("");
  }

  const handleRowSelection = (rowId) => {
    setSelectedRow(rowId.slice(-1));
  };

  const deletemultiple = async () => {
    const body = { clientId: clientId, organisationCodeId: selectedRow };
    const res = await httpAdmin(`deleteGpData`, "delete", body, null);
    if (res.status) {
      getGPListist();
      handleCloseDeletePopup();
    }
    ShowSwal(res.status, res.message ?? "Something went wrong!");
  };

  async function handleSubmit() {
    if (nhsId) {
      const response = await httpAdmin("createGpData", "POST", {
        clientId,
        nhsId: nhsId,
      });
      if (response?.status) {
        ShowSwal(response.status, response.message ?? "Something went wrong!");
        getGPListist();
        handleCloseAddGPPopup();
      } else {
        ShowSwal(response.status, response.message ?? "Something went wrong!");
      }
    }
  }

  const handleGetGpApi = () => {};

  async function getGPListist() {
    const response = await httpAdmin(
      `getGpData?clientId=${clientId}`,
      "GET",
      null,
      "json"
    );
    if (response?.status) {
      const rows = response?.gpData?.map((item) => {
        const address1 = item?.organisationDetails?.["address1"] || "";
        const address2 = item?.organisationDetails?.["address2"] || "";
        const address3 = item?.organisationDetails?.["address3"] || "";
        const address4 = item?.organisationDetails?.["address4"] || "";
        const address5 = item?.organisationDetails?.["address5"] || "";
        const town = item?.organisationDetails?.["town"] || "";
        const country = item?.organisationDetails?.["country"] || "";
        const primaryRoleName =
          item?.organisationDetails?.["primaryRoleName"] || "";
        const postcode = item?.organisationDetails?.["postcode"] || "";
        const address = `${address1}, ${
          address2 && address1 !== address2 ? `${address2}, ` : ""
        }${address3 ? `${address3}, ` : ""}${address4 ? `${address4}, ` : ""}${
          address5
            ? `${address5}, ${town ? town : ""} ${
                country ? `, ${country}` : ""
              }`
            : ""
        }`;
        return {
          _id: item?._id,
          gpName: item?.organisationDetails?.name,
          practiceIdentifiers: item?.organisationDetails?.["nhsId"],
          postcode: postcode,
          primaryRoleName,
          address,
        };
      });
      setGpListRows(rows);
    } else {
      setGpListRows([]);
    }
  }

  const camelCaseKeys = (obj) => {
    if (!obj) {
      return {};
    }
    const toCamelCase = (str) =>
      str.replace(/[-_\s]+(.)?/g, (_, char) =>
        char ? char.toUpperCase() : ""
      );

    if (Array.isArray(obj)) {
      return obj.map((item) => camelCaseKeys(item));
    } else if (obj !== null && typeof obj === "object") {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        const camelKey = toCamelCase(key);
        acc[camelKey] =
          typeof value === "object" && value !== null
            ? camelCaseKeys(value)
            : value;
        return acc;
      }, {});
    }
    return obj;
  };

  const controllerRef = useRef(null);
  async function handleSearch(e) {
    const value = e.target.value;
    setGpName(value);
    if (value && value.length > 3) {
      try {
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Create a new AbortController instance
        controllerRef.current = new AbortController();
        const signal = controllerRef.current.signal;
        const path = `${process.env.REACT_APP_BASEURL}/getNHSWeeklyData?name=${value}`;
        const res = await fetch(path, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("care_admin_token"),
          },
          signal,
        });
        const result = await res.json();
        if (result?.status) {
          const mapGpData = result?.data?.map((item) => camelCaseKeys(item));
          setGpList(mapGpData);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
          setGpList([]);
        } else {
          console.error("Fetch error:", error);
        }
      }
    } else {
      setGpList([]);
    }
  }
  useEffect(() => {
    if (clientId) {
      getGPListist();
    }
  }, []);

  function addDataInName(obj) {
    return `${obj.address1} - ${obj.nhsId} - ${obj.primaryRoleName} `;
  }

  return (
    <>
      <div className="card_inbox">
        {/* sShowing Medical Condition */}
        <div className="mb-4 btns_head">
          <h5 className="tb_title mt-0 d-flex justify-content-between">
            GP
            <DropdownButton id="dropdown-basic-button" title="Actions">
              <Dropdown.Item
                onClick={() => setIsOpen(true)}
                disabled={Profileaccess !== "full"}
              >
                Add
              </Dropdown.Item>
              {/* <Dropdown.Item
                                // className="action_table_btn"
                                onClick={() => {
                                    if (selectedRow?.length > 0) {
                                        medicdocEvent(selectedRow[0]);
                                    } else {
                                        ShowInfoSwal('Please select checkbox to edit!')
                                    }
                                }}
                                disabled={Profileaccess !== 'full'}
                            >
                                Edit
                            </Dropdown.Item> */}
              <Dropdown.Item
                onClick={() => {
                  if (selectedRow?.length > 0) {
                    handleShowDeletePopup(true);
                  } else {
                    ShowInfoSwal("Please select checkbox to delete!");
                  }
                }}
                disabled={Profileaccess !== "full"}
              >
                Delete{" "}
              </Dropdown.Item>
            </DropdownButton>
          </h5>
        </div>

        <Box className={cx.gpTable}>
          <CareMagnusTable
            tableHeight="130px"
            idParam="_id"
            columns={columns}
            rows={gpListRows}
            selectedRow={selectedRow}
            handleRowSelection={handleRowSelection}
            hideFooter={true}
          />
        </Box>

        {/* Add Gp Condition */}
        {isOpen && (
          <Modal
            show={isOpen}
            onHide={() => {
              setIsOpen(false);
            }}
          >
            <Modal.Body>
              <div
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {editMedConId === "" ? "Add" : "Edit"} GP
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={handleCloseAddGPPopup}
                      ></button>
                    </div>
                    <div className="modal-body" style={{ minHeight: "300px" }}>
                      <div className="row">
                        <div className="mb-2 position-relative">
                          <div className="">
                            <input
                              type="search"
                              className="form-control react-dropdown"
                              placeholder="Search for Company Name"
                              onChange={handleSearch}
                              value={gpName}
                            />
                          </div>

                          {
                            <div className={` ${cx.addMeddropdown}`}>
                              {gpList?.map((item, gpIndex) => {
                                const fullComapnyName = addDataInName(item);
                                return (
                                  <div
                                    className={`${cx.dropdownRow}`}
                                    key={gpIndex}
                                    onClick={() => {
                                      setGpName(item?.name);
                                      setNhsId(item?.nhsId);
                                      setGpList([]);
                                    }}
                                  >
                                    {item?.name} (
                                    <span style={{ fontSize: "12px" }}>
                                      {fullComapnyName}
                                    </span>
                                    )
                                  </div>
                                );
                              })}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-success"
                        onClick={handleSubmit}
                      >
                        {editMedConId === "" ? "Create" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

        <Modal
          className="viewModal"
          show={isDeleteOpen}
          onHide={handleCloseDeletePopup}
        >
          <Modal.Header closeButton2>
            <Modal.Title>
              <span>Delete GP</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <p>Are you sure you want to delete this entry ?</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <button
                className="btn submitBtn"
                onClick={handleCloseDeletePopup}
              >
                No
              </button>
              <button
                className="btn submitBtn"
                onClick={() => {
                  deletemultiple();
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
