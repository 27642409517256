// export function transformData(inputData) {
//     const transformedData = {};
//   console.log(inputData,'inputData---');

//     // Loop through each key in the input data
//     for (const key in inputData) {
//       if (inputData.hasOwnProperty(key)) {
//         const parts = key.split('-');

//         // If the key has a dash, it indicates it should be nested
//         if (parts.length > 1) {
//           // The first part is the parent key, the rest are the sub-keys
//           const parentKey = parts[0];
//           const subKey = parts.slice(1).join('-');

//           // Ensure the parent key exists in the transformedData object
//           if (!transformedData[parentKey]) {
//             transformedData[parentKey] = {};
//           }

//           // Add the sub-key and value under the parent key
//           transformedData[parentKey][subKey] = inputData[key];
//         } else {
//           // For keys without a dash, just add them directly to the transformedData object
//           transformedData[key] = inputData[key];
//         }
//       }
//     }

//     return transformedData;
//   }

export function transformData(inputData) {
  const transformedData = {};

  // Loop through each key in the input data
  for (const key in inputData) {
    if (inputData.hasOwnProperty(key)) {
      const parts = key.split('-');

      // If the key has a dash, it indicates it should be nested
      if (parts.length > 1) {
        const parentKey = parts[0];
        const subKey = parts.slice(1).join('-');

        // Ensure the parent key exists and is an object in the transformedData object
        if (!transformedData[parentKey] || typeof transformedData[parentKey] !== 'object') {
          transformedData[parentKey] = {};
        }

        // Add the sub-key and value under the parent key
        transformedData[parentKey][subKey] = inputData[key];
      } else {
        // For keys without a dash, just add them directly to the transformedData object
        transformedData[key] = inputData[key];
      }
    }
  }

  return transformedData;
}

export function reverseTransformDataWithKey(transformedData) {
  const flatData = {};

  // Helper function to recursively flatten nested objects
  function flatten(obj, parentKey = '') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}-${key}` : key;

        // If the value is an object, recursively process it
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flatten(obj[key], newKey); // Include the parent key in the recursion
        } else {
          // Otherwise, add the value to the flatData object
          flatData[newKey] = obj[key];
        }
      }
    }
  }

  flatten(transformedData);
  return flatData;
}

