export const fetch1 = async (api, body) => {
  // console.log(localStorage.getItem("token"));
  if (body) {
    let entries = Object.keys(body);
    let data = new FormData();
    for (let i = 0; i < entries.length; i++) {
      data.append(entries[i], body[entries[i]]);
    }
    const res = await fetch(api, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
      },
      body: data,
    });
    return await res.json();
  }
};

export const fetch2 = async (api, body) => {
  // console.log(localStorage.getItem("token"));
  if (body) {
    let entries = Object.keys(body);
    let data = new FormData();
    for (let i = 0; i < entries.length; i++) {
      data.append(entries[i], body[entries[i]]);
    }
    const res = await fetch(api, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("superadmin_token"),
      },
      body: data,
    });
    return await res.json();
  }
};

export const fetch3 = async (api, type) => {
  // console.log(localStorage.getItem("token"));
  const res = await fetch(api, {
    method: type,
    headers: {
      Authorization: localStorage.getItem("care_admin_token"),
    },
  });
  return await res.json();
};

export const fetch4 = async (api, body, header = {}) => {
  try {
    if (body) {
      const res = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...header,
        },
        body: JSON.stringify(body),
      });
      return await res.json();
    } else {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          ...header,
        },
      });
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};

export const deleteFetch = async (api, body, header = {}) => {
  try {
    if (body) {
      const res = await fetch(api, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          ...header,
        },
        body: JSON.stringify(body),
      });
      return await res.json();
    } else {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          ...header,
        },
      });
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};

export const httpRequest = async (api, method, body) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  } catch (error) {
    return await error;
  }
};

export const httpAdmin = async (api, method, body, dataType) => {
  try {
    if (body && dataType) {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
        method: method,
        headers: {
          Authorization: localStorage.getItem("care_admin_token"),
        },
        body: body,
      });
      return await res.json();
    } else if (body) {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("care_admin_token"),
        },
        body: JSON.stringify(body),
      });
      return await res.json();
    } else {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
        method: method,
        headers: {
          Authorization: localStorage.getItem("care_admin_token"),
        },
      });
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};

export const httpPortal = async (api, method, body, dataType) => {
  try {
    if (body && dataType === "formdata") {
      const res = await fetch(`${process.env.REACT_APP_fANDF}/${api}`, {
        method: method,
        headers: {
          Authorization: localStorage.getItem("fandfam_token"),
        },
        body: body,
      });
      return await res.json();
    } else if (body && dataType === "json") {
      const res = await fetch(`${process.env.REACT_APP_fANDF}/${api}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("fandfam_token"),
        },
        body: JSON.stringify(body),
      });
      return await res.json();
    } else if (body && dataType === "withoutToken") {
      const res = await fetch(`${process.env.REACT_APP_fANDF}/${api}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("fandfam_token"),
        },
        body: body,
      });
      return await res.json();
    } else {
      const res = await fetch(`${process.env.REACT_APP_fANDF}/${api}`, {
        method: method,
        headers: {
          Authorization: localStorage.getItem("fandfam_token"),
        },
      });
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};

export const httpSuperAdmin = async (api, body) => {
  try {
    if (body) {
      const res = await fetch(
        `${process.env.REACT_APP_SUPERADMIN_BASEURL}/${api}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("superadmin_token"),
          },
          body: JSON.stringify(body),
        }
      );
      return await res.json();
    } else {
      const res = await fetch(
        `${process.env.REACT_APP_SUPERADMIN_BASEURL}/${api}`,
        {
          method: "get",
          headers: {
            Authorization: localStorage.getItem("superadmin_token"),
          },
        }
      );
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};

export const httpPayments = async (api, body) => {
  let token = localStorage.getItem("care_admin_token");
  if (!token) token = localStorage.getItem("careAdminToken");
  try {
    if (body) {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      });
      return await res.json();
    } else {
      const res = await fetch(`${process.env.REACT_APP_BASEURL}/${api}`, {
        method: "get",
        headers: {
          Authorization: token,
        },
      });
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};

// this function is for no token

export const httpCareMagnus = async (api, body) => {
  try {
    if (body) {
      const res = await fetch(
        `${process.env.REACT_APP_SUPERADMIN_BASEURL}/${api}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      return await res.json();
    } else {
      const res = await fetch(
        `${process.env.REACT_APP_SUPERADMIN_BASEURL}/${api}`,
        {
          method: "get",
        }
      );
      return await res.json();
    }
  } catch (error) {
    return await error;
  }
};

export function limitToTwoDecimal(number) {
  return Math.round(number * 100) / 100;
}

export function sortByDateDescend(dataArray, dateProperty) {
  return dataArray.sort(
    (a, b) => new Date(b[dateProperty]) - new Date(a[dateProperty])
  );
}

export function combineDateTimeAndTimeZone(dateString, timeString, timeZone) {
  const usertimeZone =
    timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTimeString = `${dateString} ${timeString || "00:00"}:00`;
  const combinedDateTime = new Date(dateTimeString + usertimeZone);
  console.log(combinedDateTime, "combinedDateTime");

  return combinedDateTime;
}
