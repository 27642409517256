import React, { useState, useEffect, useRef, useMemo } from "react";
import { mainTasksArray, timeValues } from "../../constants/constants";
import cx from "./clients.module.css";
import { addMinutesToTime, formatDate } from "../../utils/CommonFunctions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const ClientCarePlanModal = ({
  isPlanBase,
  categoryType,
  activetemplateriskClass,
  taskList,
  carePlanFormVals,
  handleCarePlanInputChange,
  handleValueChangeByName,
}) => {
  const formatObj = {
    TemplateName: "Nobis tempor est deb",
    risk_profile: "low",
    riskProfileCategory: "low",
    updated_by: "dev test",
    questions: [],
    obeservation_needs_comments: "Placeat aut omnis p",
    outcomes: "Officia sint dolore",
    wishes: "Qui enim ut aut et o",
    strength: "Qui non duis optio ",
    Safeguarding: [
      {
        safeguardingConcern: "rr",
        crimeRefNumber: "cc",
        gpInformed: "xx",
        policeAware: "vv",
        safeguardingStartDate: "2025-01-08T00:00:00.000Z",
        safeguardingEndDate: "2025-01-16T00:00:00.000Z",
        _id: "677cb8d64f1d0be7d4b3cde2",
      },
      {
        safeguardingConcern: "Sed sunt error enim",
        crimeRefNumber: "Voluptatibus animi ",
        gpInformed: "Id neque ut nobis te",
        policeAware: "Et et enim a ut dolo",
        safeguardingStartDate: "2013-02-24T00:00:00.000Z",
        safeguardingEndDate: "1978-06-05T00:00:00.000Z",
        _id: "677cb9774f1d0be7d4b40483",
      },
      {
        safeguardingConcern: "Iste minim maiores v",
        crimeRefNumber: "Sint maiores minim ",
        gpInformed: "Doloribus ad in ipsu",
        policeAware: "In non eveniet atqu",
        safeguardingStartDate: "2025-01-14T00:00:00.000Z",
        safeguardingEndDate: "2024-09-01T00:00:00.000Z",
        _id: "677cb9774f1d0be7d4b40484",
      },
    ],
    risk_interventions: [
      {
        risk: "Ullam pariatur Nemo",
        interventions: "Aliqua Aliquip quis",
        _id: "677bbdde4f1d0be7d449bcd3",
      },
      {
        risk: "dfdsdf",
        interventions: "sdf",
        _id: "677cb8d64f1d0be7d4b3cde5",
      },
      {
        risk: "Qui impedit nulla i",
        interventions: "Nostrum et dolorem e",
        _id: "677cb9774f1d0be7d4b40489",
      },
    ],
    Task: [
      {
        shiftType: "specific time",
        startTime: "15:00",
        endTime: "15:30",
        taskName: "Personal Care",
        taskType: "Repudiandae iste mol",
        status: "false",
        riskProfileCategory: "low",
        comments: "Veritatis sit minim ",
        createdBy: "owner",
        details: [],
        _id: "677bbdde4f1d0be7d449bcd2",
      },
      {
        shiftType: "specific time",
        startTime: "18:00",
        endTime: "18:30",
        taskName: "Personal Care",
        taskType: "Consectetur quia qui",
        status: "false",
        riskProfileCategory: "low",
        comments: "Est placeat sit fac",
        createdBy: "owner",
        details: [],
        _id: "677cb9774f1d0be7d4b40486",
      },
    ],
    timeZone: "Asia/Calcutta",
  };

  const [taskObj, setTaskObj] = useState({
    taskName: "",
    desc: "",
    spTime: "No",
  });
  const [riskObj, setRiskObj] = useState({
    risk: "",
    interventions: "",
  });
  const initialSafeguarding = {
    safeguardingConcern: "",
    crimeRefNumber: "",
    gpInformed: "",
    safeguardingStartDate: "",
    safeguardingEndDate: "",
    policeAware: "",
  };
  const [safeGuardObj, setSafeGuardObj] = useState(initialSafeguarding);

  //  -------- tsk handlers -----------

  const handleTaskObjChange = (event) => {
    const { name, value } = event.target;
    setTaskObj((prev) => ({ ...prev, [name]: value }));
  };

  const handleTaskAdd = () => {
    if (taskObj.taskName === "") {
      return;
    }
    let userData = JSON.parse(localStorage.getItem("userData"));
    let newObj = {
      shiftType: "within time",
      startTime: "",
      endTime: "",
      taskName: categoryType,
      riskProfileCategory: activetemplateriskClass,
      taskType: taskObj.taskName,
      status: "false",
      comments: taskObj.desc,
      createdBy: userData.userType,
    };
    if (taskObj.spTime !== "No") {
      let calculatedEndTime = addMinutesToTime(taskObj.spTime, 30);
      newObj.shiftType = "specific time";
      newObj.startTime = taskObj.spTime;
      newObj.endTime = calculatedEndTime;
    }
    let newArr = [...carePlanFormVals.Task, newObj];
    handleValueChangeByName("Task", newArr);
    setTaskObj({ taskName: "", desc: "", spTime: "No" });
  };

  const addedTaskRemoveHandler = (e, indexToRemove) => {
    e.preventDefault();
    const clonefield = carePlanFormVals.Task.slice();
    clonefield.splice(indexToRemove, 1);
    handleValueChangeByName("Task", clonefield);
  };

  //  -------- risk handlers ----------

  const handleRiskObjChange = (event) => {
    const { name, value } = event.target;
    setRiskObj((prev) => ({ ...prev, [name]: value }));
  };

  const handleRiskAdd = () => {
    if (riskObj.risk === "") {
      return;
    }
    let newArr = [...carePlanFormVals.risk_interventions, riskObj];
    handleValueChangeByName("risk_interventions", newArr);
    setRiskObj({ risk: "", interventions: "" });
  };

  const addedRiskRemoveHandler = (e, indexToRemove) => {
    e.preventDefault();
    const clonefield = carePlanFormVals?.risk_interventions.slice();
    clonefield.splice(indexToRemove, 1);
    handleValueChangeByName("risk_interventions", clonefield);
  };

  //  -------- risk handlers ----------

  const handleSafegaurdObjChange = (event) => {
    const { name, value } = event.target;
    setSafeGuardObj((prev) => ({ ...prev, [name]: value }));
  };

  const handleSafeguardingAdd = () => {
    let newArr = [...carePlanFormVals.Safeguarding, safeGuardObj];
    handleValueChangeByName("Safeguarding", newArr);
    setSafeGuardObj(initialSafeguarding);
  };

  const addedSafegaurdRemoveHandler = (e, indexToRemove) => {
    e.preventDefault();
    const clonefield = carePlanFormVals?.Safeguarding.slice();
    clonefield.splice(indexToRemove, 1);
    handleValueChangeByName("Safeguarding", clonefield);
  };

  const selectedCategoryType = useMemo(() => {
    return mainTasksArray.find((item) => item.taskName === categoryType);
  }, [categoryType]);

  return (
    <>
      <div className="col-md-12">
        <h5>Observations, Needs and Comments</h5>
        <div className="d-flex mb-3">
          <textarea
            style={{ minHeight: "150px" }}
            className="form-control"
            placeholder=""
            disabled={isPlanBase}
            name="obeservation_needs_comments"
            value={carePlanFormVals?.obeservation_needs_comments}
            onChange={handleCarePlanInputChange}
          ></textarea>
        </div>

        <h5>Outcomes</h5>
        <div className="d-flex mb-3">
          <textarea
            style={{ minHeight: "150px" }}
            className="form-control"
            placeholder=""
            disabled={isPlanBase}
            name="outcomes"
            value={carePlanFormVals?.outcomes}
            onChange={handleCarePlanInputChange}
          ></textarea>
        </div>

        <h5>Strength</h5>
        <div className="d-flex mb-3">
          <textarea
            style={{ minHeight: "150px" }}
            className="form-control"
            placeholder=""
            disabled={isPlanBase}
            name="strength"
            value={carePlanFormVals?.strength}
            onChange={handleCarePlanInputChange}
          ></textarea>
        </div>

        <h5>Wishes</h5>
        <div className="d-flex mb-3">
          <textarea
            style={{ minHeight: "150px" }}
            className="form-control"
            placeholder=""
            disabled={isPlanBase}
            name="wishes"
            value={carePlanFormVals?.wishes}
            onChange={handleCarePlanInputChange}
          ></textarea>
        </div>
      </div>

      <div className="col-md-12 mb-2">
        <div className="d-flex">
          <div className={`col-md-4 position-relative ${cx.reactAutocomplete}`}>
            <label className="mb-1">Task</label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={taskObj.taskName}
              ListboxProps={{
                style: {
                  maxHeight: 80,
                  backgroundColor: "#d9e5f2",
                },
              }}
              onChange={(event, newVal) =>
                setTaskObj((prev) => ({
                  ...prev,
                  taskName: newVal ?? prev.taskName,
                }))
              }
              inputValue={taskObj?.taskName}
              onInputChange={(event, newInputValue) => {
                setTaskObj((prev) => ({
                  ...prev,
                  taskName: newInputValue,
                }));
              }}
              options={taskList?.[selectedCategoryType?.shortName] ?? []}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          </div>
          <div className="col-md-4 ms-3">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              placeholder="Add description"
              name="desc"
              value={taskObj.desc}
              onChange={handleTaskObjChange}
            />
          </div>
          <div className="col-4 ms-3">
            <label> Specific Time</label>
            <div className="d-flex">
              <div className="col-9">
                <select
                  className="form-select"
                  name="spTime"
                  value={taskObj.spTime}
                  onChange={handleTaskObjChange}
                >
                  <option value="No">No</option>
                  {timeValues.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <button className={cx.removeBtn} onClick={handleTaskAdd}>
                ADD
              </button>
            </div>
          </div>
        </div>
      </div>

      {carePlanFormVals.Task?.map((item, index) => {
        return (
          <div key={index} className="col-md-12 mb-2">
            <div className="d-flex">
              <div className="col-4">
                <input
                  className="form-control"
                  type="text"
                  value={item?.taskType}
                  disabled
                />
              </div>
              <div className="col-4 ms-3">
                <input
                  className="form-control"
                  type="text"
                  value={item?.comments}
                  disabled
                />
              </div>
              <div className="col-4 ms-3">
                <div className="d-flex">
                  <div className="col-9">
                    <input
                      className="form-control"
                      type="text"
                      value={
                        item?.shiftType === "specific time"
                          ? item.startTime
                          : "No"
                      }
                      disabled
                    />
                  </div>
                  <button
                    className={cx.removeBtn}
                    onClick={(e) => {
                      addedTaskRemoveHandler(e, index);
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="col-md-12 mb-2">
        <div className="d-flex">
          <div className={`col-6 position-relative ${cx.reactAutocomplete}`}>
            <label className="mb-1">Risks</label>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Add Risk"
              disabled={isPlanBase}
              name="risk"
              value={riskObj.risk}
              onChange={handleRiskObjChange}
            />
          </div>
          <div className="col-6" style={{marginLeft: '33px'}}>
            <label>Interventions</label>
            <div style={{ display: "flex" }}>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder=""
                  disabled={isPlanBase}
                  name="interventions"
                  value={riskObj.interventions}
                  onChange={handleRiskObjChange}
                />
              </div>
              <button className={cx.removeBtn} onClick={handleRiskAdd}>
                ADD
              </button>
            </div>
          </div>
        </div>
      </div>

      {carePlanFormVals.risk_interventions?.map((item, index) => {
        return (
          <div key={index} className="col-md-12 mb-2">
            <div className="d-flex">
              <div className="col-6">
                <input
                  className="form-control"
                  type="text"
                  value={item?.risk}
                  disabled
                />
              </div>
              <div className="col-6" style={{marginLeft: '33px'}}>
                <div
                  style={{ display: "flex" }}
                >
                  <div className="col-10">
                    <input
                      className="form-control"
                      type="text"
                      value={item.interventions}
                      disabled
                    />
                  </div>
                  <button
                    className={cx.removeBtn}
                    onClick={(e) => {
                      addedRiskRemoveHandler(e, index);
                    }}
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="col-md-12 my-3 d-flex">
        <div className="d-flex">
          <div className={`${cx.safeBox} d-flex col-md-11`}>
            <div
              className={`col-md-4 position-relative ${cx.reactAutocomplete}`}
            >
              <label className="mb-1">Safeguarding Concern</label>
              <input
                type="text"
                className="form-control"
                placeholder="Safeguarding Concern"
                name="safeguardingConcern"
                value={safeGuardObj?.safeguardingConcern}
                onChange={handleSafegaurdObjChange}
              />
            </div>
            <div className="col-4 ms-3">
              <label>Crime reference number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Crime reference number"
                name="crimeRefNumber"
                value={safeGuardObj?.crimeRefNumber}
                onChange={handleSafegaurdObjChange}
              />
            </div>

            <div className="col-4 ms-3">
              <label>GP informed</label>
              <input
                type="text"
                className="form-control"
                placeholder="GP informed"
                name="gpInformed"
                value={safeGuardObj?.gpInformed}
                onChange={handleSafegaurdObjChange}
              />
            </div>
            <div className="col-3 ms-3">
              <label>Police aware</label>
              <input
                type="text"
                className="form-control"
                placeholder="Have the police been made aware if applicable"
                name="policeAware"
                value={safeGuardObj?.policeAware}
                onChange={handleSafegaurdObjChange}
              />
            </div>

            <div className="col-5 ms-3">
              <label>Safeguarding concerns start date</label>
              <input
                type="date"
                className="form-control"
                name="safeguardingStartDate"
                value={safeGuardObj?.safeguardingStartDate}
                onChange={handleSafegaurdObjChange}
              />
            </div>
            <div className="col-5 ms-3">
              <label>Safeguarding concerns end date</label>
              <input
                type="date"
                className="form-control"
                name="safeguardingEndDate"
                value={safeGuardObj?.safeguardingEndDate}
                onChange={handleSafegaurdObjChange}
              />
            </div>
          </div>
          <div className="col-md-1">
            <button className={cx.removeBtn} onClick={handleSafeguardingAdd}>
              ADD
            </button>
          </div>
        </div>
      </div>
      {carePlanFormVals.Safeguarding?.map((item, i) => {
        return (
          <div className="col-md-12 mb-3 d-flex" key={i}>
            <div className="d-flex">
              <div className={`${cx.safeBox} d-flex col-md-11`}>
                <div
                  className={`col-md-4 position-relative ${cx.reactAutocomplete}`}
                >
                  <label className="mb-1">Safeguarding Concern</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="Safeguarding Concern"
                    value={item?.safeguardingConcern}
                  />
                </div>
                <div className="col-4 ms-3">
                  <label>Crime reference number</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="Crime reference number"
                    value={item?.crimeRefNumber}
                  />
                </div>

                <div className="col-4 ms-3">
                  <label>GP informed</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="GP informed"
                    value={item?.gpInformed}
                  />
                </div>
                <div className="col-3 ms-3">
                  <label>Police aware</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    placeholder="Have the police been made aware if applicable"
                    value={item?.policeAware}
                  />
                </div>

                <div className="col-5 ms-3">
                  <label>Safeguarding concerns start date</label>
                  <input
                    type="date"
                    className="form-control"
                    disabled
                    value={formatDate(item?.safeguardingStartDate)}
                  />
                </div>
                <div className="col-5 ms-3">
                  <label>Safeguarding concerns end date</label>
                  <input
                    type="date"
                    className="form-control"
                    disabled
                    value={formatDate(item?.safeguardingEndDate)}
                  />
                </div>
              </div>
              <div className="col-md-1">
                <button
                  className={cx.removeBtn}
                  onClick={(e) => {
                    addedSafegaurdRemoveHandler(e, i);
                  }}
                >
                  -
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ClientCarePlanModal;
