export const formulationDataLabelValues = [
    { label: "Date", value: "formulationDate" },
    { label: " Locations", value: "formulationLocation" },
    { label: " Performing Professionals", value: "performingProfessional" },
    { label: "Formulation", value: "formulation" },
    { label: "Person Complete Record", value: "personCompleteRecord" },
];

export const ContingencyDataLabelValues = [
    { label: "Trigger factors", value: "contingencyTriggerFactors" },
    { label: "What should happen", value: "whatShouldHappen" },
    {
        head: "Who Should Be Contacted:",
        objkey: 'whoShouldBeContacted',
        label: "Name",
        value: "name",
    },
    {
        objkey: 'whoShouldBeContacted',
        label: "Contact Details",
        value: "contactDetails",
    },
    {
        objkey: 'whoShouldBeContacted',
        label: "Role",
        value: "role",
    },
    {
        head: "Agreed with person or legitimate representative:",
        objkey: 'agreedWithPersonOrLegitimateRepresentative',
        label: "Person or legitimate representative",
        value: "personOrLegitimateRepresentative",
    },
    {
        objkey: 'agreedWithPersonOrLegitimateRepresentative',
        label: "Person full name",
        value: "personFullName",
    },
    {
        objkey: 'agreedWithPersonOrLegitimateRepresentative',
        label: "Role",
        value: "role",
    },
    {
        label: "Date this plan was last updated",
        value: "contingencyDateThisPlanWasLastUpdated",
    },
    {
        label: "Planned review date/interval",
        value: "contigencyPlannedReviewDate",
    },
    {
        head: "Responsibility for review:",
        objkey: 'contigencyResponsibilityForReview',
        label: "Name",
        value: "name",
    },
    {
        objkey: 'contigencyResponsibilityForReview',
        label: "Contact Details",
        value: "contactDetails",
    },
    {
        objkey: 'contigencyResponsibilityForReview',
        label: "Role",
        value: "role",
    },
    {
        head: "Coping strategies:",
        objkey: 'copingStrategies',
        label: "Coping Strategy",
        value: "copingStrategy",
    },
    {
        objkey: 'copingStrategies',
        label: "Start date",
        value: "start_date",
    },
    {
        objkey: 'copingStrategies',
        label: "End date",
        value: "end_date",
    },
    {
        label: "Relapse indicators / early warning signs",
        value: "relapseIndicators_earlyWarningSigns",
    },
    {
        head: "Advance statement:",
        objkey: 'advanceStatement',
        label: "Date",
        value: "statementDate",
    },
    {
        objkey: 'advanceStatement',
        label: "Location",
        value: "location",
    },
    {
        objkey: 'advanceStatement',
        label: "Advance statement",
        value: "advanceStatements",
    },
    {
        objkey: 'advanceStatement',
        label: "Location Of Documents",
        value: "locationOfDocument",
    },
    {
        head: "Contigency Performing Professional :",
        objkey: 'contigencyPerformingProfessional',
        label: "Name",
        value: "name",
    },
    {
        objkey: 'contigencyPerformingProfessional',
        label: "Role",
        value: "role",
    },
    {
        objkey: 'contigencyPerformingProfessional',
        label: "Grade",
        value: "grade",
    },
    {
        objkey: 'contigencyPerformingProfessional',
        label: "Speciality",
        value: "speciality",
    },
    {
        objkey: 'contigencyPerformingProfessional',
        label: "Organisation",
        value: "organisation",
    },
    {
        objkey: 'contigencyPerformingProfessional',
        label: "Professional Identifire",
        value: "professionalIdentifire",
    },
    {
        objkey: 'contigencyPerformingProfessional',
        label: "Date Completed",
        value: "dateCompleted",
    },
    {
        objkey: 'contigencyPerformingProfessional',
        label: "Contact Details",
        value: "contactDetails",
    },
    {
        head: "Contigency Person Completing Record :",
        objkey: 'contigencyPersonCompletingRecord',
        label: "Name",
        value: "name",
    },
    {
        objkey: 'contigencyPersonCompletingRecord',
        label: "Role",
        value: "role",
    },
    {
        objkey: 'contigencyPersonCompletingRecord',
        label: "Grade",
        value: "grade",
    },
    {
        objkey: 'contigencyPersonCompletingRecord',
        label: "Speciality",
        value: "speciality",
    },
    {
        objkey: 'contigencyPersonCompletingRecord',
        label: "Organisation",
        value: "organisation",
    },
    {
        objkey: 'contigencyPersonCompletingRecord',
        label: "Professional Identifire",
        value: "professionalIdentifire",
    },
    {
        objkey: 'contigencyPersonCompletingRecord',
        label: "Date Completed",
        value: "dateCompleted",
    },
    {
        objkey: 'contigencyPersonCompletingRecord',
        label: "Contact Details",
        value: "contactDetails",
    },
    {
        label: "Anticipatory Medicines Equipment",
        value: "anticipatoryMedicinesEquipment",
    },
    {
        label: "Lontigency Location",
        value: "contigencyLocation",
    },
];


export const additionalPlanLabelValues = [
    { label: 'Additional support plan content', value: 'additionalSupportPlanContent' },
    { label: 'Planned review date/interval', value: 'plannedReviewDate' },
    {
        head: "Responsibility for review:",
        objkey: 'responsibilityForReview',
        label: "Name",
        value: "name",
    },
    {
        objkey: 'responsibilityForReview',
        label: "Role",
        value: "role",
    },
    {
        objkey: 'responsibilityForReview',
        label: "Contact Details",
        value: "contactDetails",
    },
    {
        head: "Performing professional:",
        objkey: 'additionalSupportPerformingProfessional',
        label: "Name",
        value: "name",
    },
    {
        objkey: 'additionalSupportPerformingProfessional',
        label: "Role",
        value: "role",
    },
    {
        objkey: 'additionalSupportPerformingProfessional',
        label: "Grade",
        value: "grade",
    },
    {
        objkey: 'additionalSupportPerformingProfessional',
        label: "Speciality",
        value: "speciality",
    },
    {
        objkey: 'additionalSupportPerformingProfessional',
        label: "Organisation",
        value: "organisation",
    },
    {
        objkey: 'additionalSupportPerformingProfessional',
        label: "Professional Identifire",
        value: "professionalIdentifire",
    },
    {
        objkey: 'additionalSupportPerformingProfessional',
        label: "Date Completed",
        value: "dateCompleted",
    },
    {
        objkey: 'additionalSupportPerformingProfessional',
        label: "Contact Details",
        value: "contactDetails",
    },
    {
        head: "Person professional:",
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Name",
        value: "name",
    },
    {
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Role",
        value: "role",
    },
    {
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Grade",
        value: "grade",
    },
    {
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Speciality",
        value: "speciality",
    },
    {
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Organisation",
        value: "organisation",
    },
    {
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Professional Identifire",
        value: "professionalIdentifire",
    },
    {
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Date Completed",
        value: "dateCompleted",
    },
    {
        objkey: 'additionalSupportPersonCompletingRecord',
        label: "Contact Details",
        value: "contactDetails",
    },

    { label: 'Date this plan was last updated', value: 'dateThisPlanWasLastUpdated' },
    { label: 'Location', value: 'additionalSupportLocation' },
]