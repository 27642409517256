export const dischargingDepartment = [
  { label: "Informal", value: "Informal" },
  {
    label: "Formally detained under Mental Health Act Section 2",
    value: "Formally detained under Mental Health Act Section 2",
  },
  {
    label: "Formally detained under Mental Health Act Section 3",
    value: "Formally detained under Mental Health Act Section 3",
  },
  {
    label: "Formally detained under Mental Health Act Section 4",
    value: "Formally detained under Mental Health Act Section 4",
  },
  {
    label: "Formally detained under Mental Health Act Section 5(2)",
    value: "Formally detained under Mental Health Act Section 5(2)",
  },
  {
    label: "Formally detained under Mental Health Act Section 5(4)",
    value: "Formally detained under Mental Health Act Section 5(4)",
  },
  {
    label: "Formally detained under Mental Health Act Section 35",
    value: "Formally detained under Mental Health Act Section 35",
  },
  {
    label: "Formally detained under Mental Health Act Section 36",
    value: "Formally detained under Mental Health Act Section 36",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 37 with section 41 restrictions",
    value:
      "Formally detained under Mental Health Act Section 37 with section 41 restrictions",
  },
  {
    label: "Formally detained under Mental Health Act Section 37",
    value: "Formally detained under Mental Health Act Section 37",
  },
  {
    label: "Formally detained under Mental Health Act Section 38",
    value: "Formally detained under Mental Health Act Section 38",
  },
  {
    label: "Formally detained under Mental Health Act Section 44",
    value: "Formally detained under Mental Health Act Section 44",
  },
  {
    label: "Formally detained under Mental Health Act Section 46",
    value: "Formally detained under Mental Health Act Section 46",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 47 with section 49 restrictions",
    value:
      "Formally detained under Mental Health Act Section 47 with section 49 restrictions",
  },
  {
    label: "Formally detained under Mental Health Act Section 47",
    value: "Formally detained under Mental Health Act Section 47",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 48 with section 49 restrictions",
    value:
      "Formally detained under Mental Health Act Section 48 with section 49 restrictions",
  },
  {
    label: "Formally detained under Mental Health Act Section 48",
    value: "Formally detained under Mental Health Act Section 48",
  },
  {
    label: "Formally detained under Mental Health Act Section 135",
    value: "Formally detained under Mental Health Act Section 135",
  },
  {
    label: "Formally detained under Mental Health Act Section 136",
    value: "Formally detained under Mental Health Act Section 136",
  },
  {
    label:
      "Formally detained under Criminal Procedure(Insanity) Act 1964 as amended by the Criminal Procedures (Insanity and Unfitness to Plead) Act 1991",
    value:
      "Formally detained under Criminal Procedure(Insanity) Act 1964 as amended by the Criminal Procedures (Insanity and Unfitness to Plead) Act 1991",
  },
  {
    label: "Formally detained under other acts",
    value: "Formally detained under other acts",
  },
  {
    label:
      "Supervised Discharge (Mental Health (Patients in the Community) Act 1995) (Retired 03 November 2008 - but may apply to some patients until 3 May 2009)",
    value:
      "Supervised Discharge (Mental Health (Patients in the Community) Act 1995) (Retired 03 November 2008 - but may apply to some patients until 3 May 2009)",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 45A (Retired 01 September 2014)",
    value:
      "Formally detained under Mental Health Act Section 45A (Retired 01 September 2014)",
  },
  {
    label: "Subject to guardianship under Mental Health Act Section 7",
    value: "Subject to guardianship under Mental Health Act Section 7",
  },
  {
    label: "Subject to guardianship under Mental Health Act Section 37",
    value: "Subject to guardianship under Mental Health Act Section 37",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 45A (Limited direction in force)",
    value:
      "Formally detained under Mental Health Act Section 45A (Limited direction in force)",
  },
  {
    label:
      "Formally detained under Mental Health Act Section 45A (Limitation direction ended)",
    value:
      "Formally detained under Mental Health Act Section 45A (Limitation direction ended)",
  },
];

export const dischargeDestination = [
  {
    label:
      "Usual place of residence unless listed below, for example, a private dwelling whether owner occupied or owned by Local Authority, housing association or other landlord. This includes wardened accommodation but not residential accommodation where health care is provided. It also includes PATIENTS with no fixed abode.",
    value:
      "Usual place of residence unless listed below, for example, a private dwelling whether owner occupied or owned by Local Authority, housing association or other landlord. This includes wardened accommodation but not residential accommodation where health care is provided. It also includes PATIENTS with no fixed abode.",
  },
  {
    label:
      "Temporary place of residence when usually resident elsewhere (includes hotel, residential Educational Establishment).",
    value:
      "Temporary place of residence when usually resident elsewhere (includes hotel, residential Educational Establishment).",
  },
  {
    label:
      "Repatriation from high security psychiatric accommodation in an NHS Hospital Provider (NHS Trust or NHS Foundation Trust).",
    value:
      "Repatriation from high security psychiatric accommodation in an NHS Hospital Provider (NHS Trust or NHS Foundation Trust).",
  },
  { label: "Court", value: "Court" },
  { label: "Penal establishment", value: "Penal establishment" },
  {
    label: "Police Station / Police Custody Suite",
    value: "Police Station / Police Custody Suite",
  },
  {
    label: "High Security Psychiatric Hospital, Scotland",
    value: "High Security Psychiatric Hospital, Scotland",
  },
  {
    label:
      "NHS other Hospital Provider - high security psychiatric accommodation.",
    value:
      "NHS other Hospital Provider - high security psychiatric accommodation.",
  },
  {
    label: "NHS other Hospital Provider - medium secure unit",
    value: "NHS other Hospital Provider - medium secure unit",
  },
  {
    label:
      "NHS other Hospital Provider - WARD for general PATIENTS or the younger physically disabled.",
    value:
      "NHS other Hospital Provider - WARD for general PATIENTS or the younger physically disabled.",
  },
  {
    label:
      "NHS other Hospital Provider - WARD for maternity PATIENTS or Neonates.",
    value:
      "NHS other Hospital Provider - WARD for maternity PATIENTS or Neonates.",
  },
  {
    label:
      "NHS other Hospital Provider - WARD for PATIENTS who are mentally ill or have Learning Disabilities.",
    value:
      "NHS other Hospital Provider - WARD for PATIENTS who are mentally ill or have Learning Disabilities.",
  },
  { label: "Care Home With Nursing", value: "Care Home With Nursing" },
  { label: "Care Home Without Nursing", value: "Care Home Without Nursing" },
  {
    label: "Local Authority foster care",
    value: "Local Authority foster care",
  },
  { label: "PATIENT died or stillbirth", value: "PATIENT died or stillbirth" },
  {
    label:
      "Independent Sector Healthcare Provider run hospital - medium secure unit.",
    value:
      "Independent Sector Healthcare Provider run hospital - medium secure unit.",
  },
  {
    label:
      "Independent Sector Healthcare Provider run hospital - excluding medium secure unit.",
    value:
      "Independent Sector Healthcare Provider run hospital - excluding medium secure unit.",
  },
  { label: "Hospice", value: "Hospice" },
  {
    label: "ORGANISATION responsible for forced repatriation.",
    value: "ORGANISATION responsible for forced repatriation.",
  },
];

export const codedValueDischargeMethod = [
  {
    label: "Patient discharged on clinical advice or with clinical consent",
    value: "Patient discharged on clinical advice or with clinical consent",
  },
  {
    label:
      "Patient discharged him/herself or was discharged by a relative or advocate",
    value:
      "Patient discharged him/herself or was discharged by a relative or advocate",
  },
  {
    label:
      "Patient discharged by mental health review tribunal, Home Secretary or Court",
    value:
      "Patient discharged by mental health review tribunal, Home Secretary or Court",
  },
  {
    label: "Patient died",
    value: "Patient died",
  },
  {
    label: "Stillbirth",
    value: "Stillbirth",
  },
];

export const dischargedSpeciality = [
  { label: "General Surgery Service", value: "General Surgery Service" },
  { label: "Urology Service", value: "Urology Service" },
  { label: "Transplant Surgery Service", value: "Transplant Surgery Service" },
  { label: "Breast Surgery Service", value: "Breast Surgery Service" },
  { label: "Colorectal Surgery Service", value: "Colorectal Surgery Service" },
  {
    label: "Hepatobiliary and Pancreatic Surgery Service",
    value: "Hepatobiliary and Pancreatic Surgery Service",
  },
  {
    label: "Upper Gastrointestinal Surgery Service",
    value: "Upper Gastrointestinal Surgery Service",
  },
  { label: "Vascular Surgery Service", value: "Vascular Surgery Service" },
  { label: "Spinal Surgery Service", value: "Spinal Surgery Service" },
  { label: "Bariatric Surgery Service", value: "Bariatric Surgery Service" },
  {
    label: "Trauma and Orthopaedic Service",
    value: "Trauma and Orthopaedic Service",
  },
  { label: "Orthopaedic Service", value: "Orthopaedic Service" },
  { label: "Endocrine Surgery Service", value: "Endocrine Surgery Service" },
  { label: "Trauma Surgery Service", value: "Trauma Surgery Service" },
  {
    label: "Ear Nose and Throat Service",
    value: "Ear Nose and Throat Service",
  },
  { label: "Ophthalmology Service", value: "Ophthalmology Service" },
  { label: "Oral Surgery Service", value: "Oral Surgery Service" },
  {
    label: "Restorative Dentistry Service",
    value: "Restorative Dentistry Service",
  },
  { label: "Orthodontic Service", value: "Orthodontic Service" },
  {
    label: "Maxillofacial Surgery Service",
    value: "Maxillofacial Surgery Service",
  },
  {
    label: "Oral and Maxillofacial Surgery Service",
    value: "Oral and Maxillofacial Surgery Service",
  },
  { label: "Neurosurgical Service", value: "Neurosurgical Service" },
  { label: "Plastic Surgery Service", value: "Plastic Surgery Service" },
  { label: "Burns Care Service", value: "Burns Care Service" },
  {
    label: "Cardiothoracic Surgery Service",
    value: "Cardiothoracic Surgery Service",
  },
  { label: "Cardiac Surgery Service", value: "Cardiac Surgery Service" },
  { label: "Thoracic Surgery Service", value: "Thoracic Surgery Service" },
  {
    label: "Cardiothoracic Transplantation Service",
    value: "Cardiothoracic Transplantation Service",
  },
  { label: "Pain Management Service", value: "Pain Management Service" },
  {
    label: "Paediatric Dentistry Service",
    value: "Paediatric Dentistry Service",
  },
  { label: "Paediatric Surgery Service", value: "Paediatric Surgery Service" },
  { label: "Paediatric Urology Service", value: "Paediatric Urology Service" },
  {
    label: "Paediatric Transplantation Surgery Service",
    value: "Paediatric Transplantation Surgery Service",
  },
  {
    label: "Paediatric Gastrointestinal Surgery Service",
    value: "Paediatric Gastrointestinal Surgery Service",
  },
  {
    label: "Paediatric Trauma and Orthopaedic Service",
    value: "Paediatric Trauma and Orthopaedic Service",
  },
  {
    label: "Paediatric Ear Nose and Throat Service",
    value: "Paediatric Ear Nose and Throat Service",
  },
  {
    label: "Paediatric Ophthalmology Service",
    value: "Paediatric Ophthalmology Service",
  },
  {
    label: "Paediatric Oral and Maxillofacial Surgery Service",
    value: "Paediatric Oral and Maxillofacial Surgery Service",
  },
  {
    label: "Paediatric Neurosurgery Service",
    value: "Paediatric Neurosurgery Service",
  },
  {
    label: "Paediatric Plastic Surgery Service",
    value: "Paediatric Plastic Surgery Service",
  },
  {
    label: "Paediatric Burns Care Service",
    value: "Paediatric Burns Care Service",
  },
  {
    label: "Paediatric Cardiac Surgery Service",
    value: "Paediatric Cardiac Surgery Service",
  },
  {
    label: "Paediatric Thoracic Surgery Service",
    value: "Paediatric Thoracic Surgery Service",
  },
  {
    label: "Paediatric Epilepsy Service",
    value: "Paediatric Epilepsy Service",
  },
  {
    label: "Paediatric Clinical Pharmacology Service",
    value: "Paediatric Clinical Pharmacology Service",
  },
  {
    label: "Paediatric Palliative Medicine Service",
    value: "Paediatric Palliative Medicine Service",
  },
  {
    label: "Paediatric Pain Management Service",
    value: "Paediatric Pain Management Service",
  },
  {
    label: "Paediatric Intensive Care Service",
    value: "Paediatric Intensive Care Service",
  },
  {
    label: "Paediatric Hepatology Service",
    value: "Paediatric Hepatology Service",
  },
  {
    label: "Paediatric Gastroenterology Service",
    value: "Paediatric Gastroenterology Service",
  },
  {
    label: "Paediatric Endocrinology Service",
    value: "Paediatric Endocrinology Service",
  },
  {
    label: "Paediatric Clinical Haematology Service",
    value: "Paediatric Clinical Haematology Service",
  },
  {
    label: "Paediatric Audio Vestibular Medicine Service",
    value: "Paediatric Audio Vestibular Medicine Service",
  },
  {
    label: "Paediatric Clinical Immunology and Allergy Service",
    value: "Paediatric Clinical Immunology and Allergy Service",
  },
  {
    label: "Paediatric Infectious Diseases Service",
    value: "Paediatric Infectious Diseases Service",
  },
  {
    label: "Paediatric Dermatology Service",
    value: "Paediatric Dermatology Service",
  },
  {
    label: "Paediatric Respiratory Medicine Service",
    value: "Paediatric Respiratory Medicine Service",
  },
  {
    label: "Paediatric Nephrology Service",
    value: "Paediatric Nephrology Service",
  },
  {
    label: "Paediatric Medical Oncology Service",
    value: "Paediatric Medical Oncology Service",
  },
  {
    label: "Paediatric Inherited Metabolic Medicine Service",
    value: "Paediatric Inherited Metabolic Medicine Service",
  },
  {
    label: "Paediatric Rheumatology Service",
    value: "Paediatric Rheumatology Service",
  },
  {
    label: "Paediatric Diabetes Service",
    value: "Paediatric Diabetes Service",
  },
  {
    label: "Paediatric Cystic Fibrosis Service",
    value: "Paediatric Cystic Fibrosis Service",
  },
  {
    label: "Paediatric Emergency Medicine Service",
    value: "Paediatric Emergency Medicine Service",
  },
  {
    label: "Paediatric Interventional Radiology Service",
    value: "Paediatric Interventional Radiology Service",
  },
  {
    label: "Community Paediatric Service",
    value: "Community Paediatric Service",
  },
  {
    label: "Paediatric Neurodisability Service",
    value: "Paediatric Neurodisability Service",
  },
  {
    label: "Paediatric Cardiology Service",
    value: "Paediatric Cardiology Service",
  },
  {
    label: "Paediatric Neurology Service",
    value: "Paediatric Neurology Service",
  },
  { label: "Emergency Medicine Service", value: "Emergency Medicine Service" },
  { label: "Anaesthetic Service", value: "Anaesthetic Service" },
  {
    label: "Intensive Care Medicine Service",
    value: "Intensive Care Medicine Service",
  },
  {
    label: "Aviation and Space Medicine Service",
    value: "Aviation and Space Medicine Service",
  },
  {
    label: "General Internal Medicine Service",
    value: "General Internal Medicine Service",
  },
  { label: "Gastroenterology Service", value: "Gastroenterology Service" },
  { label: "Endocrinology Service", value: "Endocrinology Service" },
  {
    label: "Clinical Haematology Service",
    value: "Clinical Haematology Service",
  },
  {
    label: "Clinical Physiology Service",
    value: "Clinical Physiology Service",
  },
  {
    label: "Clinical Pharmacology Service",
    value: "Clinical Pharmacology Service",
  },
  { label: "Hepatology Service", value: "Hepatology Service" },
  { label: "Diabetes Service", value: "Diabetes Service" },
  {
    label: "Blood and Marrow Transplantation Service",
    value: "Blood and Marrow Transplantation Service",
  },
  { label: "Haemophilia Service", value: "Haemophilia Service" },
  {
    label: "Audio Vestibular Medicine Service",
    value: "Audio Vestibular Medicine Service",
  },
  { label: "Clinical Genetics Service", value: "Clinical Genetics Service" },
  {
    label: "Clinical Immunology and Allergy Service",
    value: "Clinical Immunology and Allergy Service",
  },
  {
    label: "Rehabilitation Medicine Service",
    value: "Rehabilitation Medicine Service",
  },
  {
    label: "Palliative Medicine Service",
    value: "Palliative Medicine Service",
  },
  {
    label: "Clinical Immunology Service",
    value: "Clinical Immunology Service",
  },
  { label: "Allergy Service", value: "Allergy Service" },
  { label: "Intermediate Care Service", value: "Intermediate Care Service" },
  { label: "Respite Care Service", value: "Respite Care Service" },
  { label: "Cardiology Service", value: "Cardiology Service" },
  {
    label: "Clinical Microbiology Service",
    value: "Clinical Microbiology Service",
  },
  { label: "Spinal Injuries Service", value: "Spinal Injuries Service" },
  { label: "Anticoagulant Service", value: "Anticoagulant Service" },
  {
    label: "Sport and Exercise Medicine Service",
    value: "Sport and Exercise Medicine Service",
  },
  {
    label: "Acute Internal Medicine Service",
    value: "Acute Internal Medicine Service",
  },
  {
    label: "Cardiac Rehabilitation Service",
    value: "Cardiac Rehabilitation Service",
  },
  { label: "Stroke Medicine Service", value: "Stroke Medicine Service" },
  {
    label: "Transient Ischaemic Attack Service",
    value: "Transient Ischaemic Attack Service",
  },
  { label: "Dermatology Service", value: "Dermatology Service" },
  {
    label: "Congenital Heart Disease Service",
    value: "Congenital Heart Disease Service",
  },
  { label: "Rare Disease Service", value: "Rare Disease Service" },
  {
    label: "Inherited Metabolic Medicine Service",
    value: "Inherited Metabolic Medicine Service",
  },
  {
    label: "Respiratory Medicine Service",
    value: "Respiratory Medicine Service",
  },
  {
    label: "Respiratory Physiology Service",
    value: "Respiratory Physiology Service",
  },
  {
    label: "Pulmonary Rehabilitation Service",
    value: "Pulmonary Rehabilitation Service",
  },
  {
    label: "Adult Cystic Fibrosis Service",
    value: "Adult Cystic Fibrosis Service",
  },
  {
    label: "Complex Specialised Rehabilitation Service",
    value: "Complex Specialised Rehabilitation Service",
  },
  {
    label: "Specialist Rehabilitation Service",
    value: "Specialist Rehabilitation Service",
  },
  {
    label: "Local Specialist Rehabilitation Service",
    value: "Local Specialist Rehabilitation Service",
  },
  { label: "Sleep Medicine Service", value: "Sleep Medicine Service" },
  {
    label: "Post-COVID-19 Syndrome Service",
    value: "Post-COVID-19 Syndrome Service",
  },
  {
    label: "Infectious Diseases Service",
    value: "Infectious Diseases Service",
  },
  { label: "Tropical Medicine Service", value: "Tropical Medicine Service" },
  {
    label: "Genitourinary Medicine Service",
    value: "Genitourinary Medicine Service",
  },
  { label: "Renal Medicine Service", value: "Renal Medicine Service" },
  { label: "Medical Oncology Service", value: "Medical Oncology Service" },
  { label: "Nuclear Medicine Service", value: "Nuclear Medicine Service" },
  { label: "Neurology Service", value: "Neurology Service" },
  {
    label: "Clinical Neurophysiology Service",
    value: "Clinical Neurophysiology Service",
  },
  { label: "Rheumatology Service", value: "Rheumatology Service" },
  { label: "Paediatric Service", value: "Paediatric Service" },
  {
    label: "Neonatal Critical Care Service",
    value: "Neonatal Critical Care Service",
  },
  { label: "Well Baby Service", value: "Well Baby Service" },
  { label: "Elderly Medicine Service", value: "Elderly Medicine Service" },
  {
    label: "Orthogeriatric Medicine Service",
    value: "Orthogeriatric Medicine Service",
  },
  { label: "Dental Medicine Service", value: "Dental Medicine Service" },
  {
    label: "Special Care Dentistry Service",
    value: "Special Care Dentistry Service",
  },
  {
    label: "Medical Ophthalmology Service",
    value: "Medical Ophthalmology Service",
  },
  {
    label: "Ophthalmic and Vision Science Service",
    value: "Ophthalmic and Vision Science Service",
  },
  { label: "Obstetrics Service", value: "Obstetrics Service" },
  { label: "Gynaecology Service", value: "Gynaecology Service" },
  {
    label: "Gynaecological Oncology Service",
    value: "Gynaecological Oncology Service",
  },
  {
    label: "Community Sexual and Reproductive Health Service",
    value: "Community Sexual and Reproductive Health Service",
  },
  { label: "Fetal Medicine Service", value: "Fetal Medicine Service" },
  { label: "Midwifery Service", value: "Midwifery Service" },
  {
    label: "Clinical Psychology Service",
    value: "Clinical Psychology Service",
  },
  {
    label: "Learning Disability Service",
    value: "Learning Disability Service",
  },
  {
    label: "Adult Mental Health Service",
    value: "Adult Mental Health Service",
  },
  {
    label: "Child and Adolescent Psychiatry Service",
    value: "Child and Adolescent Psychiatry Service",
  },
  {
    label: "Forensic Psychiatry Service",
    value: "Forensic Psychiatry Service",
  },
  {
    label: "Medical Psychotherapy Service",
    value: "Medical Psychotherapy Service",
  },
  { label: "Old Age Psychiatry Service", value: "Old Age Psychiatry Service" },
  { label: "Eating Disorders Service", value: "Eating Disorders Service" },
  { label: "Addiction Service", value: "Addiction Service" },
  { label: "Liaison Psychiatry Service", value: "Liaison Psychiatry Service" },
  {
    label: "Psychiatric Intensive Care Service",
    value: "Psychiatric Intensive Care Service",
  },
  {
    label: "Perinatal Mental Health Service",
    value: "Perinatal Mental Health Service",
  },
  {
    label: "Mental Health Recovery and Rehabilitation Service",
    value: "Mental Health Recovery and Rehabilitation Service",
  },
  {
    label: "Mental Health Dual Diagnosis Service",
    value: "Mental Health Dual Diagnosis Service",
  },
  {
    label: "Dementia Assessment Service",
    value: "Dementia Assessment Service",
  },
  { label: "Neuropsychiatry Service", value: "Neuropsychiatry Service" },
  { label: "Clinical Oncology Service", value: "Clinical Oncology Service" },
  {
    label: "Interventional Radiology Service",
    value: "Interventional Radiology Service",
  },
  { label: "Diagnostic Imaging Service", value: "Diagnostic Imaging Service" },
  { label: "Chemical Pathology Service", value: "Chemical Pathology Service" },
  { label: "Medical Virology Service", value: "Medical Virology Service" },
  { label: "Physiotherapy Service", value: "Physiotherapy Service" },
  {
    label: "Occupational Therapy Service",
    value: "Occupational Therapy Service",
  },
  {
    label: "Speech and Language Therapy Service",
    value: "Speech and Language Therapy Service",
  },
  { label: "Podiatry Service", value: "Podiatry Service" },
  { label: "Dietetics Service", value: "Dietetics Service" },
  { label: "Orthoptics Service", value: "Orthoptics Service" },
  { label: "Prosthetics Service", value: "Prosthetics Service" },
  { label: "Orthotics Service", value: "Orthotics Service" },
  { label: "Dramatherapy Service", value: "Dramatherapy Service" },
  { label: "Art Therapy Service", value: "Art Therapy Service" },
  { label: "Music Therapy Service", value: "Music Therapy Service" },
  { label: "Optometry Service", value: "Optometry Service" },
  { label: "Podiatric Surgery Service", value: "Podiatric Surgery Service" },
  {
    label: "Urological Physiology Service",
    value: "Urological Physiology Service",
  },
  {
    label: "Vascular Physiology Service",
    value: "Vascular Physiology Service",
  },
  { label: "Cardiac Physiology Service", value: "Cardiac Physiology Service" },
  {
    label: "Gastrointestinal Physiology Service",
    value: "Gastrointestinal Physiology Service",
  },
  { label: "Audiology Service", value: "Audiology Service" },
  { label: "Diabetic Education Service", value: "Diabetic Education Service" },
  {
    label: "Retired but retained for historical purposes",
    value: "Retired but retained for historical purposes",
  },
];
