import React, { useState, useEffect } from "react";
import "./careteam.css";
import axios from "axios";
import Moment from "react-moment";
import { Fragment } from "react";
import { MdArrowRight } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { combineDateTime, convertToTimeZone, formatDate, getFullName, getMondayDate, getMonthAgoDate, sendDateFormat } from "../../utils/CommonFunctions";
import { sortByDateDescend } from "../../Apis/commonApis";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { useSelector } from "react-redux";
import JournalDetailFacility from "./JournalDetailFacility";
import JournalDetailAgency from "./JournalDetailAgency";

const CareTeamJournalAsApp = (props) => {
  const monthAgoDate = getMonthAgoDate();
  const { careteamId } = props;

  const { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const [journalData, setJournalData] = useState([]);
  const [careteamJournal, setcareteamJournal] = useState({});
  const [loading, setLoading] = useState(false);

  const [filterType, setFilterType] = useState("custom");
  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (currentCareSiteData) {
      if (filterType === "custom" && endDate) {
        getJournalbyClientId(startDate, endDate);
      } else if (filterType === "week") {
        const mondayDate = getMondayDate(new Date());
        getJournalbyClientId(formatDate(mondayDate), formatDate(new Date()));
      } else if (filterType === "today") {
        const sendDate = sendDateFormat(new Date());
        getJournalbyClientId(sendDate, sendDate);
      }
    }
  }, [filterType, dateRange, currentCareSiteData]);

  const getJournalbyClientId = (start, end) => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASEURL}/CareMemberJournal?careTeamId=${careteamId}&careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    }).then((res) => {
      setLoading(false);
      let ClientData = res?.data?.result;
      if (ClientData.length > 0) {
        if (currentCareSiteData?.carefacility === "facalities") {
          handleFacilityData(ClientData);
        } else if (currentCareSiteData?.carefacility === "agency") {
          handleAgencyData(ClientData);
        }
      } else {
        setJournalData([]);
        setcareteamJournal([]);
      }
    }).catch((error) => console.log(`Error: ${error}`));
  };

  function handleFacilityData(ClientData) {
    const careteamJournals = [];
    ClientData?.forEach((shift) => {
      if (shift) {
        const shiftdata = shift?.assignedCareTeam[0];
        const totalFacilityTask = shift?.assignedCareTeam?.reduce((acc, obj) => (acc + (obj.Task?.length + 1)), 0);
        const completedTasks = getTotaCompletedTasks(shift);
        const forClientName = shiftdata?.forClient ?? getFullName(shiftdata?.clientId)
        careteamJournals.push({
          _shiftId: shift?._id,
          _id: shiftdata?._id,
          Date: shiftdata?.Date ?? shift.Date,
          shiftDateTime: combineDateTime(shift.Date, shift.startingTime, shift.timeZone),
          totalTask: totalFacilityTask,
          completedTasks: completedTasks,
          AssignedCareTeam: shift?.assignedCareTeam,
          // endDate: shiftdata?.endDate ?? shift?.endDate,
          Task: shift?.assignedCareTeam?.flatMap(obj => obj.Task),
          notesData: shift?.notesData,
          forClient: forClientName,
          startingTime: convertToTimeZone(shift.shiftStartDatetime),
          endingTime: convertToTimeZone(shift?.shiftEndDatetime),
          shiftTimeZone: shiftdata.timeZone,
          careTeamId: shiftdata?.careTeamId,
          // checkinDateTime: shiftdata?.checkinDateTime,
          // checkoutDateTime: shiftdata?.checkoutDateTime,
          fndfRating: shiftdata?.fandfRating ?? 0,
          clientId: shiftdata?.clientId,
          shiftApprovalstatus: shiftdata?.shiftApprovalstatus ?? '',
          shiftEndDatetime: shiftdata?.shiftEndDatetime ?? '',
          status: shiftdata?.status ?? shift?.status,
          templateType: shiftdata?.templateType ?? '',
          wellbeingAssessment: shiftdata?.wellbeingAssessment ?? {},
        });
      }
    });
    let sortedObj = sortByDateDescend(careteamJournals, 'shiftDateTime');
    let groupdate = groupJournalDataByDate(sortedObj);
    // let groupdate = groupItems(sortedObj, "Date");
    // groupdate = Object.entries(groupdate).map(([k, v]) => ({ [k]: v }));
    setJournalData(groupdate);
    setcareteamJournal(sortedObj[0]);
  }
  function handleAgencyData(ClientData) {
    let careteamJournals = [];
    ClientData?.forEach((shift) => {
      if (shift) {
        const shiftdata = shift?.shiftData;
        const forClientName = shiftdata?.forClient ?? getFullName(shiftdata?.clientId);
        const taskCompleted = completedTasks(shiftdata);
        careteamJournals.push({
          _shiftId: shiftdata?._id,
          _id: shiftdata?._id,
          totalTask: taskCompleted[1],
          completedTasks: taskCompleted[0],
          Date: shiftdata?.Date ?? shift.Date,
          shiftDateTime: combineDateTime(shiftdata.Date, shiftdata.startingTime, shiftdata.timeZone),
          endDate: shiftdata?.endDate ?? shift?.endDate,
          Task: shiftdata?.Task,
          notesData: shiftdata.notesData,
          forClient: forClientName,
          startingTime: shiftdata?.startingTime ?? shift?.startingTime,
          endingTime: shiftdata?.endingTime ?? shift?.endingTime,
          shiftTimeZone: shiftdata.timeZone,
          careTeamId: shiftdata?.careTeamId[0],
          checkinDateTime: shiftdata?.checkinDateTime,
          checkoutDateTime: shiftdata?.checkoutDateTime,
          fndfRating: shiftdata?.fandfRating ?? 0,
          clientId: shiftdata?.clientId[0],
          shiftApprovalstatus: shiftdata?.shiftApprovalstatus ?? '',
          shiftEndDatetime: shiftdata?.shiftEndDatetime ?? '',
          status: shiftdata?.status ?? shift?.status,
          templateType: shiftdata?.templateType ?? '',
          wellbeingAssessment: shiftdata?.wellbeingAssessment ?? {},
        });
      }
    });

    let sortedObj = sortByDateDescend(careteamJournals, 'shiftDateTime');
    let groupdate = groupJournalDataByDate(sortedObj);
    // let groupdate = groupItems(sortedObj, "Date");
    // groupdate = Object.entries(groupdate).map(([k, v]) => ({ [k]: v }));
    setJournalData(groupdate);
    setcareteamJournal(sortedObj[0]);
  }

  const calendarStrings = {
    lastDay: "[Yesterday ]",
    sameDay: "[Today ]",
    nextDay: "[Tomorrow ]",
    lastWeek: "L",
    nextWeek: "L",
    sameElse: "L",
  };

  function customDateChangeHandler(daterange) {
    setDateRange(daterange);
    // if()
    // Example usage:
    // if (daterange[1]) {
    //   const startDate = new Date(daterange[0]);
    //   const endDate = new Date(daterange[1]);
    //   const datesInRange = getDatesInRange(startDate, endDate);
    //   const filteredData = journalAllData.filter((date) =>
    //     datesInRange.includes(Object.keys(date)[0])
    //   );
    //   setJournalData(filteredData);
    // } else if (!daterange[0] && !daterange[1]) {
    //   setJournalData(journalAllData);
    // }
  }

  function completedTasks(journalData) {
    const tasks = [...journalData?.Task, journalData?.wellbeingAssessment];
    const completed = tasks?.filter((item) => item?.status === "true" && item.shiftId === journalData._id);
    return [
      completed?.length > 0 ? completed?.length : 0,
      tasks?.length > 0 ? tasks?.length : 0,
    ];
  }

  const activeTab = {
    background: '#f0e8f7'
  }

  return (
    <>
      <div className="row clientList">
        <div className="col-md-6 shiftsHistory">
          <h5 style={{ color: "#9B51E0" }}>Shifts History</h5>
          <ul className="filter_box me-2 mb-1">
            <button
              exact
              className={filterType === "today" ? "active" : ""}
              onClick={() => setFilterType("today")}
            >
              Today
            </button>
            <button
              className={filterType === "week" ? "active " : ""}
              onClick={() => setFilterType("week")}
            >
              This Week
            </button>
            <button
              className={filterType === "custom" ? "active" : ""}
              onClick={() => setFilterType("custom")}
            >
              Custom
            </button>
            {filterType === "custom" ? (
              <div className="datepicker_input">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    customDateChangeHandler(update);
                  }}
                  isClearable={true}
                />
              </div>
            ) : (
              <div className="container">&ensp;</div>
            )}
          </ul>
        </div>
        <div className="col-md-6"></div>
        <div className="col-md-6 journalListScroll">
          <ul className="menuList">
            {loading && <p>Loading...</p>}
            {journalData.length > 0 &&
              journalData?.map((journal, jIndex) => {
                // const date = Object.keys(journal);
                // const datewisejournal = Object.values(journal).flatMap(
                //   (num) => num
                // );
                return (
                  <Fragment key={jIndex}>
                    <h5 className="titlen">
                      <span style={{ color: "#BB6BD9" }}>
                        <Moment calendar={calendarStrings}>{journal.date}</Moment>
                      </span>{" "}
                      <br />
                      <Moment format="Do MMM YYYY">{journal.date}</Moment>
                    </h5>
                    {journal?.shifts?.map((item, index) => {
                      return (
                        <li
                          style={item._id === careteamJournal._id ? activeTab : {}}
                          key={index}
                          onClick={() => {
                            setcareteamJournal(item);
                          }}
                        >
                          <a>
                            {/* <img alt="" src={item?.clientId[0]?.image} /> */}
                            <div className="body">
                              <p style={{ color: "#BB6BD9" }}>
                                {item?.startingTime} - {item?.endingTime}
                              </p>
                              <h5>
                                {/* {`${item?.clientId?.[0]?.first_Name}'s`}  */}
                                Shift Summary
                              </h5>{" "}
                              <MdArrowRight className="arrowIcon" />
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </Fragment>
                );
              })}
            {loading === false && journalData.length === 0 && (
              <p>No Shift Available</p>
            )}{" "}
          </ul>
        </div>
        {Object.keys(careteamJournal).length !== 0 && (
          <>
            {(currentCareSiteData?.carefacility === "facalities") &&
              <JournalDetailFacility careteamJournal={careteamJournal} />
            }
            {(currentCareSiteData?.carefacility === "agency") &&
              <JournalDetailAgency careteamJournal={careteamJournal} />
            }
          </>

        )}
      </div>
    </>
  );
};

export default CareTeamJournalAsApp;

function groupJournalDataByDate(shiftdata) {
  if (shiftdata.length < 1) {
    return [];
  }
  const grouped = {};
  shiftdata?.forEach((item) => {
    const dateLabel = new Date(item.Date).toDateString();

    if (!grouped[dateLabel]) {
      grouped[dateLabel] = [];
    }
    grouped[dateLabel].push(item);
  });

  return Object.keys(grouped).map((date) => ({
    date,
    shifts: grouped[date],
  }));
}

function getTotaCompletedTasks(shift) {
  return shift?.assignedCareTeam?.reduce((acc, obj) => {
    let completeTasksInObject = obj.Task.reduce((count, task) => {
      return count + ((task.status === 'true' && task.shiftId === shift._id && task?.doneBy === obj.careTeamId?._id) ? 1 : 0);
    }, 0);
    if (obj.wellbeingAssessment.status === 'true' && obj.wellbeingAssessment?.shiftId === shift._id && obj?.wellbeingAssessmentDoneBy === obj.careTeamId?._id) {
      completeTasksInObject = completeTasksInObject + 1;
    }
    return acc + completeTasksInObject;
  }, 0);
}