export const addressType = [
  "Main Permanent Residence",
  "Other Permanent Residence",
  "Temporary Residence",
  "Correspondence (Non-Residence)",
  "Main Business Premises",
  "Invoice",
  "Other Business Premises",
  "Safe Haven ADDRESS",
];

export const ethnicity = [
  { label: "White - British", value: "White - British" },
  { label: "White - Irish", value: "White - Irish" },
  {
    label: "White - Any other White background",
    value: "White - Any other White background",
  },
  {
    label: "Mixed - White and Black Caribbean",
    value: "Mixed - White and Black Caribbean",
  },
  {
    label: "Mixed - White and Black African",
    value: "Mixed - White and Black African",
  },
  { label: "Mixed - White and Asian", value: "Mixed - White and Asian" },
  {
    label: "Mixed - Any other mixed background",
    value: "Mixed - Any other mixed background",
  },
  {
    label: "Asian or Asian British - Indian",
    value: "Asian or Asian British - Indian",
  },
  {
    label: "Asian or Asian British - Pakistani",
    value: "Asian or Asian British - Pakistani",
  },
  {
    label: "Asian or Asian British - Bangladeshi",
    value: "Asian or Asian British - Bangladeshi",
  },
  {
    label: "Asian or Asian British - Any other Asian background",
    value: "Asian or Asian British - Any other Asian background",
  },
  {
    label: "Black or Black British - Caribbean",
    value: "Black or Black British - Caribbean",
  },
  {
    label: "Black or Black British - African",
    value: "Black or Black British - African",
  },
  {
    label: "Black or Black British - Any other Black background",
    value: "Black or Black British - Any other Black background",
  },
  {
    label: "Other Ethnic Groups - Chinese",
    value: "Other Ethnic Groups - Chinese",
  },
  {
    label: "Other Ethnic Groups - Any other ethnic group",
    value: "Other Ethnic Groups - Any other ethnic group",
  },
  { label: "Not stated", value: "Not stated" },
  { label: "Not known", value: "Not known" },
];

export const firstLanguage = [
  { label: "Afar", value: "Afar" },
  { label: "Abkhazian", value: "Abkhazian" },
  { label: "Afrikaans", value: "Afrikaans" },
  { label: "Akan", value: "Akan" },
  { label: "Albanian", value: "Albanian" },
  { label: "Amharic", value: "Amharic" },
  { label: "Arabic", value: "Arabic" },
  { label: "Aragonese", value: "Aragonese" },
  { label: "Armenian", value: "Armenian" },
  { label: "Assamese", value: "Assamese" },
  { label: "Avaric", value: "Avaric" },
  { label: "Avestan", value: "Avestan" },
  { label: "Aymara", value: "Aymara" },
  { label: "Azerbaijani", value: "Azerbaijani" },
  { label: "Bashkir", value: "Bashkir" },
  { label: "Bambara", value: "Bambara" },
  { label: "Basque", value: "Basque" },
  { label: "Belarusian", value: "Belarusian" },
  { label: "Bengali", value: "Bengali" },
  { label: "Bihari languages", value: "Bihari languages" },
  { label: "Bislama", value: "Bislama" },
  { label: "Tibetan", value: "Tibetan" },
  { label: "Bosnian", value: "Bosnian" },
  { label: "Breton", value: "Breton" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Burmese", value: "Burmese" },
  { label: "Catalan; Valencian", value: "Catalan; Valencian" },
  { label: "Czech", value: "Czech" },
  { label: "Chamorro", value: "Chamorro" },
  { label: "Chechen", value: "Chechen" },
  { label: "Chinese", value: "Chinese" },
  {
    label:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    value:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
  },
  { label: "Chuvash", value: "Chuvash" },
  { label: "Cornish", value: "Cornish" },
  { label: "Corsican", value: "Corsican" },
  { label: "Cree", value: "Cree" },
  { label: "Welsh", value: "Welsh" },
  { label: "Danish", value: "Danish" },
  { label: "German", value: "German" },
  { label: "Divehi; Dhivehi; Maldivian", value: "Divehi; Dhivehi; Maldivian" },
  { label: "Dutch; Flemish", value: "Dutch; Flemish" },
  { label: "Dzongkha", value: "Dzongkha" },
  { label: "Greek, Modern (1453-)", value: "Greek, Modern (1453-)" },
  { label: "English", value: "English" },
  { label: "Esperanto", value: "Esperanto" },
  { label: "Estonian", value: "Estonian" },
  { label: "Ewe", value: "Ewe" },
  { label: "Faroese", value: "Faroese" },
  { label: "Persian", value: "Persian" },
  { label: "Fijian", value: "Fijian" },
  { label: "Finnish", value: "Finnish" },
  { label: "French", value: "French" },
  { label: "Western Frisian", value: "Western Frisian" },
  { label: "Fulah", value: "Fulah" },
  { label: "Georgian", value: "Georgian" },
  { label: "Gaelic; Scottish Gaelic", value: "Gaelic; Scottish Gaelic" },
  { label: "Irish", value: "Irish" },
  { label: "Galician", value: "Galician" },
  { label: "Manx", value: "Manx" },
  { label: "Guarani", value: "Guarani" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Haitian; Haitian Creole", value: "Haitian; Haitian Creole" },
  { label: "Hausa", value: "Hausa" },
  { label: "Hebrew", value: "Hebrew" },
  { label: "Herero", value: "Herero" },
  { label: "Hindi", value: "Hindi" },
  { label: "Hiri Motu", value: "Hiri Motu" },
  { label: "Croatian", value: "Croatian" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Igbo", value: "Igbo" },
  { label: "Icelandic", value: "Icelandic" },
  { label: "Ido", value: "Ido" },
  { label: "Sichuan Yi; Nuosu", value: "Sichuan Yi; Nuosu" },
  { label: "Inuktitut", value: "Inuktitut" },
  { label: "Interlingue; Occidental", value: "Interlingue; Occidental" },
  {
    label: "Interlingua (International Auxiliary Language Association)",
    value: "Interlingua (International Auxiliary Language Association)",
  },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Inupiaq", value: "Inupiaq" },
  { label: "Italian", value: "Italian" },
  { label: "Javanese", value: "Javanese" },
  { label: "Japanese", value: "Japanese" },
  { label: "Kalaallisut; Greenlandic", value: "Kalaallisut; Greenlandic" },
  { label: "Kannada", value: "Kannada" },
  { label: "Kashmiri", value: "Kashmiri" },
  { label: "Kanuri", value: "Kanuri" },
  { label: "Kazakh", value: "Kazakh" },
  { label: "Central Khmer", value: "Central Khmer" },
  { label: "Kikuyu; Gikuyu", value: "Kikuyu; Gikuyu" },
  { label: "Kinyarwanda", value: "Kinyarwanda" },
  { label: "Kirghiz; Kyrgyz", value: "Kirghiz; Kyrgyz" },
  { label: "Komi", value: "Komi" },
  { label: "Kongo", value: "Kongo" },
  { label: "Korean", value: "Korean" },
  { label: "Kuanyama; Kwanyama", value: "Kuanyama; Kwanyama" },
  { label: "Kurdish", value: "Kurdish" },
  { label: "Lao", value: "Lao" },
  { label: "Latin", value: "Latin" },
  { label: "Latvian", value: "Latvian" },
  {
    label: "Limburgan; Limburger; Limburgish",
    value: "Limburgan; Limburger; Limburgish",
  },
  { label: "Lingala", value: "Lingala" },
  { label: "Lithuanian", value: "Lithuanian" },
  {
    label: "Luxembourgish; Letzeburgesch",
    value: "Luxembourgish; Letzeburgesch",
  },
  { label: "Luba-Katanga", value: "Luba-Katanga" },
  { label: "Ganda", value: "Ganda" },
  { label: "Macedonian", value: "Macedonian" },
  { label: "Marshallese", value: "Marshallese" },
  { label: "Malayalam", value: "Malayalam" },
  { label: "Maori", value: "Maori" },
  { label: "Marathi", value: "Marathi" },
  { label: "Malay", value: "Malay" },
  { label: "Malagasy", value: "Malagasy" },
  { label: "Maltese", value: "Maltese" },
  { label: "Mongolian", value: "Mongolian" },
  { label: "Nauru", value: "Nauru" },
  { label: "Navajo; Navaho", value: "Navajo; Navaho" },
  {
    label: "Ndebele, South; South Ndebele",
    value: "Ndebele, South; South Ndebele",
  },
  {
    label: "Ndebele, North; North Ndebele",
    value: "Ndebele, North; North Ndebele",
  },
  { label: "Ndonga", value: "Ndonga" },
  { label: "Nepali", value: "Nepali" },
  {
    label: "Norwegian Nynorsk; Nynorsk, Norwegian",
    value: "Norwegian Nynorsk; Nynorsk, Norwegian",
  },
  {
    label: "Bokmål, Norwegian; Norwegian Bokmål",
    value: "Bokmål, Norwegian; Norwegian Bokmål",
  },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Chichewa; Chewa; Nyanja", value: "Chichewa; Chewa; Nyanja" },
  { label: "Occitan (post 1500)", value: "Occitan (post 1500)" },
  { label: "Ojibwa", value: "Ojibwa" },
  { label: "Oriya", value: "Oriya" },
  { label: "Oromo", value: "Oromo" },
  { label: "Ossetian; Ossetic", value: "Ossetian; Ossetic" },
  { label: "Panjabi; Punjabi", value: "Panjabi; Punjabi" },
  { label: "Pali", value: "Pali" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Pushto; Pashto", value: "Pushto; Pashto" },
  { label: "Quechua", value: "Quechua" },
  { label: "Romansh", value: "Romansh" },
  {
    label: "Romanian; Moldavian; Moldovan",
    value: "Romanian; Moldavian; Moldovan",
  },
  { label: "Rundi", value: "Rundi" },
  { label: "Russian", value: "Russian" },
  { label: "Sango", value: "Sango" },
  { label: "Sanskrit", value: "Sanskrit" },
  { label: "Sinhala; Sinhalese", value: "Sinhala; Sinhalese" },
  { label: "Slovak", value: "Slovak" },
  { label: "Slovenian", value: "Slovenian" },
  { label: "Northern Sami", value: "Northern Sami" },
  { label: "Samoan", value: "Samoan" },
  { label: "Shona", value: "Shona" },
  { label: "Sindhi", value: "Sindhi" },
  { label: "Somali", value: "Somali" },
  { label: "Sotho, Southern", value: "Sotho, Southern" },
  { label: "Spanish; Castilian", value: "Spanish; Castilian" },
  { label: "Sardinian", value: "Sardinian" },
  { label: "Serbian", value: "Serbian" },
  { label: "Swati", value: "Swati" },
  { label: "Sundanese", value: "Sundanese" },
  { label: "Swahili", value: "Swahili" },
  { label: "Swedish", value: "Swedish" },
  { label: "Tahitian", value: "Tahitian" },
  { label: "Tamil", value: "Tamil" },
  { label: "Tatar", value: "Tatar" },
  { label: "Telugu", value: "Telugu" },
  { label: "Tajik", value: "Tajik" },
  { label: "Thai", value: "Thai" },
  { label: "Tigrinya", value: "Tigrinya" },
  { label: "Tibetan", value: "Tibetan" },
  { label: "Turkmen", value: "Turkmen" },
  { label: "Tagalog", value: "Tagalog" },
  { label: "Tswana", value: "Tswana" },
  { label: "Tonga (Tonga Islands)", value: "Tonga (Tonga Islands)" },
  { label: "Turkish", value: "Turkish" },
  { label: "Tswana", value: "Tswana" },
  { label: "Twi", value: "Twi" },
  { label: "Uighur; Uyghur", value: "Uighur; Uyghur" },
  { label: "Ukrainian", value: "Ukrainian" },
  { label: "Urdu", value: "Urdu" },
  { label: "Uzbek", value: "Uzbek" },
  { label: "Venda", value: "Venda" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Volapük", value: "Volapük" },
  { label: "Walloon", value: "Walloon" },
  { label: "Wolof", value: "Wolof" },
  { label: "Xhosa", value: "Xhosa" },
  { label: "Yiddish", value: "Yiddish" },
  { label: "Yoruba", value: "Yoruba" },
  { label: "Zhuang; Chuang", value: "Zhuang; Chuang" },
  { label: "Zulu", value: "Zulu" },
  {
    label: "Braille - for people who are unable to see",
    value: "Braille - for people who are unable to see",
  },
  { label: "American Sign Language", value: "American Sign Language" },
  { label: "Australian Sign Language", value: "Australian Sign Language" },
  { label: "British Sign Language", value: "British Sign Language" },
  {
    label:
      "Makaton - devised for children and adults with a variety of communication and Learning Disabilities",
    value:
      "Makaton - devised for children and adults with a variety of communication and Learning Disabilities",
  },
];

export const religions = [
  { label: "Baha'i", value: "Baha'i" },
  { label: "Buddhist", value: "Buddhist" },
  { label: "Mahayana Buddhist", value: "Mahayana Buddhist" },
  {
    label: "New Kadampa Tradition Buddhist",
    value: "New Kadampa Tradition Buddhist",
  },
  { label: "Nichiren Buddhist", value: "Nichiren Buddhist" },
  { label: "Pure Land Buddhist", value: "Pure Land Buddhist" },
  { label: "Theravada Buddhist", value: "Theravada Buddhist" },
  { label: "Tibetan Buddhist", value: "Tibetan Buddhist" },
  { label: "Zen Buddhist", value: "Zen Buddhist" },
  { label: "Christian", value: "Christian" },
  { label: "Amish", value: "Amish" },
  { label: "Anabaptist", value: "Anabaptist" },
  { label: "Anglican", value: "Anglican" },
  { label: "Apostolic Pentecostalist", value: "Apostolic Pentecostalist" },
  { label: "Armenian Catholic", value: "Armenian Catholic" },
  { label: "Armenian Orthodox", value: "Armenian Orthodox" },
  { label: "Baptist", value: "Baptist" },
  { label: "Brethren", value: "Brethren" },
  { label: "Bulgarian Orthodox", value: "Bulgarian Orthodox" },
  { label: "Calvinist", value: "Calvinist" },
  {
    label: "Catholic: Not Roman Catholic",
    value: "Catholic: Not Roman Catholic",
  },
  { label: "Celtic Christian", value: "Celtic Christian" },
  { label: "Celtic Orthodox Christian", value: "Celtic Orthodox Christian" },
  {
    label: "Chinese Evangelical Christian",
    value: "Chinese Evangelical Christian",
  },
  { label: "Christadelphian", value: "Christadelphian" },
  { label: "Christian Existentialist", value: "Christian Existentialist" },
  { label: "Christian Humanist", value: "Christian Humanist" },
  { label: "Christian Scientists", value: "Christian Scientists" },
  { label: "Christian Spiritualist", value: "Christian Spiritualist" },
  { label: "Church in Wales", value: "Church in Wales" },
  { label: "Church of England", value: "Church of England" },
  { label: "Church of God of Prophecy", value: "Church of God of Prophecy" },
  { label: "Church of Ireland", value: "Church of Ireland" },
  { label: "Church of Scotland", value: "Church of Scotland" },
  { label: "Congregationalist", value: "Congregationalist" },
  { label: "Coptic Orthodox", value: "Coptic Orthodox" },
  { label: "Eastern Catholic", value: "Eastern Catholic" },
  { label: "Eastern Orthodox", value: "Eastern Orthodox" },
  { label: "Elim Pentecostalist", value: "Elim Pentecostalist" },
  { label: "Ethiopian Orthodox", value: "Ethiopian Orthodox" },
  { label: "Evangelical Christian", value: "Evangelical Christian" },
  { label: "Exclusive Brethren", value: "Exclusive Brethren" },
  { label: "Free Church", value: "Free Church" },
  { label: "Free Church of Scotland", value: "Free Church of Scotland" },
  {
    label: "Free Evangelical Presbyterian",
    value: "Free Evangelical Presbyterian",
  },
  { label: "Free Methodist", value: "Free Methodist" },
  { label: "Free Presbyterian", value: "Free Presbyterian" },
  { label: "French Protestant", value: "French Protestant" },
  { label: "Greek Catholic", value: "Greek Catholic" },
  { label: "Greek Orthodox", value: "Greek Orthodox" },
  { label: "Independent Methodist", value: "Independent Methodist" },
  { label: "Indian Orthodox", value: "Indian Orthodox" },
  { label: "Jehovah's Witness", value: "Jehovah's Witness" },
  { label: "Judaic Christian", value: "Judaic Christian" },
  { label: "Lutheran", value: "Lutheran" },
  { label: "Mennonite", value: "Mennonite" },
  { label: "Messianic Jew", value: "Messianic Jew" },
  { label: "Methodist", value: "Methodist" },
  { label: "Moravian", value: "Moravian" },
  { label: "Mormon", value: "Mormon" },
  { label: "Nazarene Church", value: "Nazarene Church" },
  {
    label: "New Testament Pentecostalist",
    value: "New Testament Pentecostalist",
  },
  { label: "Nonconformist", value: "Nonconformist" },
  { label: "Old Catholic", value: "Old Catholic" },
  { label: "Open Brethren", value: "Open Brethren" },
  { label: "Orthodox Christian", value: "Orthodox Christian" },
  { label: "Pentecostalist", value: "Pentecostalist" },
  { label: "Presbyterian", value: "Presbyterian" },
  { label: "Protestant", value: "Protestant" },
  { label: "Plymouth Brethren", value: "Plymouth Brethren" },
  { label: "Quaker", value: "Quaker" },
  { label: "Rastafari", value: "Rastafari" },
  { label: "Reformed Christian", value: "Reformed Christian" },
  { label: "Reformed Presbyterian", value: "Reformed Presbyterian" },
  { label: "Reformed Protestant", value: "Reformed Protestant" },
  { label: "Roman Catholic", value: "Roman Catholic" },
  { label: "Romanian Orthodox", value: "Romanian Orthodox" },
  { label: "Russian Orthodox", value: "Russian Orthodox" },
  { label: "Salvation Army Member", value: "Salvation Army Member" },
  { label: "Scottish Episcopalian", value: "Scottish Episcopalian" },
  { label: "Serbian Orthodox", value: "Serbian Orthodox" },
  { label: "Seventh Day Adventist", value: "Seventh Day Adventist" },
  { label: "Syrian Orthodox", value: "Syrian Orthodox" },
  { label: "Ukrainian Catholic", value: "Ukrainian Catholic" },
  { label: "Ukrainian Orthodox", value: "Ukrainian Orthodox" },
  { label: "Uniate Catholic", value: "Uniate Catholic" },
  { label: "Unitarian", value: "Unitarian" },
  { label: "United Reform", value: "United Reform" },
  { label: "Zwinglian", value: "Zwinglian" },
  { label: "Hindu", value: "Hindu" },
  { label: "Advaitin Hindu", value: "Advaitin Hindu" },
  { label: "Arya Samaj Hindu", value: "Arya Samaj Hindu" },
  { label: "Shakti Hindu", value: "Shakti Hindu" },
  { label: "Shiva Hindu", value: "Shiva Hindu" },
  { label: "Vaishnava Hindu", value: "Vaishnava Hindu" },
  { label: "Jain", value: "Jain" },
  { label: "Jewish", value: "Jewish" },
  { label: "Ashkenazi Jew", value: "Ashkenazi Jew" },
  { label: "Haredi Jew", value: "Haredi Jew" },
  { label: "Hasidic Jew", value: "Hasidic Jew" },
  { label: "Liberal Jew", value: "Liberal Jew" },
  { label: "Masorti Jew", value: "Masorti Jew" },
  { label: "Orthodox Jew", value: "Orthodox Jew" },
  { label: "Reform Jew", value: "Reform Jew" },
  { label: "Muslim", value: "Muslim" },
  { label: "Ahmadi", value: "Ahmadi" },
  { label: "Druze", value: "Druze" },
  { label: "Ismaili Muslim", value: "Ismaili Muslim" },
  { label: "Shi'ite Muslim", value: "Shi'ite Muslim" },
  { label: "Sunni Muslim", value: "Sunni Muslim" },
  { label: "Pagan", value: "Pagan" },
  { label: "Asatruar", value: "Asatruar" },
  { label: "Celtic Pagan", value: "Celtic Pagan" },
  { label: "Druid", value: "Druid" },
  { label: "Goddess", value: "Goddess" },
  { label: "Heathen", value: "Heathen" },
  { label: "Occultist", value: "Occultist" },
  { label: "Shaman", value: "Shaman" },
  { label: "Wiccan", value: "Wiccan" },
  { label: "Sikh", value: "Sikh" },
  { label: "Zoroastrian", value: "Zoroastrian" },
  { label: "Agnostic", value: "Agnostic" },
  { label: "Ancestral Worship", value: "Ancestral Worship" },
  { label: "Animist", value: "Animist" },
  { label: "Anthroposophist", value: "Anthroposophist" },
  { label: "Black Magic", value: "Black Magic" },
  { label: "Brahma Kumari", value: "Brahma Kumari" },
  { label: "British Israelite", value: "British Israelite" },
  { label: "Chondogyo", value: "Chondogyo" },
  { label: "Confucianist", value: "Confucianist" },
  { label: "Deist", value: "Deist" },
  { label: "Humanist", value: "Humanist" },
  { label: "Infinite Way", value: "Infinite Way" },
  { label: "Kabbalist", value: "Kabbalist" },
  { label: "Lightworker", value: "Lightworker" },
  { label: "New Age Practitioner", value: "New Age Practitioner" },
  { label: "Native American Religion", value: "Native American Religion" },
  { label: "Pantheist", value: "Pantheist" },
  { label: "Peyotist", value: "Peyotist" },
  { label: "Radha Soami", value: "Radha Soami" },
  {
    label: "Religion (Other Not Listed)",
    value: "Religion (Other Not Listed)",
  },
  { label: "Santeri", value: "Santeri" },
  { label: "Satanist", value: "Satanist" },
  { label: "Scientologist", value: "Scientologist" },
  { label: "Secularist", value: "Secularist" },
  { label: "Shumei", value: "Shumei" },
  { label: "Shinto", value: "Shinto" },
  { label: "Spiritualist", value: "Spiritualist" },
  { label: "Swedenborgian", value: "Swedenborgian" },
  { label: "Taoist", value: "Taoist" },
  { label: "Unitarian-Universalist", value: "Unitarian-Universalist" },
  { label: "Universalist", value: "Universalist" },
  { label: "Vodun", value: "Vodun" },
  { label: "Yoruba", value: "Yoruba" },
  { label: "Atheist", value: "Atheist" },
  { label: "Not Religious", value: "Not Religious" },
  {
    label: "Religion not given - PATIENT refused",
    value: "Religion not given - PATIENT refused",
  },
  { label: "PATIENT Religion Unknown", value: "PATIENT Religion Unknown" },
];

export const sexualOrientation = [
  { label: "Heterosexual", value: "Heterosexual" },
  { label: "Female Homosexual", value: "Female Homosexual" },
  { label: "Male Homosexual", value: "Male Homosexual" },
  { label: "Bisexual", value: "Bisexual" },
  {
    label: "Sexually attracted to neither gender",
    value: "Sexually attracted to neither gender",
  },
  { label: "Sexual orientation unknown", value: "Sexual orientation unknown" },
  {
    label: "Sexual orientation undecided",
    value: "Sexual orientation undecided",
  },
  {
    label: "Sexual orientation not given – patient refused",
    value: "Sexual orientation not given – patient refused",
  },
];
