import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Select from "react-select";
import { httpAdmin } from "../../Apis/commonApis";
import { countryList } from "../../constants/constants";
import cx from "./clients.module.css";
import { Controller, useForm } from "react-hook-form";
import { addCarePlanTemplate } from "../../redux-toolkit/reducer/carePlanReducer";
import { useDispatch } from "react-redux";
import { formatDate, getChangedKeysVals } from "../../utils/CommonFunctions";
import {
  codedValueDischargeMethod,
  dischargeDestination,
  dischargedSpeciality,
  dischargingDepartment,
} from "./dischargedConstant";
import { Autocomplete, TextField } from "@mui/material";
import {
  deathCause,
  deathIndicator,
  deathLocation,
  deathNoPrefered,
  locationIndicator,
} from "./deathConstant";
import {
  admissionMethods,
  cprDecision,
  CPRInformer,
  descriptions,
  informedConsentStatus,
  mentalHealthAct,
  sourceOfAdmission,
} from "./admissionConstant";
// import { watch } from "@ideal-postcodes/address-finder/dist/watch";

const chatUserImg = "/images/chatUser.svg";

const AdminSection = (props) => {
  const { clientAllData, Profileaccess, clientId, getClientbyId } = props;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [editClientProfileData, setEditClientProfileData] = useState([]);
  const [changeBtn, setchangeBtn] = useState(true);
  const [advanceDirective, setAdvanceDirective] = useState("");
  const [consentMentalCapacity, setconsentMentalCapacity] = useState("");
  const [consentPOA, setconsentPOA] = useState("");

  useEffect(() => {
    if (clientAllData) {
      setClientDataFunc(clientAllData);
    }
  }, [clientAllData]);

  const editClient = async (postData) => {
    let formdata = {
      ...postData,
      legalStatusOnDischarge: postData?.legalStatusOnDischarge?.value || "",
      dischargeDestination: postData?.dischargeDestination?.value || "",
      legelStatusOnAdmission: postData?.legelStatusOnAdmission?.value || "",
      dischargeSpeciality: postData?.dischargeSpeciality || "",
      CPR: postData?.CPR || "",
      CPRInformed: postData?.CPRInformed || "",
      deathCauseIdentificationMethod:
        postData?.deathCauseIdentificationMethod || "",
      deathLocationTypeCode: postData?.deathLocationTypeCode || "",
      deathNotAtPreferredLocationReason:
        postData?.deathNotAtPreferredLocationReason || "",
      personAtRiskOfUnexpectedDeathIndicator:
        postData?.personAtRiskOfUnexpectedDeathIndicator || "",
      preferredDeathLocationDiscussedIndicator:
        postData?.preferredDeathLocationDiscussedIndicator || "",
      admissionCountry: postData?.admissionCountry || "",
      advanceDecisioToRefuseTreatmentPreference:
        postData?.advanceDecisioToRefuseTreatmentPreference || "",
      admisssionMethod: postData?.admisssionMethod || "",
      mentalHealthCodedvalue: postData?.mentalHealthCodedvalue || "",
      sourceOfAdmission: postData?.sourceOfAdmission || "",
      specialityOfAdmission: postData?.specialityOfAdmission || "",
    };
    if (advanceDirective) formdata.advance_Directive = advanceDirective;
    if (consentMentalCapacity)
      formdata.consentMentalCapacity = consentMentalCapacity;
    if (consentPOA) formdata.consentPOA = consentPOA;
    let editData = getChangedKeysVals(editClientProfileData, formdata);
    setchangeBtn(true);
    if (Object.keys(editData)?.length < 1) {
      setchangeBtn(true);
      return;
    }
    const res = await httpAdmin(
      `edit_client/${clientId}`,
      "post",
      editData,
      null
    );
    if (res.status) {
      setClientDataFunc(res.data);
      getClientbyId();
      setchangeBtn(true);
    }
    swal(
      res.status ? "Success" : "Failed",
      res.message,
      res.status ? "success" : "error"
    );
  };
  const handleSelect = (value) => {
    if (!value) {
      return null;
    }
    return { label: value, value };
  };
  function handleEdit() {
    // setValue('dateRegardingDischarge', editClientProfileData?.dateRegardingDischarge ? editClientProfileData?.dateRegardingDischarge?.split('T')?.[0] : '')
    setValue(
      "legalStatusOnDischarge",
      handleSelect(editClientProfileData?.legalStatusOnDischarge)
    );
    setValue("dischargeMethod", editClientProfileData?.dischargeMethod);
    setValue("dischargeSpeciality", editClientProfileData?.dischargeSpeciality);
    setValue(
      "deathCauseIdentificationMethod",
      editClientProfileData?.deathCauseIdentificationMethod
    );
    setValue(
      "deathLocationTypeCode",
      editClientProfileData?.deathLocationTypeCode
    );
    setValue(
      "deathNotAtPreferredLocationReason",
      editClientProfileData?.deathNotAtPreferredLocationReason
    );
    setValue(
      "personAtRiskOfUnexpectedDeathIndicator",
      editClientProfileData?.personAtRiskOfUnexpectedDeathIndicator
    );
    setValue(
      "preferredDeathLocationDiscussedIndicator",
      editClientProfileData?.preferredDeathLocationDiscussedIndicator
    );
    setValue("admissionCountry", editClientProfileData?.admissionCountry);
    setValue("admisssionMethod", editClientProfileData?.admisssionMethod);
    setValue(
      "mentalHealthCodedvalue",
      editClientProfileData?.mentalHealthCodedvalue
    );
    setValue("sourceOfAdmission", editClientProfileData?.sourceOfAdmission);
    setValue("CPR", editClientProfileData?.CPR);
    setValue("CPRInformed", editClientProfileData?.CPRInformed);
    setValue(
      "advanceDecisioToRefuseTreatmentPreference",
      editClientProfileData?.advanceDecisioToRefuseTreatmentPreference
    );
    setValue(
      "specialityOfAdmission",
      editClientProfileData?.specialityOfAdmission
    );
    setValue(
      "dischargeDestination",
      handleSelect(editClientProfileData?.dischargeDestination)
    );
    setValue(
      "legelStatusOnAdmission",
      handleSelect(editClientProfileData?.legelStatusOnAdmission)
    );
  }

  function setClientDataFunc(ClientData) {
    setEditClientProfileData(ClientData);
    // Displaying Single Client data
    dispatch(addCarePlanTemplate({ firstName: ClientData?.first_Name }));
    setconsentMentalCapacity(ClientData?.consentMentalCapacity);
    setconsentPOA(ClientData?.consentPOA);
    setAdvanceDirective(ClientData?.advance_Directive);
  }

  const ListboxProps = {
    style: {
      maxHeight: 80,
      backgroundColor: "#fafafa",
      fontSize: "14px",
      border: '1px solid #ced4da'
    },
  };

  return (
    <>
      <div className="row" style={{ height: "78vh", overflowY: "auto" }}>
        <div className="col-md-6 pe-3">
          <div className="card_inbox">
            <div className="view_details position-relative">
              <img
                src={editClientProfileData?.image ?? chatUserImg}
                className="profile_img"
                alt="user"
              />
              <div className="float-end btns_head mb-3">
                {changeBtn ? (
                  <button
                    className="btn btn-theme btn-sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setchangeBtn(false);
                      handleEdit();
                    }}
                    disabled={Profileaccess !== "full"}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="btn btn-theme btn-sm"
                    type="submit"
                    onClick={handleSubmit(editClient)}
                  >
                    Save
                  </button>
                )}
              </div>

              {/* consent  */}
              <h5 className="tb_title d-block">Consent</h5>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>Mental Capacity to Consent </td>
                    {changeBtn ? (
                      <td style={{minWidth: '20vw'}}>
                        {editClientProfileData?.consentMentalCapacity || "--"}
                      </td>
                    ) : (
                      <td style={{minWidth: '20vw'}}>
                        {/* <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.consentMentalCapacity
                          }
                          onChange={(e) =>
                            setconsentMentalCapacity(e.target.value)
                          }
                          className="form-control form-control-sm"
                        /> */}
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.consentMentalCapacity,
                            label: editClientProfileData?.consentMentalCapacity,
                          }}
                          options={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                          ]}
                          onChange={(event) => {
                            setconsentMentalCapacity(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Power of Attorney in Place</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.consentPOA || "--"}</td>
                    ) : (
                      <td>
                        {/* <input
                          type="text"
                          defaultValue={editClientProfileData?.consentPOA}
                          onChange={(e) => setconsentPOA(e.target.value)}
                          className="form-control form-control-sm"
                        /> */}
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.consentPOA,
                            label: editClientProfileData?.consentPOA,
                          }}
                          options={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                          ]}
                          onChange={(event) => {
                            setconsentPOA(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Authorized Person</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.authrisedPerson?.map(
                          ({ name }) => {
                            return (
                              <>
                                <span key={name._id}>{name}</span>
                                <br />
                              </>
                            );
                          }
                        )}
                      </td>
                    ) : (
                      <td>
                        {editClientProfileData?.authrisedPerson?.map(
                          ({ name }) => {
                            return (
                              <>
                                <span key={name._id}>{name}</span>
                                <br />
                              </>
                            );
                          }
                        )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Advanced Directive</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.advance_Directive || "--"}
                      </td>
                    ) : (
                      <td>
                        {/* <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.advance_Directive
                          }
                          onChange={(e) => setAdvanceDirective(e.target.value)}
                          className="form-control form-control-sm"
                        /> */}
                        <Select
                          placeholder="Select"
                          defaultValue={{
                            value: editClientProfileData?.advance_Directive,
                            label: editClientProfileData?.advance_Directive,
                          }}
                          options={[
                            { value: "CPR", label: "CPR" },
                            { value: "DNACPR", label: "DNACPR" },
                          ]}
                          onChange={(event) => {
                            setAdvanceDirective(event.value);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr className="rowHead">
                    <td>Advance decision to refuse treatment</td>
                  </tr>
                  <tr>
                    <td>Advance decision to refuse treatment preference.</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.advanceDecisioToRefuseTreatmentPreference ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="advanceDecisioToRefuseTreatmentPreference"
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="advanceDecisioToRefuseTreatmentPreference"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Advance decision to refuse treatment"
                                  options={descriptions.map((item, i) => ({
                                    id: i,
                                    label: item, // Adjust based on your object structure
                                    value: item, // Or use another unique property
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />

                        <p className="error_style">
                          {errors.deathCauseIdentificationMethod?.message ||
                            errors.deathCauseIdentificationMethod?.label
                              .message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td> Location of document (ADRT)</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.locationOfDocumentForConsent ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.locationOfDocumentForConsent
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          title="Link to digital representation of the ADRT if applicable."
                          placeholder="Link to digital representation of the ADRT if applicable."
                          {...register("locationOfDocumentForConsent")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td> Advance statement</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.advanceStatementFreeText ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.advanceStatementFreeText
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Advance statement"
                          {...register("advanceStatementFreeText")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td> Advance statement Location</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.advanceStatementLocationOfDocument ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.advanceStatementLocationOfDocument
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          title="The location of where the advanced statement is held."
                          placeholder="The location of where the advanced statement is held."
                          {...register("advanceStatementLocationOfDocument")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr className="rowHead">
                    <td>Cardio-pulmonary resuscitation</td>
                  </tr>
                  <tr>
                    <td>Care Actor (CPR)</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.careActor || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.careActor}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          title="Care actor who will perform the activity or performed the action or made the observation."
                          placeholder="Care actor who will perform the activity or performed the action or made the observation."
                          {...register("careActor")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>CPR decision</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.CPR || "--"}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="CPR"
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="CPR"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="CPR decision"
                                  options={cprDecision.map((item, i) => ({
                                    id: i,
                                    label: item, // Adjust based on your object structure
                                    value: item, // Or use another unique property
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>CPR Date</td>
                    {changeBtn ? (
                      <td>{formatDate(editClientProfileData?.CPRDate) || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={editClientProfileData?.CPRDate}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          {...register("CPRDate")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>CPR Informed</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.CPRInformed || "--"}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="CPRInformed"
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="CPRInformed"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="CPR Informed"
                                  options={CPRInformer.map((item, i) => ({
                                    id: i,
                                    label: item, // Adjust based on your object structure
                                    value: item, // Or use another unique property
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Location</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.CPRLocation || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.CPRLocation}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="The location of the event. "
                          {...register("CPRLocation")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Location of document</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.CPRLocationOfDocument || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.CPRLocationOfDocument
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          title="The location of the CPR decision document"
                          placeholder="The location of the CPR decision document"
                          {...register("CPRLocationOfDocument")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Review of CPR decision</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.CPRReviewOfCPR || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.CPRReviewOfCPR}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          title="Review or scheduled review of CPR decision."
                          placeholder="Review or scheduled review of CPR decision."
                          {...register("CPRReviewOfCPR")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Subject of care (CPR)</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.CPRSubjectOfCare || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.CPRSubjectOfCare}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          title="Subject of care who is the focus of the record"
                          placeholder="Subject of care who is the focus of the record"
                          {...register("CPRSubjectOfCare")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td> Time</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.CPRTime || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="time"
                          defaultValue={editClientProfileData?.CPRTime}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          {...register("CPRTime")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr className="rowHead">
                    <td>
                      Deprivation of Liberty Safeguards (DoLS) or equivalent
                    </td>
                  </tr>
                  <tr>
                    <td>Application date</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.DOLSApplicationDate) || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.DOLSApplicationDate
                          }
                          className="form-control form-control-sm"
                          {...register("DOLSApplicationDate")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Application status</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.DOLSApplicationStatus || "--"}
                      </td>
                    ) : (
                      <td>
                        <select
                          defaultValue={
                            editClientProfileData?.DOLSApplicationStatus
                          }
                          className="form-control form-control-sm"
                          {...register("DOLSApplicationStatus")}
                        >
                          <option value="">--Select--</option>
                          {["Requested", "Authorised", "Rejected"].map(
                            (item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            )
                          )}
                        </select>
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Deprivation of liberty safeguards or equivalent</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.DOLSEquivalent || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.DOLSEquivalent}
                          className="form-control form-control-sm"
                          placeholder="Record of DoLS, including the reason for this."
                          {...register("DOLSEquivalent")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Location</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.DOLSLocation || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.DOLSLocation}
                          className="form-control form-control-sm"
                          placeholder="The location where the DoLS decision was made."
                          {...register("DOLSLocation")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Location of document</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.DOLSLocationOfDocument || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.DOLSLocationOfDocument
                          }
                          className="form-control form-control-sm"
                          placeholder="Link to digital representation of DoLS, if applicable."
                          {...register("DOLSLocationOfDocument")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Planned or actual end date of authorisation</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.DOLSActualOrPlanned) || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.DOLSActualOrPlanned
                          }
                          className="form-control form-control-sm"
                          {...register("DOLSActualOrPlanned")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Reason for rejection</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.DOLSReasonForRejection || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.DOLSReasonForRejection
                          }
                          className="form-control form-control-sm"
                          placeholder="Reason for rejection of DoLS, if applicable."
                          {...register("DOLSReasonForRejection")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>DoLS Review</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.DOLSReview || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.DOLSReview}
                          className="form-control form-control-sm"
                          placeholder="Review of the DoLS"
                          {...register("DOLSReview")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Start date of authorisation</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.DOLSStartDateOFAuthrisation) ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.DOLSStartDateOFAuthrisation
                          }
                          className="form-control form-control-sm"
                          {...register("DOLSStartDateOFAuthrisation")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr className="rowHead">
                    <td>Informed consent</td>
                  </tr>
                  <tr>
                    <td>Care worker</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentCareWorker ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentCareWorker
                          }
                          className="form-control form-control-sm"
                          placeholder="The care worker who performed the activity"
                          {...register("informedConsentCareWorker")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Caveat</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentCaveat || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentCaveat
                          }
                          className="form-control form-control-sm"
                          placeholder="Details of any qualifications or exemptions to the informed consent."
                          {...register("informedConsentCaveat")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Consent description</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentDescription ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentDescription
                          }
                          className="form-control form-control-sm"
                          placeholder="Narrative description of the informed consent"
                          {...register("informedConsentDescription")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Informed Consent End date</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.informedConsentEndDate) || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.informedConsentEndDate
                          }
                          className="form-control form-control-sm"
                          {...register("informedConsentEndDate")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Focus</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentFocus || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentFocus
                          }
                          className="form-control form-control-sm"
                          placeholder="Identification of the assessment, care plan, instruction, activity or action against which the consent status and details are recorded."
                          {...register("informedConsentFocus")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Form of consent</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentFormOfConsent ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentFormOfConsent
                          }
                          className="form-control form-control-sm"
                          placeholder="Form of the consent sought or provided."
                          {...register("informedConsentFormOfConsent")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Informed Consent Activity Location</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentLocation || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentLocation
                          }
                          className="form-control form-control-sm"
                          placeholder="A physical LOCATION of the informed consent activity."
                          {...register("informedConsentLocation")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Location of document</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentLocationOfDocument ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentLocationOfDocument
                          }
                          className="form-control form-control-sm"
                          placeholder="Link to digital representation of consent"
                          {...register("informedConsentLocationOfDocument")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Proxy representation</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentProxyRepresentation ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentProxyRepresentation
                          }
                          className="form-control form-control-sm"
                          placeholder="Complete if the subject of care's wishes are expressed by a proxy"
                          {...register("informedConsentProxyRepresentation")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Reason consent not given</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentReasonConsentNotGiven ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentReasonConsentNotGiven
                          }
                          className="form-control form-control-sm"
                          placeholder="Reason for consent status i.e. reason consent withdrawn or refused"
                          {...register("informedConsentReasonConsentNotGiven")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Informed consent Start date</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.informedConsentStartDate) ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.informedConsentStartDate
                          }
                          className="form-control form-control-sm"
                          {...register("informedConsentStartDate")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>The informed consent status</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentStatus || "--"}
                      </td>
                    ) : (
                      <td>
                        <select
                          defaultValue={
                            editClientProfileData?.informedConsentStatus
                          }
                          className="form-control form-control-sm"
                          {...register("informedConsentStatus")}
                        >
                          <option value="">--Select--</option>
                          {informedConsentStatus.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Subject of care</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.informedConsentSubjectOfCare ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.informedConsentSubjectOfCare
                          }
                          className="form-control form-control-sm"
                          placeholder="Subject of care the information is about"
                          {...register("informedConsentSubjectOfCare")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr className="rowHead">
                    <td>Lasting power of attorney</td>
                  </tr>
                  <tr>
                    <td>Attorney</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.LPA_attroney || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.LPA_attroney}
                          className="form-control form-control-sm"
                          placeholder="Name and contact details of the Lasting Power of Attorney"
                          {...register("LPA_attroney")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Location of document (LPA)</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.LPA_LocationOfDocument || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.LPA_LocationOfDocument
                          }
                          className="form-control form-control-sm"
                          placeholder="Location of document (LPA)"
                          {...register("LPA_LocationOfDocument")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Subject of care proxy (LPA)</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.LPA_SubjectOfCareProxy || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.LPA_SubjectOfCareProxy
                          }
                          className="form-control form-control-sm"
                          placeholder="Subject of care proxy (LPA)"
                          {...register("LPA_SubjectOfCareProxy")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr className="rowHead">
                    <td>Mental health act or equivalent</td>
                  </tr>
                  <tr>
                    <td>Location (MHAE)</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.mentalHealthLocation || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.mentalHealthLocation
                          }
                          className="form-control form-control-sm"
                          placeholder="The location where the decision to detain the person was made under the mental health act."
                          {...register("mentalHealthLocation")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Location of document</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.mentalHealthLocationOfDocument ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.mentalHealthLocationOfDocument
                          }
                          className="form-control form-control-sm"
                          placeholder="Link to digital representation of the decision to detain the person under the mental health act if applicable."
                          {...register("mentalHealthLocationOfDocument")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Section start date</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.mentalHealthStartDate) || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.mentalHealthStartDate
                          }
                          className="form-control form-control-sm"
                          {...register("mentalHealthStartDate")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Section end date</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.mentalHealthEndDate) || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.mentalHealthEndDate
                          }
                          className="form-control form-control-sm"
                          {...register("mentalHealthEndDate")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Mental Health Act</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.mentalHealthCodedvalue || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="mentalHealthCodedvalue"
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="mentalHealthCodedvalue"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Mental Health Act"
                                  options={mentalHealthAct.map((item, i) => ({
                                    id: i,
                                    label: item.label, // Adjust based on your object structure
                                    value: item.value, // Or use another unique property
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />

                        <p className="error_style">
                          {errors.deathCauseIdentificationMethod?.message ||
                            errors.deathCauseIdentificationMethod?.label
                              .message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Supporting information</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.mentalHealthSupportingInformation ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.mentalHealthSupportingInformation
                          }
                          className="form-control form-control-sm"
                          placeholder="Supporting information"
                          {...register("mentalHealthSupportingInformation")}
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-6 ps-3">
          <div className="mb-4 card_inbox">
            <div className="view_details">
              {/* admission  */}

              <h5 className="tb_title d-block">Admission details</h5>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td >UPRN</td>
                    {changeBtn ? (
                      <td style={{minWidth: '20vw'}}>{editClientProfileData?.UPRN || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.UPRN}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="UPRN"
                          {...register("UPRN")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Address entry 1</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionAddressEntry1 || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionAddressEntry1
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Address entry 1"
                          {...register("admissionAddressEntry1")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Address entry 2</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionAddressEntry2 || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionAddressEntry2
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Address entry 2"
                          {...register("admissionAddressEntry2")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Address entry 3</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionAddressEntry3 || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionAddressEntry3
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Address entry 3"
                          {...register("admissionAddressEntry3")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Address entry 4</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionAddressEntry4 || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionAddressEntry4
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Address entry 4"
                          {...register("admissionAddressEntry4")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Address entry 5</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionAddressEntry5 || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionAddressEntry5
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Address entry 5"
                          {...register("admissionAddressEntry5")}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Country</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.admissionCountry || "--"}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="admissionCountry"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="admissionCountry"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  options={countryList?.map((item, i) => ({
                                    label: item?.name,
                                    value: item?.name,
                                    id: i,
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.dischargeMethod?.message ||
                            errors.dischargeMethod?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Postcode</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionPostcode || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionPostcode
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Postcode"
                          {...register("admissionPostcode")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Admitted to</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionAdmittedTo || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionAdmittedTo
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Admitted to"
                          {...register("admissionAdmittedTo")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Date</td>
                    {changeBtn ? (
                      <td>
                        {formatDate(editClientProfileData?.dateAdmissionDetails)
                          ?? "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.dateAdmissionDetails
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          {...register("dateAdmissionDetails")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Individual accompanying person</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.admissionIndividualAccompanyingPerson ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.admissionIndividualAccompanyingPerson
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          placeholder="Individual accompanying person"
                          className="form-control form-control-sm"
                          {...register("admissionIndividualAccompanyingPerson")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Legal status on admission</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.legelStatusOnAdmission || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="legelStatusOnAdmission"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={dischargingDepartment}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.legelStatusOnAdmission?.message ||
                            errors.legelStatusOnAdmission?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Responsible care worker</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.responsibleCareWorkerForAdmission ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.responsibleCareWorkerForAdmission
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          placeholder="Responsible care worker"
                          className="form-control form-control-sm"
                          {...register("responsibleCareWorkerForAdmission")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Admission method</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.admisssionMethod || "--"}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="admisssionMethod"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="admisssionMethod"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  options={admissionMethods?.map((item, i) => ({
                                    ...item,
                                    id: i,
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.dischargeMethod?.message ||
                            errors.dischargeMethod?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Reason for admission</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.reasonForAdmission || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.reasonForAdmission
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          placeholder="Reason for admission"
                          className="form-control form-control-sm"
                          {...register("reasonForAdmission")}
                        />
                      </td>
                    )}
                  </tr>
                  {/* <tr>
                    <td>Admission method</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.admisssionMethod || '--'}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="admisssionMethod"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}>
                                <Autocomplete
                                  disablePortal
                                  id="admisssionMethod"
                                  onInputChange={(event, value) => {
                                    if (event?.target) {
                                      field.onChange(value);

                                    } else {
                                      field.onChange(admissionMethods?.[+value]?.value);
                                    }
                                  }}
                                  value={field.value} // Sets the value from the form state
                                  ListboxProps={{ style: { maxHeight: 80, backgroundColor: '#d9e5f2' } }}
                                  options={admissionMethods}
                                  renderInput={(params) => <TextField {...params} label="" />}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.admisssionMethod?.message ||
                            errors.admisssionMethod?.label.message}
                        </p>
                      </td>
                    )}
                  </tr> */}
                  <tr>
                    <td>Source of admission</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.sourceOfAdmission || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="sourceOfAdmission"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="sourceOfAdmission"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  options={sourceOfAdmission?.map(
                                    (item, i) => ({ ...item, id: i })
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.sourceOfAdmission?.message ||
                            errors.sourceOfAdmission?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Specialty</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.specialityOfAdmission || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="specialityOfAdmission"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="specialityOfAdmission"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  options={dischargedSpeciality.map(
                                    (item, index) => ({
                                      id: index,
                                      label: item.label, // Adjust as per your object structure
                                      value: item.value,
                                    })
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.specialityOfAdmission?.message ||
                            errors.specialityOfAdmission?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>

              <h5 className="tb_title d-block">Discharge Details</h5>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>Date</td>
                    {changeBtn ? (
                      <td style={{minWidth: '20vw'}}>
                        {editClientProfileData?.dateRegardingDischarge
                          ? formatDate(editClientProfileData?.dateRegardingDischarge)
                          : "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={
                            editClientProfileData?.dateRegardingDischarge
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          {...register("dateRegardingDischarge")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Discharged from</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.dischargedFrom || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.dischargedFrom}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Discharged from"
                          {...register("dischargedFrom")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Discharging care worker</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.dischargingCareWorker || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.dischargingCareWorker
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Discharging care worker"
                          {...register("dischargingCareWorker")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Discharging department</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.dischargingDepartment || "--"}
                      </td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={
                            editClientProfileData?.dischargingDepartment
                          }
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Discharging department"
                          {...register("dischargingDepartment")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Legal status on discharge</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.legalStatusOnDischarge || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="legalStatusOnDischarge"
                          render={({ field }) => (
                            <Select
                              {...field}
                              isClearable
                              isSearchable={false}
                              className="react-dropdown"
                              classNamePrefix="dropdown"
                              options={dischargingDepartment}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              value={field.value} // Sets the value from the form state
                            />
                          )}
                        />

                        {/* <p className="error_style">
                          {errors.dischargingDepartment?.message ||
                            errors.dischargingDepartment?.label.message}
                        </p> */}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Discharge address</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.dischargeAddress || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="text"
                          defaultValue={editClientProfileData?.dischargeAddress}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          placeholder="Discharge address"
                          {...register("dischargeAddress")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Discharge destination</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.dischargeDestination || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="dischargeDestination"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                value={field.value} // Sets the value from the form state
                                isSearchable={false}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={dischargeDestination}
                                // onChange={handleStatusChange}
                              />
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.dischargeDestination?.message ||
                            errors.dischargeDestination?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Discharge Method</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.dischargeMethod || "--"}</td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="dischargeMethod"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="dischargeMethod"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Discharge Method"
                                  options={codedValueDischargeMethod.map(
                                    (item, i) => ({
                                      id: i,
                                      label: item.label, // Ensure proper label mapping
                                      value: item.value, // Or use another unique property
                                    })
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.dischargeMethod?.message ||
                            errors.dischargeMethod?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Discharging specialty</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.dischargeSpeciality || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="dischargeSpeciality"
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="dischargeSpeciality"
                                  onInputChange={(event, value) => {
                                    field.onChange(
                                      event?.target?.value || value
                                    );
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Discharging specialty"
                                  options={dischargedSpeciality?.map(
                                    (item, i) => ({ ...item, id: i })
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />

                        <p className="error_style">
                          {errors.dischargeSpeciality?.message ||
                            errors.dischargeSpeciality?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>

              {/* death */}

              <h5 className="tb_title d-block">Death Details</h5>
              <table className="table table-striped table_half">
                <tbody>
                  <tr>
                    <td>Death cause identification method</td>
                    {changeBtn ? (
                      <td style={{minWidth: '20vw'}}>
                        {editClientProfileData?.deathCauseIdentificationMethod ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="deathCauseIdentificationMethod"
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="deathCauseIdentificationMethod"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Death cause identification method"
                                  options={deathCause.map((item, i) => ({
                                    id: i,
                                    label: item.label, // Adjust based on your object structure
                                    value: item.value, // Or use another unique property
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />

                        <p className="error_style">
                          {errors.deathCauseIdentificationMethod?.message ||
                            errors.deathCauseIdentificationMethod?.label
                              .message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Death location type code</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.deathLocationTypeCode || "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="deathLocationTypeCode"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="deathLocationTypeCode"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Death location type code"
                                  options={deathLocation?.map((item, i) => ({
                                    ...item,
                                    id: i,
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.deathLocationTypeCode?.message ||
                            errors.deathLocationTypeCode?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Death not at preferred location reason</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.deathNotAtPreferredLocationReason ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="deathNotAtPreferredLocationReason"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="deathNotAtPreferredLocationReason"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Death not at preferred location reason"
                                  options={deathNoPrefered?.map((item, i) => ({
                                    ...item,
                                    id: i,
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.deathNotAtPreferredLocationReason?.message ||
                            errors.deathNotAtPreferredLocationReason?.label
                              .message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Person at risk of unexpected death indicator</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.personAtRiskOfUnexpectedDeathIndicator ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="personAtRiskOfUnexpectedDeathIndicator"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="personAtRiskOfUnexpectedDeathIndicator"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Person at risk of unexpected death indicator"
                                  options={deathIndicator?.map((item, i) => ({
                                    ...item,
                                    id: i,
                                  }))}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.personAtRiskOfUnexpectedDeathIndicator
                            ?.message ||
                            errors.personAtRiskOfUnexpectedDeathIndicator?.label
                              .message}
                        </p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Person death date</td>
                    {changeBtn ? (
                      <td>{formatDate(editClientProfileData?.personDeathDate) || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="date"
                          defaultValue={editClientProfileData?.personDeathDate}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          {...register("personDeathDate")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Person death Time</td>
                    {changeBtn ? (
                      <td>{editClientProfileData?.personDeathTime || "--"}</td>
                    ) : (
                      <td>
                        <input
                          type="time"
                          defaultValue={editClientProfileData?.personDeathTime}
                          // onChange={(e) => setFirstName(e.target.value)}
                          className="form-control form-control-sm"
                          {...register("personDeathTime")}
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Preferred death location discussed indicator</td>
                    {changeBtn ? (
                      <td>
                        {editClientProfileData?.preferredDeathLocationDiscussedIndicator ||
                          "--"}
                      </td>
                    ) : (
                      <td>
                        <Controller
                          control={control}
                          name="preferredDeathLocationDiscussedIndicator"
                          // rules={{
                          //   required: "This field is required",
                          // }}
                          render={({ field }) => {
                            return (
                              <div
                                className={`col-md-4 position-relative w-100 ${cx.reactAutocomplete}`}
                              >
                                <Autocomplete
                                  disablePortal
                                  id="preferredDeathLocationDiscussedIndicator"
                                  onInputChange={(event, value) => {
                                    field.onChange(value);
                                  }}
                                  inputValue={field?.value || ""}
                                  value={field.value || ""} // Sets the value from the form state
                                  ListboxProps={ListboxProps}
                                  placeholder="Preferred death location discussed indicator"
                                  options={locationIndicator?.map(
                                    (item, i) => ({ ...item, id: i })
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} label="" />
                                  )}
                                />
                              </div>
                            );
                          }}
                        />
                        <p className="error_style">
                          {errors.preferredDeathLocationDiscussedIndicator
                            ?.message ||
                            errors.preferredDeathLocationDiscussedIndicator
                              ?.label.message}
                        </p>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSection;
