import React, { useState, useEffect } from "react";
import DonutSales from "./DonutSales";
import GraphsSalesFunnel from "./GraphsSalesFunnel";
import { httpAdmin } from "../../Apis/commonApis";
import DatePicker from "react-datepicker";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import {
  formatDate,
  getMondayDate,
  getWeekStartAndEndDates,
  limitToTwoDecimal,
} from "../../utils/CommonFunctions";
import { twoDecimalPlaces } from "../../utils/utilsHandlerFunctions";
import moment from "moment";

let helperArrDate = [];
let customDates = [];
const dateOptions = { day: "numeric", month: "long", year: "numeric" };

function SalesOverview(props) {
  let today = new Date();
  const { filterType, dateRange } = props;

  const [startDate, endDate] = dateRange;

  // const [customDates, setCustomDates] = useState([]);
  const [clientsPlanType, setClientsPlanType] = useState({
    total: 1,
    council: 0,
    selfFunded: 0,
  });
  const [leadsPlanType, setLeadsPlanType] = useState({
    total: 1,
    council: 0,
    selfFunded: 0,
  });
  const [overviewData, setOverviewData] = useState(null);
  const [leadsData, setLeadsData] = useState({});
  const [leadsSeries, setLeadsSeries] = useState([]);
  const [graphVals, setGraphVals] = useState({
    revenue: [0, 0, 0, 0, 0, 0, 0],
    grossWageCosts: [0, 0, 0, 0, 0, 0, 0],
    avgFixFeeRates: [0, 0, 0, 0, 0, 0, 0],
    avgPayAsGoFeeRates: [0, 0, 0, 0, 0, 0, 0],
    staffChurnRate: [0, 0, 0, 0, 0, 0, 0],
    occupancy: [0, 0, 0, 0, 0, 0, 0],
    occupancyRates: [0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    if (overviewData) {
      graphsFilter();
    }
  }, [overviewData]);

  useEffect(() => {
    if (filterType === "custom" && endDate) {
      getSalesOverviewData(formatDate(startDate), formatDate(endDate));
    } else if (filterType === "week") {
      const weekDates = getWeekStartAndEndDates(today);
      getSalesOverviewData(weekDates.start, weekDates.end);
    } else if (filterType === "today") {
      // getSalesOverviewData(today.toLocaleDateString(undefined, dateOptions), today);
    }
  }, [filterType, dateRange]);

  function graphsFilter() {
    let user = JSON.parse(localStorage.getItem("userData"));
    let totalBeds = user.subscriptionPlanData[0]?.totalBeds;
    const dailyBasedHz = [0, 4, 8, 12, 16, 20, 24];
    const weekBasedHz = getWeekDates(new Date());

    let revenueArr = [];
    let grossWageArr = [];
    let avgFixFeeArr = [];
    let avgPasAsGoArr = [];
    let occupancyArr = [];
    let occupancyRateArr = [];
    let staffChurnRateArr = [];

    if (filterType === "today") {
      for (let i = 0; i < dailyBasedHz.length; i++) {
        let revenueVals = overviewData?.clientData?.filter(
          dailyCount(dailyBasedHz[i], "createdAt")
        );
        let occupancyLength = overviewData?.clientData?.filter(
          dailyCount(dailyBasedHz[i], "createdAt")
        )?.length;
        let inActiveStaffLength = overviewData?.careteamData?.filter(
          dailyCountChurnRate(dailyBasedHz[i], "createdAt", "inactive")
        )?.length;
        let activeStaffLength = overviewData?.careteamData?.filter(
          dailyCountChurnRate(dailyBasedHz[i], "createdAt", "active")
        )?.length;

        const totalAmount = revenueVals.reduce(
          (acc, obj) => acc + (obj.invoiceRate ? obj.invoiceRate : 0),
          0
        );
        revenueArr.push(totalAmount);
        let churnRateOfStaff = 0;
        if (activeStaffLength) {
          churnRateOfStaff =
            (inActiveStaffLength / (inActiveStaffLength + activeStaffLength)) *
            100;
        }
        occupancyArr.push(occupancyLength);
        occupancyRateArr.push(((occupancyLength / totalBeds) * 100).toFixed(2));
        staffChurnRateArr.push(churnRateOfStaff.toFixed(2));
      }
    } else if (filterType === "week") {
      const arrayOfObjects = Object.entries(overviewData?.revenue)?.map(
        ([key, value]) => ({ date: key, ...value })
      );
      for (let i = 0; i < weekBasedHz.length; i++) {
        const revenueArrOfObjects = Object.entries(overviewData?.revenue)?.map(
          ([key, value]) => ({ date: key, value })
        );
        let filteredRevenueVal = revenueArrOfObjects?.filter(
          filterByDate(weekBasedHz[i])
        );
        let revenueVal =
          filteredRevenueVal.length > 0
            ? limitToTwoDecimal(filteredRevenueVal[0]?.value)
            : 0;
        revenueArr.push(revenueVal);

        let grossWageVal = convertWeekPatternInArr(
          overviewData.grossWages,
          i,
          weekBasedHz
        );
        grossWageArr.push(grossWageVal);
        const filterDataByDate = overviewData.rangeData?.find(
          (item) => formatDate(item.date) === formatDate(weekBasedHz[i])
        );
        // const avgFeeRate = (filterDataByDate?.payAsYouGoAverageFeeRates || 0) + (filterDataByDate?.fixedPeriodAverageFeeRates || 0)
        avgFixFeeArr.push(filterDataByDate?.fixedPeriodAverageFeeRates ?? 0);
        avgPasAsGoArr.push(filterDataByDate?.payAsYouGoAverageFeeRates ?? 0);
        staffChurnRateArr.push(filterDataByDate?.churnRate ?? 0);
        occupancyArr.push(filterDataByDate?.occupancy ?? 0);
        occupancyRateArr.push(filterDataByDate?.occupancyRates ?? 0);
      }
    } else if (filterType === "custom") {
      cutomizeHz();
      for (let i = 0; i < helperArrDate.length; i++) {
        const revenueArrOfObjects = Object.entries(overviewData?.revenue)?.map(
          ([key, value]) => ({ date: key, value })
        );
        let filteredRevenueVal = revenueArrOfObjects?.filter(
          filterByDate(helperArrDate[i])
        );
        let revenueVal =
          filteredRevenueVal.length > 0
            ? limitToTwoDecimal(filteredRevenueVal[0]?.value)
            : 0;
        revenueArr.push(revenueVal);

        let grossWageVal = convertWeekPatternInArr(
          overviewData.grossWages,
          i,
          helperArrDate
        );
        grossWageArr.push(grossWageVal);

        const filterDataByDate = overviewData.rangeData?.find(
          (item) => formatDate(item.date) === formatDate(helperArrDate[i])
        );
        // const avgFeeRate = (filterDataByDate?.payAsYouGoAverageFeeRates || 0) + (filterDataByDate?.fixedPeriodAverageFeeRates || 0)
        avgFixFeeArr.push(
          limitToTwoDecimal(filterDataByDate?.fixedPeriodAverageFeeRates ?? 0)
        );
        avgPasAsGoArr.push(
          limitToTwoDecimal(filterDataByDate?.payAsYouGoAverageFeeRates ?? 0)
        );
        staffChurnRateArr.push(twoDecimalPlaces(filterDataByDate?.churnRate));
        occupancyArr.push(twoDecimalPlaces(filterDataByDate?.occupancy));
        occupancyRateArr.push(
          twoDecimalPlaces(filterDataByDate?.occupancyRates)
        );
      }
    }

    function convertWeekPatternInArr(allVals, i, CompareArr) {
      const arrOfObjects = allVals?.map((item) => ({
        date: Object.keys(item)[0],
        value: Object.values(item)[0],
      }));
      const filteredVal = arrOfObjects?.find(
        (item) => item.date.split(" : ")?.[1] === CompareArr[i]
      );
      return filteredVal ? limitToTwoDecimal(filteredVal?.value) : 0;
    }

    setGraphVals({
      revenue: revenueArr,
      grossWageCosts: grossWageArr,
      avgFixFeeRates: avgFixFeeArr,
      avgPayAsGoFeeRates: avgPasAsGoArr,
      occupancy: occupancyArr,
      occupancyRates: occupancyRateArr,
      staffChurnRate: staffChurnRateArr,
    });
  }

  function filterByDate(maxDate) {
    return function (item) {
      let dataDate = new Date(item.date);
      let compareDate = new Date(maxDate);
      return (
        dataDate.getFullYear() === compareDate.getFullYear() &&
        dataDate.getMonth() === compareDate.getMonth() &&
        dataDate.getDate() === compareDate.getDate()
      );
    };
  }

  function dailyCount(hrsVal, dateKey) {
    return function (item) {
      if (
        new Date(today).toDateString() ===
        new Date(item[dateKey]).toDateString()
      ) {
        let hours = new Date(item[dateKey]).getHours();
        return hours <= hrsVal;
      }
    };
  }

  function dailyCountChurnRate(hrsVal, dateKey, type) {
    return function (item) {
      if (
        new Date(today).toDateString() ===
        new Date(item[dateKey]).toDateString()
      ) {
        let hours = new Date(item[dateKey]).getHours();
        if (type === "active") {
          return hours <= hrsVal && item?.careMemberstatus === "active";
        } else {
          return hours <= hrsVal && item?.careMemberstatus !== "active";
        }
      }
    };
  }

  function cutomizeHz() {
    helperArrDate = [];
    customDates = [];
    let customBasedHz = [];
    const getDateDifference = (date1, date2) => {
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };
    let difference = getDateDifference(startDate, endDate);
    for (let i = 0; i < difference; i++) {
      let finalDate = new Date(startDate);
      if (i > 0) {
        let dayByInterval = new Date(helperArrDate[0]).getDate() + i;
        finalDate.setDate(dayByInterval);
        if (finalDate.getTime() >= endDate.getTime()) {
          finalDate = new Date(endDate);
        }
      }
      let formatedDate = formatDateToDDMMYYYY(finalDate);
      helperArrDate.push(formatedDate);
      let changedFormat = finalDate.toLocaleDateString("en-GB").slice(0, 5);
      customDates.push(changedFormat);
      if (finalDate.getTime() >= endDate.getTime()) {
        break;
      }
    }
  }

  function formatDateToDDMMYYYY(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const getSalesOverviewData = async (start, end) => {
    // let careId = currentCareSiteId;
    const res = await httpAdmin(
      `salesOverview?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`,
      "get",
      null,
      null
    );
    if (res.data) {
      setOverviewData(res.data);
      let leads_data = res.data?.leadsData?.data;
      let client_data = res.data?.clientData;

      const leadCounts = {};
      leads_data?.forEach((item) => {
        if (leadCounts[item.leadsStatus]) {
          leadCounts[item.leadsStatus]++;
        } else {
          leadCounts[item.leadsStatus] = 1;
        }
      });
      let finalLeadCountData = {
        total: leads_data?.length,
        success: leadCounts.success ? leadCounts.success : 0,
        earlyStage: leadCounts["earlyStage"] ? leadCounts["earlyStage"] : 0,
        caseClose: leadCounts["caseClosed"] ? leadCounts["caseClosed"] : 0,
        qualified: leadCounts.qualified ? leadCounts.qualified : 0,
      };
      setLeadsSeries([
        finalLeadCountData.earlyStage,
        finalLeadCountData.caseClose,
        finalLeadCountData.qualified,
        finalLeadCountData.success,
      ]);
      setLeadsData(finalLeadCountData);

      let leadsPlanCounts = {
        total: leads_data?.length > 0 ? leads_data?.length : 0,
        council: 0,
        selfFunded: 0,
      };
      leads_data?.forEach((item) => {
        if (item.clientPlanType === "Council") {
          leadsPlanCounts.council++;
        } else if (item.clientPlanType === "Self-Funded") {
          leadsPlanCounts.selfFunded++;
        }
      });
      setLeadsPlanType(leadsPlanCounts);

      let clientsPlanCounts = {
        total: client_data?.length > 0 ? client_data?.length : 1,
        council: 0,
        selfFunded: 0,
      };
      client_data?.forEach((item) => {
        if (item.clientPlanType === "Council") {
          clientsPlanCounts.council++;
        } else {
          clientsPlanCounts.selfFunded++;
        }
      });
      setClientsPlanType(clientsPlanCounts);
    }
  };

  return (
    <div
      className="row"
      style={{ height: "79vh", overflowY: "auto", overflowX: "hidden" }}
    >
      {/* <ReactHookForm /> */}
      {/* <LocationSearchInput /> */}

      <GraphsSalesFunnel
        title="Revenue-(Excl. Tax)"
        customDates={customDates}
        filterType={filterType}
        graphVals={graphVals.revenue}
      />

      <GraphsSalesFunnel
        title="Gross Wage Costs"
        customDates={customDates}
        filterType={filterType}
        graphVals={graphVals.grossWageCosts}
      />

      <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
        <div className="card dashboard_card card_border">
          <div className="card-body">
            <h4 className="card-title">Leads in Funnel</h4>
            <div className="graph_box" style={{ minHeight: "230px" }}>
              <ul>
                <li>
                  Total Leads :{" "}
                  <strong style={{ color: "#818181" }}>
                    {leadsData?.total}
                  </strong>
                </li>
                <li>
                  Early Stage :{" "}
                  <strong style={{ color: "#c580d1" }}>
                    {leadsData?.earlyStage}
                  </strong>
                </li>
                <li>
                  Case Closed :{" "}
                  <strong style={{ color: "#eb5757" }}>
                    {leadsData?.caseClose}
                  </strong>
                </li>
                <li>
                  Qualified (Q) :{" "}
                  <strong style={{ color: "#2f80ed" }}>
                    {leadsData?.qualified}
                  </strong>
                </li>
                <li>
                  Success :{" "}
                  <strong style={{ color: "#219653" }}>
                    {leadsData?.success}
                  </strong>
                </li>
              </ul>
              <DonutSales
                midTitle="Success Rate"
                labels={["Early Stage", "Case Closed", "Qualified", "Success"]}
                rate={twoDecimalPlaces(
                  (leadsData?.success * 100) / leadsData?.total
                )}
                colors={["#c580d1", "#eb5757", "#2f80ed", "#219653"]}
                series={leadsSeries}
              />
            </div>
            {/* <NavLink to="" className="link">
              See All
            </NavLink> */}
          </div>
        </div>
      </div>

      <GraphsSalesFunnel
        title="Average Fee Rates"
        customDates={customDates}
        filterType={filterType}
        graphVals={{
          fixFee: graphVals.avgFixFeeRates,
          payAsGo: graphVals.avgPayAsGoFeeRates,
        }}
      />

      <GraphsSalesFunnel
        title="Staff Churn Rate"
        customDates={customDates}
        filterType={filterType}
        graphVals={graphVals.staffChurnRate}
      />

      <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
        <div className="card dashboard_card card_border">
          <div className="card-body">
            <h4 className="card-title">Leads Plan Type</h4>
            <div className="graph_box" style={{ minHeight: "230px" }}>
              <ul>
                <li>
                  Council :{" "}
                  <strong style={{ color: "#818181" }}>
                    {leadsPlanType?.council}
                  </strong>
                </li>
                <li>
                  Private :{" "}
                  <strong style={{ color: "#c580d1" }}>
                    {leadsPlanType?.selfFunded}
                  </strong>
                </li>
              </ul>
              <DonutSales
                midTitle="Private"
                labels={["Council", "Private"]}
                rate={twoDecimalPlaces(
                  (leadsPlanType?.selfFunded * 100) / leadsPlanType?.total
                )}
                colors={["#8C00A480", "#9B51E0"]}
                series={[leadsPlanType?.council, leadsPlanType?.selfFunded]}
              />
            </div>
            {/* <NavLink to="" className="link">
              See All
            </NavLink> */}
          </div>
        </div>
      </div>

      <GraphsSalesFunnel
        title="Occupancy"
        customDates={customDates}
        filterType={filterType}
        graphVals={graphVals.occupancy}
      />

      <GraphsSalesFunnel
        title="Occupancy Rates"
        customDates={customDates}
        filterType={filterType}
        graphVals={graphVals.occupancyRates}
      />

      <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
        <div className="card dashboard_card card_border">
          <div className="card-body">
            <h4 className="card-title">Clients Plan Type</h4>
            <div className="graph_box" style={{ minHeight: "230px" }}>
              <ul>
                <li>
                  Council :{" "}
                  <strong style={{ color: "#8C00A480" }}>
                    {clientsPlanType?.council}
                  </strong>
                </li>
                <li>
                  Private :{" "}
                  <strong style={{ color: "#9B51E0" }}>
                    {clientsPlanType?.selfFunded}
                  </strong>
                </li>
              </ul>
              <DonutSales
                midTitle="Private"
                rate={twoDecimalPlaces(
                  (clientsPlanType.selfFunded * 100) / clientsPlanType?.total
                )}
                colors={["#8C00A480", "#9B51E0"]}
                labels={["Council", "Private"]}
                series={[clientsPlanType.council, clientsPlanType.selfFunded]}
              />
            </div>
            {/* <NavLink to="" className="link">
              See All
            </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesOverview;

function getWeekDates(dateString) {
  const inputDate = moment(dateString);
  const weekStart = inputDate.clone().startOf("isoWeek");
  const weekDates = [];
  for (let i = 0; i < 7; i++) {
    weekDates.push(weekStart.clone().add(i, "days").format("YYYY-MM-DD"));
  }
  return weekDates;
}
