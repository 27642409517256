import React, { useEffect, useState } from "react";
import { formatDate } from "../../utils/CommonFunctions";

export default function AdditionalPlanModal({
  setAdditionalPlan,
  additionalPlan,
}) {
  const [formValues, setFormValues] = useState(
    additionalPlan
      ? {
          ...additionalPlan,
        }
      : {}
  );

  const handleChangeInputValues = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setAdditionalPlan({ ...formValues });
  }, [formValues]);

  return (
    <>
      {/* <label> Additional support plan name</label>
      <div className="d-flex mb-3">
        <input
          title="The name of the particular additional supporting plan, e.g. dieticians plan, wound management plan, discharge management plan and behaviour support plan"
          placeholder={
            "The name of the particular additional supporting plan, e.g. dieticians plan, wound management plan, discharge management plan and behaviour support plan"
          }
          type="text"
          className="form-control"
          // {...register('additionalSupportPlanName')}

          name="additionalSupportPlanName"
          value={formValues.additionalSupportPlanName}
          onChange={handleChangeInputValues}
        />
      </div> */}

      <label> Additional support plan content</label>

      <div className="d-flex mb-3">
        <textarea
          title="This is the content of any additional care and support plan which the person and/or care professional consider should be shared with others providing care and support. May be structured in different ways, e.g. tables, diagrams, images. This is the content of any additional care and support plan which the individual and/or care professional consider should be shared with others providing care and support. It should be structured as recommended for the care and support plan and if contains additional detail, it may be referenced here."
          placeholder={
            "This is the content of any additional care and support plan which the person and/or care professional consider should be shared with others providing care and support. May be structured in different ways, e.g. tables, diagrams, images. This is the content of any additional care and support plan which the individual and/or care professional consider should be shared with others providing care and support. It should be structured as recommended for the care and support plan and if contains additional detail, it may be referenced here."
          }
          rows={4}
          type="text"
          className="form-control"
          // {...register('additionalSupportPlanContent')}

          name="additionalSupportPlanContent"
          value={formValues.additionalSupportPlanContent}
          onChange={handleChangeInputValues}
        />
      </div>
      <label> Planned review date/interval</label>

      <div className="d-flex mb-3">
        <input
          type="date"
          className="form-control"
          // {...register('plannedReviewDate')}

          name="plannedReviewDate"
          value={formatDate(formValues.plannedReviewDate)}
          onChange={handleChangeInputValues}
        />
      </div>
      <hr />
      <h5 className="mb-3">Responsibility for review :</h5>
      <label>Name</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('responsibilityForReview-name')}
          title="The name of the professional with responsibility for the care of the person"
          placeholder="The name of the professional with responsibility for the care of the person"
          name="responsibilityForReview-name"
          value={formValues["responsibilityForReview-name"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Role</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('responsibilityForReview-role')}
          title="The role the professional has in relation to the person e.g. GP, physiotherapist, community nurse, social worker, key worker, care home manager, care coordinator, LA hospital liaison person, care home contact, hospital clinician, Independent Mental Capacity Advocate (IMCA) etc"
          placeholder="The role the professional has in relation to the person e.g. GP, physiotherapist, community nurse, social worker, key worker, care home manager, care coordinator, LA hospital liaison person, care home contact, hospital clinician, Independent Mental Capacity Advocate (IMCA) etc"
          name="responsibilityForReview-role"
          value={formValues["responsibilityForReview-role"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Contact details</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('responsibilityForReview-contactDetails')}
          title="Contact details of the professional (e.g. telephone number, email address etc.)."
          placeholder="Contact details of the professional (e.g. telephone number, email address etc.)."
          name="responsibilityForReview-contactDetails"
          value={formValues["responsibilityForReview-contactDetails"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Date this plan was last updated</label>

      <div className="d-flex mb-3">
        <input
          type="date"
          className="form-control"
          // {...register('dateThisPlanWasLastUpdated')}

          name="dateThisPlanWasLastUpdated"
          value={formatDate(formValues.dateThisPlanWasLastUpdated)}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Location</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportLocation')}
          title="The location where the additional support plan was prepared"
          placeholder="The location where the additional support plan was prepared"
          name="additionalSupportLocation"
          value={formValues.additionalSupportLocation}
          onChange={handleChangeInputValues}
        />
      </div>
      <h5 className="mb-3">Performing professional :</h5>

      <label>Name</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPerformingProfessional-name')}
          title="The name of the professional."
          placeholder="The name of the professional."
          name="additionalSupportPerformingProfessional-name"
          value={formValues["additionalSupportPerformingProfessional-name"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Role</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPerformingProfessional-role')}
          title="The role the professional has in relation to the person e.g. GP, physiotherapist, community nurse, social worker etc"
          placeholder="The role the professional has in relation to the person e.g. GP, physiotherapist, community nurse, social worker etc"
          name="additionalSupportPerformingProfessional-role"
          value={formValues["additionalSupportPerformingProfessional-role"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Grade</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPerformingProfessional-grade')}
          title="The grade of the professional."
          placeholder="The grade of the professional."
          name="additionalSupportPerformingProfessional-grade"
          value={formValues["additionalSupportPerformingProfessional-grade"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Speciality</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPerformingProfessional-speciality')}
          title="The specialty of the professional e.g. physiotherapy, oncology, mental health etc"
          placeholder="The specialty of the professional e.g. physiotherapy, oncology, mental health etc"
          name="additionalSupportPerformingProfessional-speciality"
          value={
            formValues["additionalSupportPerformingProfessional-speciality"]
          }
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Professional identifier</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPerformingProfessional-professionalIdentifire')}
          title="Professional identifier for the professional e.g. GMC number, HCPC number etc or the personal identifier used by the local organisation."
          placeholder="Professional identifier for the professional e.g. GMC number, HCPC number etc or the personal identifier used by the local organisation."
          name="additionalSupportPerformingProfessional-professionalIdentifire"
          value={
            formValues[
              "additionalSupportPerformingProfessional-professionalIdentifire"
            ]
          }
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Organisation</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPerformingProfessional-organisation')}
          title="The name of the organisation the professional works for."
          placeholder="The name of the organisation the professional works for."
          name="additionalSupportPerformingProfessional-organisation"
          value={
            formValues["additionalSupportPerformingProfessional-organisation"]
          }
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Contact details</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPerformingProfessional-contactDetails')}
          title="Contact details of the professional"
          placeholder="Contact details of the professional"
          name="additionalSupportPerformingProfessional-contactDetailse"
          value={
            formValues[
              "additionalSupportPerformingProfessional-contactDetailse"
            ]
          }
          onChange={handleChangeInputValues}
        />
      </div>
      <hr />
      <h5 className="mb-3">Person completing record :</h5>

      <label>Name</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-name')}
          title="The name of the professional."
          placeholder="The name of the professional."
          name="additionalSupportPersonCompletingRecord-name"
          value={formValues["additionalSupportPersonCompletingRecord-name"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Role</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-role')}
          title="The role the professional has in relation to the person e.g. GP, physiotherapist, community nurse, social worker etc"
          placeholder="The role the professional has in relation to the person e.g. GP, physiotherapist, community nurse, social worker etc"
          name="additionalSupportPersonCompletingRecord-role"
          value={formValues["additionalSupportPersonCompletingRecord-role"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Grade</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-grade')}
          title="The grade of the professional."
          placeholder="The grade of the professional."
          name="additionalSupportPersonCompletingRecord-grade"
          value={formValues["additionalSupportPersonCompletingRecord-grade"]}
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Speciality</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-speciality')}
          title="The specialty of the professional e.g. physiotherapy, oncology, mental health etc"
          placeholder="The specialty of the professional e.g. physiotherapy, oncology, mental health etc"
          name="additionalSupportPersonCompletingRecord-speciality"
          value={
            formValues["additionalSupportPersonCompletingRecord-speciality"]
          }
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Professional identifier</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-professionalIdentifire')}
          title="Professional identifier for the professional e.g. GMC number, HCPC number etc or the personal identifier used by the local organisation."
          placeholder="Professional identifier for the professional e.g. GMC number, HCPC number etc or the personal identifier used by the local organisation."
          name="additionalSupportPersonCompletingRecord-professionalIdentifire"
          value={
            formValues[
              "additionalSupportPersonCompletingRecord-professionalIdentifire"
            ]
          }
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Organisation</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-organisation')}
          title="The name of the organisation the professional works for."
          placeholder="The name of the organisation the professional works for."
          name="additionalSupportPersonCompletingRecord-organisation"
          value={
            formValues["additionalSupportPersonCompletingRecord-organisation"]
          }
          onChange={handleChangeInputValues}
        />
      </div>
      <label>Date completed</label>
      <div className="d-flex mb-3">
        <input
          type="date"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-dateCompleted')}

          name="additionalSupportPersonCompletingRecord-dateCompleted"
          value={
            formatDate(formValues["additionalSupportPersonCompletingRecord-dateCompleted"])
          }
          onChange={handleChangeInputValues}
        />
      </div>

      <label>Contact details</label>

      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control"
          // {...register('additionalSupportPersonCompletingRecord-contactDetails')}
          title="Contact details of the professional"
          placeholder="Contact details of the professional"
          name="additionalSupportPersonCompletingRecord-contactDetails"
          value={
            formValues["additionalSupportPersonCompletingRecord-contactDetails"]
          }
          onChange={handleChangeInputValues}
        />
      </div>
    </>
  );
}
