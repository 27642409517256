import React from "react";
import "../Clients/clients.css";
import AdminWrapper from "../../components/AdminWrapper";
import { useDispatch } from "react-redux";
import { addCarePlanTemplate } from "../../redux-toolkit/reducer/carePlanReducer";
import ClientRoutes from "./clientRoute/ClientRoutes";
import { Switch, Route, useRouteMatch, useLocation, useHistory } from "react-router-dom";
// import { ShowInfoSwal } from "../../utils/alertSwal";


function ClientsDetails() {

  const history = useHistory();
  const location = useLocation();
  const clientId = location.state?.clientId;
  let { path, url } = useRouteMatch();

  const dispatch = useDispatch();

  const handleNavigate = (type) => {
    // if (currentPlanName !== 'Turbo' && type === 'docs') {
    //   ShowInfoSwal(`This feature is not available in the ${currentPlanName} Plan`);
    //   return;
    // }
    dispatch(
      addCarePlanTemplate({
        savedPlanHistoryStatus: false,
      })
    );
    history.replace({
      pathname: `${url}/${type}`,
      state: { clientId: clientId },
    });
  };

  return (
    <>
      <AdminWrapper>
        <div className="card">
          <div className="card-body">
            <div className="top_menubar">
              <ul
                className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Profile === "no"}
                    onClick={() => handleNavigate('profile')}
                    className={`nav-link curser_point ${window.location.href.includes("profile") ? "active" : ""
                      }`}
                  >
                    Profile
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Profile === "no"}
                    onClick={() => handleNavigate('about-me')}
                    className={`nav-link curser_point ${window.location.href.includes("about-me") ? "active" : ""
                      }`}
                  >
                    About Me
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Profile === "no"}
                    onClick={() => handleNavigate('admin-details')}
                    className={`nav-link curser_point ${window.location.href.includes("admin-details") ? "active" : ""
                      }`}
                  >
                    Admin
                  </button>
                </li>
                <li className={`nav-item`} role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Contacts === "no"}
                    onClick={() => handleNavigate('contacts')}
                    className={`nav-link curser_point ${window.location.href.includes("contacts")
                      ? "active"
                      : ""
                      }`}
                  >
                    Contacts
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.['Compliance Docs'] === "no"}
                    onClick={() => handleNavigate('docs')}
                    className={`nav-link curser_point ${window.location.href.includes("docs") ? "active" : ""
                      }`}
                  >
                    Docs
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.Journal === "no"}
                    onClick={() => handleNavigate('journal')}
                    className={`nav-link curser_point ${window.location.href.includes("journal") ? "active" : ""
                      }`}
                  >
                    Journal
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    // disabled={currentModuleAccess?.['Care Plan'] === "no"}
                    className={`nav-link curser_point ${window.location.href.includes("care-plan") ? "active" : ""
                      }`}
                    onClick={() => {
                      handleNavigate('care-plan')
                    }}
                  >
                    Care Plan
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content mt-3" id="myTabContent">
              <Switch>
                <Route path={`${path}/:topicId`}>
                  <ClientRoutes />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </AdminWrapper >
    </>
  );
}

export default ClientsDetails;
