export const speciality = [
  { label: "General Surgery", value: "General Surgery" },
  { label: "Urology", value: "Urology" },
  { label: "Vascular Surgery", value: "Vascular Surgery" },
  { label: "Trauma and Orthopaedics", value: "Trauma and Orthopaedics" },
  { label: "Ear Nose and Throat", value: "Ear Nose and Throat" },
  { label: "Ophthalmology", value: "Ophthalmology" },
  { label: "Oral Surgery", value: "Oral Surgery" },
  { label: "Restorative Dentistry", value: "Restorative Dentistry" },
  { label: "Paediatric Dentistry", value: "Paediatric Dentistry" },
  { label: "Orthodontics", value: "Orthodontics" },
  {
    label: "Oral and Maxillofacial Surgery",
    value: "Oral and Maxillofacial Surgery",
  },
  { label: "Endodontics", value: "Endodontics" },
  { label: "Periodontics", value: "Periodontics" },
  { label: "Prosthodontics", value: "Prosthodontics" },
  { label: "Surgical Dentistry", value: "Surgical Dentistry" },
  { label: "Neurosurgery", value: "Neurosurgery" },
  { label: "Plastic Surgery", value: "Plastic Surgery" },
  { label: "Cardiothoracic Surgery", value: "Cardiothoracic Surgery" },
  { label: "Paediatric Surgery", value: "Paediatric Surgery" },
  {
    label: "Pain Management (Retired 1 April 2004)",
    value: "Pain Management (Retired 1 April 2004)",
  },
  { label: "Emergency Medicine", value: "Emergency Medicine" },
  { label: "Anaesthetics", value: "Anaesthetics" },
  { label: "Intensive Care Medicine", value: "Intensive Care Medicine" },
  {
    label: "Aviation and Space Medicine",
    value: "Aviation and Space Medicine",
  },
  { label: "General Internal Medicine", value: "General Internal Medicine" },
  { label: "Gastroenterology", value: "Gastroenterology" },
  { label: "Endocrinology and Diabetes", value: "Endocrinology and Diabetes" },
  { label: "Clinical Haematology", value: "Clinical Haematology" },
  { label: "Clinical Physiology", value: "Clinical Physiology" },
  { label: "Clinical Pharmacology", value: "Clinical Pharmacology" },
  { label: "Audio Vestibular Medicine", value: "Audio Vestibular Medicine" },
  { label: "Clinical Genetics", value: "Clinical Genetics" },
  {
    label:
      "CLINICAL CYTOGENETICS and MOLECULAR GENETICS (Retired 1 April 2010)",
    value:
      "CLINICAL CYTOGENETICS and MOLECULAR GENETICS (Retired 1 April 2010)",
  },
  { label: "Clinical Immunology", value: "Clinical Immunology" },
  { label: "Rehabilitation Medicine", value: "Rehabilitation Medicine" },
  { label: "Palliative Medicine", value: "Palliative Medicine" },
  { label: "Allergy", value: "Allergy" },
  { label: "Cardiology", value: "Cardiology" },
  { label: "Paediatric Cardiology", value: "Paediatric Cardiology" },
  {
    label: "Sport and Exercise Medicine",
    value: "Sport and Exercise Medicine",
  },
  { label: "Acute Internal Medicine", value: "Acute Internal Medicine" },
  { label: "Dermatology", value: "Dermatology" },
  { label: "Respiratory Medicine", value: "Respiratory Medicine" },
  { label: "Infectious Diseases", value: "Infectious Diseases" },
  { label: "Tropical Medicine", value: "Tropical Medicine" },
  { label: "Genitourinary Medicine", value: "Genitourinary Medicine" },
  { label: "Renal Medicine", value: "Renal Medicine" },
  { label: "Medical Oncology", value: "Medical Oncology" },
  { label: "Nuclear Medicine", value: "Nuclear Medicine" },
  { label: "Neurology", value: "Neurology" },
  { label: "Clinical Neurophysiology", value: "Clinical Neurophysiology" },
  { label: "Rheumatology", value: "Rheumatology" },
  { label: "Paediatrics", value: "Paediatrics" },
  { label: "Paediatric Neurology", value: "Paediatric Neurology" },
  { label: "Geriatric Medicine", value: "Geriatric Medicine" },
  { label: "Dental Medicine", value: "Dental Medicine" },
  { label: "Special Care Dentistry", value: "Special Care Dentistry" },
  { label: "Medical Ophthalmology", value: "Medical Ophthalmology" },
  { label: "Obstetrics and Gynaecology", value: "Obstetrics and Gynaecology" },
  { label: "Obstetrics", value: "Obstetrics" },
  { label: "Gynaecology", value: "Gynaecology" },
  {
    label: "Community Sexual and Reproductive Health",
    value: "Community Sexual and Reproductive Health",
  },
  { label: "Midwifery", value: "Midwifery" },
  { label: "General Medical Practice", value: "General Medical Practice" },
  { label: "General Dental Practice", value: "General Dental Practice" },
  { label: "Learning Disability", value: "Learning Disability" },
  { label: "Adult Mental Illness", value: "Adult Mental Illness" },
  {
    label: "Child and Adolescent Psychiatry",
    value: "Child and Adolescent Psychiatry",
  },
  { label: "Forensic Psychiatry", value: "Forensic Psychiatry" },
  { label: "Medical Psychotherapy", value: "Medical Psychotherapy" },
  { label: "Old Age Psychiatry", value: "Old Age Psychiatry" },
  { label: "Clinical Oncology", value: "Clinical Oncology" },
  { label: "Radiology", value: "Radiology" },
  { label: "General Pathology", value: "General Pathology" },
  { label: "Blood Transfusion", value: "Blood Transfusion" },
  { label: "Chemical Pathology", value: "Chemical Pathology" },
  { label: "Haematology", value: "Haematology" },
  { label: "Histopathology", value: "Histopathology" },
  { label: "Immunopathology", value: "Immunopathology" },
  {
    label: "Medical Microbiology and Virology",
    value: "Medical Microbiology and Virology",
  },
  { label: "Medical Microbiology", value: "Medical Microbiology" },
  { label: "Medical Virology", value: "Medical Virology" },
  { label: "Community Medicine", value: "Community Medicine" },
  { label: "Occupational Medicine", value: "Occupational Medicine" },
  {
    label: "Community Health Services Dental",
    value: "Community Health Services Dental",
  },
  { label: "Public Health Medicine", value: "Public Health Medicine" },
  { label: "Public Health Dental", value: "Public Health Dental" },
  { label: "Nursing", value: "Nursing" },
  { label: "Allied Health Professional", value: "Allied Health Professional" },
  { label: "Joint Consultant Clinics ", value: "Joint Consultant Clinics" },
];

export const careRelationship = [
  { label: "adopted child", value: "adopted child" },
  { label: "adopted daughter", value: "adopted daughter" },
  { label: "adopted son", value: "adopted son" },
  { label: "adoptive father", value: "adoptive father" },
  { label: "adoptive mother", value: "adoptive mother" },
  { label: "adoptive parent", value: "adoptive parent" },
  { label: "Agent of patient", value: "Agent of patient" },
  { label: "aunt", value: "aunt" },
  { label: "Billing contact person", value: "Billing contact person" },
  { label: "brother", value: "brother" },
  { label: "brother-in-law", value: "brother-in-law" },
  { label: "Carer of patient", value: "Carer of patient" },
  { label: "child", value: "child" },
  { label: "child-in-law", value: "child-in-law" },
  { label: "Contact person", value: "Contact person" },
  { label: "cousin", value: "cousin" },
  { label: "daughter", value: "daughter" },
  { label: "daughter in-law", value: "daughter in-law" },
  { label: "Dependant of patient", value: "Dependant of patient" },
  { label: "domestic partner", value: "domestic partner" },
  { label: "Emergency Contact", value: "Emergency Contact" },
  { label: "Emergency contact person", value: "Emergency contact person" },
  { label: "Employer", value: "Employer" },
  { label: "extended family member", value: "extended family member" },
  { label: "family member", value: "family member" },
  { label: "father", value: "father" },
  { label: "father-in-law", value: "father-in-law" },
  { label: "former spouse", value: "former spouse" },
  { label: "foster child", value: "foster child" },
  { label: "foster daughter", value: "foster daughter" },
  { label: "foster father", value: "foster father" },
  { label: "foster mother", value: "foster mother" },
  { label: "foster parent", value: "foster parent" },
  { label: "foster son", value: "foster son" },
  { label: "fraternal twin", value: "fraternal twin" },
  { label: "fraternal twin brother", value: "fraternal twin brother" },
  { label: "fraternal twin sister", value: "fraternal twin sister" },
  { label: "gestational mother", value: "gestational mother" },
  { label: "grandchild", value: "grandchild" },
  { label: "granddaughter", value: "granddaughter" },
  { label: "grandfather", value: "grandfather" },
  { label: "grandmother", value: "grandmother" },
  { label: "grandparent", value: "grandparent" },
  { label: "grandson", value: "grandson" },
  { label: "great grandfather", value: "great grandfather" },
  { label: "great grandmother", value: "great grandmother" },
  { label: "great grandparent", value: "great grandparent" },
  { label: "Guardian of patient", value: "Guardian of patient" },
  { label: "half-brother", value: "half-brother" },
  { label: "half-sibling", value: "half-sibling" },
  { label: "half-sister", value: "half-sister" },
  { label: "husband", value: "husband" },
  { label: "identical twin", value: "identical twin" },
  { label: "identical twin brother", value: "identical twin brother" },
  { label: "identical twin sister", value: "identical twin sister" },
  { label: "inlaw", value: "inlaw" },
  { label: "Insurance Company", value: "Insurance Company" },
  { label: "maternal aunt", value: "maternal aunt" },
  { label: "maternal cousin", value: "maternal cousin" },
  { label: "maternal grandfather", value: "maternal grandfather" },
  { label: "maternal grandmother", value: "maternal grandmother" },
  { label: "maternal grandparent", value: "maternal grandparent" },
  { label: "maternal great-grandfather", value: "maternal great-grandfather" },
  { label: "maternal great-grandmother", value: "maternal great-grandmother" },
  { label: "maternal great-grandparent", value: "maternal great-grandparent" },
  { label: "maternal uncle", value: "maternal uncle" },
  { label: "mother", value: "mother" },
  { label: "mother-in-law", value: "mother-in-law" },
  { label: "natural brother", value: "natural brother" },
  { label: "natural child", value: "natural child" },
  { label: "natural daughter", value: "natural daughter" },
  { label: "natural father", value: "natural father" },
  { label: "natural father of fetus", value: "natural father of fetus" },
  { label: "natural mother", value: "natural mother" },
  { label: "natural mother of fetus", value: "natural mother of fetus" },
  { label: "natural parent", value: "natural parent" },
  { label: "natural sibling", value: "natural sibling" },
  { label: "natural sister", value: "natural sister" },
  { label: "natural son", value: "natural son" },
  { label: "neighbor", value: "neighbor" },
  { label: "nephew", value: "nephew" },
  { label: "Next-of-Kin", value: "Next-of-Kin" },
  { label: "niece", value: "niece" },
  { label: "niece/nephew", value: "niece/nephew" },
  { label: "Non Dependant of patient", value: "Non Dependant of patient" },
  { label: "Not Specified", value: "Not Specified" },
  { label: "parent", value: "parent" },
  { label: "parent in-law", value: "parent in-law" },
  { label: "paternal aunt", value: "paternal aunt" },
  { label: "paternal cousin", value: "paternal cousin" },
  { label: "paternal grandfather", value: "paternal grandfather" },
  { label: "paternal grandmother", value: "paternal grandmother" },
  { label: "paternal grandparent", value: "paternal grandparent" },
  { label: "paternal great-grandfather", value: "paternal great-grandfather" },
  { label: "paternal great-grandmother", value: "paternal great-grandmother" },
  { label: "paternal great-grandparent", value: "paternal great-grandparent" },
  { label: "paternal uncle", value: "paternal uncle" },
  { label: "Person preparing referral", value: "Person preparing referral" },
  {
    label: "Personal relationship with the patient",
    value: "Personal relationship with the patient",
  },
  {
    label: "Polygamous Partner of patient",
    value: "Polygamous Partner of patient",
  },
  {
    label: "Proxy Communication for patient",
    value: "Proxy Communication for patient",
  },
  {
    label: "Proxy Contact and Communication for patient",
    value: "Proxy Contact and Communication for patient",
  },
  { label: "Proxy Contact for patient", value: "Proxy Contact for patient" },
  { label: "Roommate", value: "Roommate" },
  { label: "self", value: "self" },
  { label: "sibling", value: "sibling" },
  { label: "sibling in-law", value: "sibling in-law" },
  { label: "significant other", value: "significant other" },
  { label: "sister", value: "sister" },
  { label: "sister-in-law", value: "sister-in-law" },
  { label: "son", value: "son" },
  { label: "son in-law", value: "son in-law" },
  { label: "spouse", value: "spouse" },
  { label: "State Agency", value: "State Agency" },
  { label: "step child", value: "step child" },
  { label: "step parent", value: "step parent" },
  { label: "step sibling", value: "step sibling" },
  { label: "stepbrother", value: "stepbrother" },
  { label: "stepdaughter", value: "stepdaughter" },
  { label: "stepfather", value: "stepfather" },
  { label: "stepmother", value: "stepmother" },
  { label: "stepsister", value: "stepsister" },
  { label: "stepson", value: "stepson" },
  { label: "twin", value: "twin" },
  { label: "twin brother", value: "twin brother" },
  { label: "twin sister", value: "twin sister" },
  { label: "uncle", value: "uncle" },
  { label: "Unknown", value: "Unknown" },
  { label: "unrelated friend", value: "unrelated friend" },
  { label: "wife", value: "wife" },
];

export const roles = [
  {
    label: "Social Care - CQC Registered Manager",
    value: "Social Care - CQC Registered Manager",
  },
  {
    label: "Medical and Dental - Medical Director",
    value: "Medical and Dental - Medical Director",
  },
  {
    label: "Medical and Dental - Clinical Director - Medical",
    value: "Medical and Dental - Clinical Director - Medical",
  },
  {
    label: "Medical and Dental - Professor",
    value: "Medical and Dental - Professor",
  },
  {
    label: "Medical and Dental - Senior Lecturer",
    value: "Medical and Dental - Senior Lecturer",
  },
  {
    label: "Medical and Dental - CONSULTANT",
    value: "Medical and Dental - CONSULTANT",
  },
  {
    label: "Medical and Dental - Dental surgeon acting as Hospital CONSULTANT",
    value: "Medical and Dental - Dental surgeon acting as Hospital CONSULTANT",
  },
  {
    label:
      "Medical and Dental - Special salary scale in Public Health Medicine",
    value:
      "Medical and Dental - Special salary scale in Public Health Medicine",
  },
  {
    label:
      "Medical and Dental - Associate Specialist (Closed to new entrants from 01 April 2008 or regrading from 01 April 2009)",
    value:
      "Medical and Dental - Associate Specialist (Closed to new entrants from 01 April 2008 or regrading from 01 April 2009)",
  },
  {
    label:
      "Medical and Dental - Staff Grade (Closed to new entrants 01 April 2008)",
    value:
      "Medical and Dental - Staff Grade (Closed to new entrants 01 April 2008)",
  },
  {
    label:
      "Medical and Dental - Hospital Practitioner (Closed to new entrants)",
    value:
      "Medical and Dental - Hospital Practitioner (Closed to new entrants)",
  },
  {
    label: "Medical and Dental - Clinical Assistant (Closed to new entrants)",
    value: "Medical and Dental - Clinical Assistant (Closed to new entrants)",
  },
  {
    label:
      "Medical and Dental - Specialist Registrar - Closed (Retired 01 April 2010)",
    value:
      "Medical and Dental - Specialist Registrar - Closed (Retired 01 April 2010)",
  },
  {
    label: "Medical and Dental - Registrar Group",
    value: "Medical and Dental - Registrar Group",
  },
  {
    label: "Medical and Dental - Speciality Doctor",
    value: "Medical and Dental - Speciality Doctor",
  },
  {
    label: "Medical and Dental - Clinical Research Fellow",
    value: "Medical and Dental - Clinical Research Fellow",
  },
  {
    label: "Medical and Dental - Foundation Doctor Year 1",
    value: "Medical and Dental - Foundation Doctor Year 1",
  },
  {
    label: "Medical and Dental - Foundation Doctor Year 2",
    value: "Medical and Dental - Foundation Doctor Year 2",
  },
  {
    label: "Medical and Dental - Consultant in Public Health Medicine",
    value: "Medical and Dental - Consultant in Public Health Medicine",
  },
  {
    label: "Medical and Dental - Core Training",
    value: "Medical and Dental - Core Training",
  },
  {
    label: "Medical and Dental - Specialty Registrar (StR) Specialty Training",
    value: "Medical and Dental - Specialty Registrar (StR) Specialty Training",
  },
  {
    label: "Medical and Dental - Dentist - Specialty Training",
    value: "Medical and Dental - Dentist - Specialty Training",
  },
  {
    label: "Medical and Dental - Salaried Dental Practitioner",
    value: "Medical and Dental - Salaried Dental Practitioner",
  },
  {
    label: "Medical and Dental - Specialised Training Doctor",
    value: "Medical and Dental - Specialised Training Doctor",
  },

  {
    label: "Medical and Dental - Medical Student",
    value: "Medical and Dental - Medical Student",
  },
  {
    label: "Medical and Dental - Trust Grade Doctor - Specialty Registrar",
    value: "Medical and Dental - Trust Grade Doctor - Specialty Registrar",
  },
  {
    label: "Medical and Dental - Foundation Dentist",
    value: "Medical and Dental - Foundation Dentist",
  },
  {
    label: "Medical and Dental - Associate Postgraduate Dean",
    value: "Medical and Dental - Associate Postgraduate Dean",
  },
  {
    label: "Medical and Dental - Trust Grade Doctor - Foundation Level",
    value: "Medical and Dental - Trust Grade Doctor - Foundation Level",
  },
  {
    label: "Medical and Dental - GP Senior Partner",
    value: "Medical and Dental - GP Senior Partner",
  },
  {
    label: "Medical and Dental - GP Partner/Provider",
    value: "Medical and Dental - GP Partner/Provider",
  },
  {
    label: "Medical and Dental - GP Retainer",
    value: "Medical and Dental - GP Retainer",
  },
  {
    label: "Medical and Dental - GP Locum",
    value: "Medical and Dental - GP Locum",
  },
  {
    label: "Medical and Dental - Dental Core Trainee",
    value: "Medical and Dental - Dental Core Trainee",
  },
  {
    label: "Medical and Dental - Specialist Dentist",
    value: "Medical and Dental - Specialist Dentist",
  },
  {
    label: "Students - Student NURSE - Adult Branch",
    value: "Students - Student NURSE - Adult Branch",
  },
  {
    label: "Students - Student NURSE - Child Branch",
    value: "Students - Student NURSE - Child Branch",
  },
  {
    label: "Students - Student NURSE - Mental Health Branch",
    value: "Students - Student NURSE - Mental Health Branch",
  },
  {
    label: "Students - Student NURSE - Learning Disabilities Branch",
    value: "Students - Student NURSE - Learning Disabilities Branch",
  },
  { label: "Students - Student MIDWIFE", value: "Students - Student MIDWIFE" },
  {
    label: "Students - Student Health Visitor",
    value: "Students - Student Health Visitor",
  },
  {
    label: "Students - Student District Nurse",
    value: "Students - Student District Nurse",
  },
  {
    label: "Students - Student School Nurse",
    value: "Students - Student School Nurse",
  },
  {
    label: "Students - Student Practice Nurse",
    value: "Students - Student Practice Nurse",
  },
  {
    label: "Students - Student Occupational Health Nurse",
    value: "Students - Student Occupational Health Nurse",
  },
  {
    label: "Students - Student Community Children's Nurse",
    value: "Students - Student Community Children's Nurse",
  },
  {
    label: "Students - Student Mental Health Nurse",
    value: "Students - Student Mental Health Nurse",
  },
  {
    label: "Students - Student Learning Disabilities Nurse",
    value: "Students - Student Learning Disabilities Nurse",
  },
  {
    label: "Students - Student Chiropodist",
    value: "Students - Student Chiropodist",
  },
  {
    label: "Students - Student Dietitian",
    value: "Students - Student Dietitian",
  },
  {
    label: "Students - Student Occupational_Therapist",
    value: "Students - Student Occupational_Therapist",
  },
  {
    label: "Students - Student Orthoptist",
    value: "Students - Student Orthoptist",
  },
  {
    label: "Students - Student Physiotherapist",
    value: "Students - Student Physiotherapist",
  },
  {
    label: "Students - Student Radiographer - Diagnostic",
    value: "Students - Student Radiographer - Diagnostic",
  },
  {
    label: "Students - Student Radiographer - Therapeutic",
    value: "Students - Student Radiographer - Therapeutic",
  },
  {
    label: "Students - Student Speech and Language Therapist",
    value: "Students - Student Speech and Language Therapist",
  },
  {
    label: "Students - Art, Music and Drama Student",
    value: "Students - Art, Music and Drama Student",
  },
  {
    label: "Students - Student Psychotherapist",
    value: "Students - Student Psychotherapist",
  },
  {
    label: "Students - Student Social_Worker",
    value: "Students - Student Social_Worker",
  },
  {
    label: "Students - Student Paramedic",
    value: "Students - Student Paramedic",
  },
  {
    label: "Students - Student Prosthetics and Orthotics",
    value: "Students - Student Prosthetics and Orthotics",
  },
  {
    label: "Students - Student Operating Department Practitioner",
    value: "Students - Student Operating Department Practitioner",
  },
  {
    label: "Students - Student Osteopath",
    value: "Students - Student Osteopath",
  },
  {
    label: "Nursing and Midwifery Registered - Director of Nursing",
    value: "Nursing and Midwifery Registered - Director of Nursing",
  },
  {
    label: "Nursing and Midwifery Registered - Nurse Consultant",
    value: "Nursing and Midwifery Registered - Nurse Consultant",
  },
  {
    label: "Nursing and Midwifery Registered - Nurse Manager",
    value: "Nursing and Midwifery Registered - Nurse Manager",
  },
  {
    label: "Nursing and Midwifery Registered - Modern Matron",
    value: "Nursing and Midwifery Registered - Modern Matron",
  },
  {
    label: "Nursing and Midwifery Registered - Specialist Nurse Practitioner",
    value: "Nursing and Midwifery Registered - Specialist Nurse Practitioner",
  },
  {
    label: "Nursing and Midwifery Registered - Sister/Charge Nurse",
    value: "Nursing and Midwifery Registered - Sister/Charge Nurse",
  },
  {
    label: "Nursing and Midwifery Registered - Staff Nurse",
    value: "Nursing and Midwifery Registered - Staff Nurse",
  },
  {
    label: "Nursing and Midwifery Registered - Enrolled Nurse",
    value: "Nursing and Midwifery Registered - Enrolled Nurse",
  },
  {
    label: "Nursing and Midwifery Registered - Midwife - Consultant",
    value: "Nursing and Midwifery Registered - Midwife - Consultant",
  },
  {
    label:
      "Nursing and Midwifery Registered - Midwife - Specialist Practitioner",
    value:
      "Nursing and Midwifery Registered - Midwife - Specialist Practitioner",
  },
  {
    label: "Nursing and Midwifery Registered - Midwife - Manager",
    value: "Nursing and Midwifery Registered - Midwife - Manager",
  },
  {
    label:
      "Nursing and Midwifery Registered - MIDWIFE - Sister/Charge Nurse (Retired 01 December 2012)",
    value:
      "Nursing and Midwifery Registered - MIDWIFE - Sister/Charge Nurse (Retired 01 December 2012)",
  },
  {
    label: "Nursing and Midwifery Registered - MIDWIFE",
    value: "Nursing and Midwifery Registered - MIDWIFE",
  },
  {
    label: "Nursing and Midwifery Registered - Community Practitioner",
    value: "Nursing and Midwifery Registered - Community Practitioner",
  },
  {
    label: "Nursing and Midwifery Registered - Community Nurse",
    value: "Nursing and Midwifery Registered - Community Nurse",
  },
  {
    label: "Nursing and Midwifery Registered - Advanced Practitioner",
    value: "Nursing and Midwifery Registered - Advanced Practitioner",
  },
  {
    label: "Nursing and Midwifery Registered - Practice Nurse",
    value: "Nursing and Midwifery Registered - Practice Nurse",
  },
  {
    label: "Nursing and Midwifery Registered - Extended Role Practice Nurse",
    value: "Nursing and Midwifery Registered - Extended Role Practice Nurse",
  },
  {
    label: "Nursing and Midwifery Registered - Practice Nurse Partner",
    value: "Nursing and Midwifery Registered - Practice Nurse Partner",
  },
  {
    label: "Nursing and Midwifery Registered - Practice Research Nurse",
    value: "Nursing and Midwifery Registered - Practice Research Nurse",
  },
  {
    label: "Nursing and Midwifery Registered - Practice Nurse Dispenser",
    value: "Nursing and Midwifery Registered - Practice Nurse Dispenser",
  },
  {
    label: "Nursing and Midwifery Registered - Surgical Care Practitioner",
    value: "Nursing and Midwifery Registered - Surgical Care Practitioner",
  },
  {
    label: "Allied Health Professionals - Art Therapist",
    value: "Allied Health Professionals - Art Therapist",
  },
  {
    label: "Allied Health Professionals - Art Therapist Consultant",
    value: "Allied Health Professionals - Art Therapist Consultant",
  },
  {
    label: "Allied Health Professionals - Art Therapist Manager",
    value: "Allied Health Professionals - Art Therapist Manager",
  },
  {
    label:
      "Allied Health Professionals - Art Therapist Specialist Practitioner",
    value:
      "Allied Health Professionals - Art Therapist Specialist Practitioner",
  },
  {
    label: "Allied Health Professionals - Chiropodist/Podiatrist",
    value: "Allied Health Professionals - Chiropodist/Podiatrist",
  },
  {
    label: "Allied Health Professionals - Chiropodist/Podiatrist Consultant",
    value: "Allied Health Professionals - Chiropodist/Podiatrist Consultant",
  },
  {
    label: "Allied Health Professionals - Chiropodist/Podiatrist Manager",
    value: "Allied Health Professionals - Chiropodist/Podiatrist Manager",
  },
  {
    label:
      "Allied Health Professionals - Chiropodist/Podiatrist Specialist Practitioner",
    value:
      "Allied Health Professionals - Chiropodist/Podiatrist Specialist Practitioner",
  },
  {
    label: "Allied Health Professionals - Dietitian",
    value: "Allied Health Professionals - Dietitian",
  },
  {
    label: "Allied Health Professionals - Dietitian Consultant",
    value: "Allied Health Professionals - Dietitian Consultant",
  },
  {
    label: "Allied Health Professionals - Dietitian Manager",
    value: "Allied Health Professionals - Dietitian Manager",
  },
  {
    label: "Allied Health Professionals - Dietitian Specialist Practitioner",
    value: "Allied Health Professionals - Dietitian Specialist Practitioner",
  },
  {
    label: "Allied Health Professionals - Dramatherapist",
    value: "Allied Health Professionals - Dramatherapist",
  },
  {
    label: "Allied Health Professionals - Dramatherapist Consultant",
    value: "Allied Health Professionals - Dramatherapist Consultant",
  },
  {
    label: "Allied Health Professionals - Dramatherapist Manager",
    value: "Allied Health Professionals - Dramatherapist Manager",
  },
  {
    label:
      "Allied Health Professionals - Dramatherapist Specialist Practitioner",
    value:
      "Allied Health Professionals - Dramatherapist Specialist Practitioner",
  },
  {
    label: "Allied Health Professionals - Occupational Therapist",
    value: "Allied Health Professionals - Occupational Therapist",
  },
  {
    label: "Allied Health Professionals - Occupational Therapist Consultant",
    value: "Allied Health Professionals - Occupational Therapist Consultant",
  },
  {
    label: "Allied Health Professionals - Occupational Therapist Manager",
    value: "Allied Health Professionals - Occupational Therapist Manager",
  },
  {
    label:
      "Allied Health Professionals - Occupational Therapist Specialist Practitioner",
    value:
      "Allied Health Professionals - Occupational Therapist Specialist Practitioner",
  },

  {
    label:
      "Additional Professional Scientific and Technical - Clinical Director",
    value:
      "Additional Professional Scientific and Technical - Clinical Director",
  },
  {
    label: "Additional Professional Scientific and Technical - OPTOMETRIST",
    value: "Additional Professional Scientific and Technical - OPTOMETRIST",
  },
  {
    label: "Additional Professional Scientific and Technical - Pharmacist",
    value: "Additional Professional Scientific and Technical - Pharmacist",
  },
  {
    label: "Additional Professional Scientific and Technical - Psychotherapist",
    value: "Additional Professional Scientific and Technical - Psychotherapist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist - Clinical",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist - Clinical",
  },
  {
    label: "Additional Professional Scientific and Technical - Chaplain",
    value: "Additional Professional Scientific and Technical - Chaplain",
  },
  {
    label: "Additional Professional Scientific and Technical - Social_Worker",
    value: "Additional Professional Scientific and Technical - Social_Worker",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Approved Social Worker (Retired 01 October 2017)",
    value:
      "Additional Professional Scientific and Technical - Approved Social Worker (Retired 01 October 2017)",
  },
  {
    label: "Additional Professional Scientific and Technical - Youth Worker",
    value: "Additional Professional Scientific and Technical - Youth Worker",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Specialist Practitioner",
    value:
      "Additional Professional Scientific and Technical - Specialist Practitioner",
  },
  {
    label: "Additional Professional Scientific and Technical - Practitioner",
    value: "Additional Professional Scientific and Technical - Practitioner",
  },
  {
    label: "Additional Professional Scientific and Technical - Technician",
    value: "Additional Professional Scientific and Technical - Technician",
  },
  {
    label:
      "Additional Professional Scientific and Technical - High Intensity Therapist",
    value:
      "Additional Professional Scientific and Technical - High Intensity Therapist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Trainee High Intensity Therapist",
    value:
      "Additional Professional Scientific and Technical - Trainee High Intensity Therapist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Physician Assistant (Retired 01 January 2016)",
    value:
      "Additional Professional Scientific and Technical - Physician Assistant (Retired 01 January 2016)",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Advanced Practitioner",
    value:
      "Additional Professional Scientific and Technical - Advanced Practitioner",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Physician Associate",
    value:
      "Additional Professional Scientific and Technical - Physician Associate",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Social Care Manager",
    value:
      "Additional Professional Scientific and Technical - Social Care Manager",
  },
  {
    label: "Additional Professional Scientific and Technical - Manager",
    value: "Additional Professional Scientific and Technical - Manager",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Approved Mental Health Professional",
    value:
      "Additional Professional Scientific and Technical - Approved Mental Health Professional",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Trainee Clinical Psychologist",
    value:
      "Additional Professional Scientific and Technical - Trainee Clinical Psychologist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Trainee Counselling Psychologist",
    value:
      "Additional Professional Scientific and Technical - Trainee Counselling Psychologist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Trainee Health Psychologist",
    value:
      "Additional Professional Scientific and Technical - Trainee Health Psychologist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Trainee Other Applied Psychologist",
    value:
      "Additional Professional Scientific and Technical - Trainee Other Applied Psychologist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist – Counselling",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist – Counselling",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist – Educational",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist – Educational",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist – Forensic",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist – Forensic",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist – Health",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist – Health",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist – Occupational",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist – Occupational",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist – Sport and Exercise",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist – Sport and Exercise",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Applied Psychologist – Neuropsychologist",
    value:
      "Additional Professional Scientific and Technical - Applied Psychologist – Neuropsychologist",
  },
  {
    label: "Additional Professional Scientific and Technical - Counsellor",
    value: "Additional Professional Scientific and Technical - Counsellor",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Social Worker - Psychological Therapist",
    value:
      "Additional Professional Scientific and Technical - Social Worker - Psychological Therapist",
  },
  {
    label: "Additional Professional Scientific and Technical - Play Therapist",
    value: "Additional Professional Scientific and Technical - Play Therapist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Family Therapist",
    value:
      "Additional Professional Scientific and Technical - Family Therapist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Child and Adolescent Psychological Therapist or Psychotherapist",
    value:
      "Additional Professional Scientific and Technical - Child and Adolescent Psychological Therapist or Psychotherapist",
  },
  {
    label:
      "Additional Professional Scientific and Technical - Anaesthesia Associate",
    value:
      "Additional Professional Scientific and Technical - Anaesthesia Associate",
  },
  {
    label: "Healthcare Scientists - Clinical_Scientist (Retired 01 April 2013)",
    value: "Healthcare Scientists - Clinical_Scientist (Retired 01 April 2013)",
  },
  {
    label: "Healthcare Scientists - Consultant Healthcare Scientist",
    value: "Healthcare Scientists - Consultant Healthcare Scientist",
  },
  {
    label:
      "Healthcare Scientists - Biomedical_Scientist (Retired 01 April 2013)",
    value:
      "Healthcare Scientists - Biomedical_Scientist (Retired 01 April 2013)",
  },
  {
    label: "Healthcare Scientists - Technician (Retired 01 April 2013)",
    value: "Healthcare Scientists - Technician (Retired 01 April 2013)",
  },
  {
    label: "Healthcare Scientists - Therapist (Retired 01 April 2013)",
    value: "Healthcare Scientists - Therapist (Retired 01 April 2013)",
  },
  {
    label: "Healthcare Scientists - Manager",
    value: "Healthcare Scientists - Manager",
  },
  {
    label: "Healthcare Scientists - Specialist Healthcare Scientist",
    value: "Healthcare Scientists - Specialist Healthcare Scientist",
  },
  {
    label: "Healthcare Scientists - Healthcare Scientist",
    value: "Healthcare Scientists - Healthcare Scientist",
  },
  {
    label: "Healthcare Scientists - Specialist Healthcare Science Practitioner",
    value: "Healthcare Scientists - Specialist Healthcare Science Practitioner",
  },
  {
    label: "Healthcare Scientists - Healthcare Science Practitioner",
    value: "Healthcare Scientists - Healthcare Science Practitioner",
  },
  {
    label: "Additional Clinical Services - Health Care Support Worker",
    value: "Additional Clinical Services - Health Care Support Worker",
  },
  {
    label: "Additional Clinical Services - Social Care Support Worker",
    value: "Additional Clinical Services - Social Care Support Worker",
  },
  {
    label: "Additional Clinical Services - Home Help",
    value: "Additional Clinical Services - Home Help",
  },
  {
    label: "Additional Clinical Services - Healthcare Assistant",
    value: "Additional Clinical Services - Healthcare Assistant",
  },
  {
    label: "Additional Clinical Services - Nursery Nurse",
    value: "Additional Clinical Services - Nursery Nurse",
  },
  {
    label: "Additional Clinical Services - Play Therapist",
    value: "Additional Clinical Services - Play Therapist",
  },
  {
    label: "Additional Clinical Services - Play Specialist",
    value: "Additional Clinical Services - Play Specialist",
  },
  {
    label: "Additional Clinical Services - Technician",
    value: "Additional Clinical Services - Technician",
  },
  {
    label: "Additional Clinical Services - Technical Instructor",
    value: "Additional Clinical Services - Technical Instructor",
  },
  {
    label: "Additional Clinical Services - Assistant/Associate Practitioner",
    value: "Additional Clinical Services - Assistant/Associate Practitioner",
  },
  {
    label: "Additional Clinical Services - Counsellor",
    value: "Additional Clinical Services - Counsellor",
  },
  {
    label: "Additional Clinical Services - Assistant",
    value: "Additional Clinical Services - Assistant",
  },
  {
    label: "Additional Clinical Services - Dental Surgery Assistant",
    value: "Additional Clinical Services - Dental Surgery Assistant",
  },
  {
    label: "Additional Clinical Services - Medical Laboratory Assistant",
    value: "Additional Clinical Services - Medical Laboratory Assistant",
  },
  {
    label: "Additional Clinical Services - Phlebotomist",
    value: "Additional Clinical Services - Phlebotomist",
  },
  {
    label: "Additional Clinical Services - Cytoscreener",
    value: "Additional Clinical Services - Cytoscreener",
  },
  {
    label: "Additional Clinical Services - Student Technician",
    value: "Additional Clinical Services - Student Technician",
  },
  {
    label: "Additional Clinical Services - Trainee Scientist",
    value: "Additional Clinical Services - Trainee Scientist",
  },
  {
    label: "Additional Clinical Services - Trainee Practitioner",
    value: "Additional Clinical Services - Trainee Practitioner",
  },
  {
    label: "Additional Clinical Services - Nursing Cadet",
    value: "Additional Clinical Services - Nursing Cadet",
  },
  {
    label: "Additional Clinical Services - Healthcare Cadet",
    value: "Additional Clinical Services - Healthcare Cadet",
  },
  {
    label: "Additional Clinical Services - Pre-reg Pharmacist",
    value: "Additional Clinical Services - Pre-reg Pharmacist",
  },
  {
    label: "Additional Clinical Services - Assistant Psychologist",
    value: "Additional Clinical Services - Assistant Psychologist",
  },
  {
    label: "Additional Clinical Services - Assistant Psychotherapist",
    value: "Additional Clinical Services - Assistant Psychotherapist",
  },
  {
    label: "Additional Clinical Services - Call Operator",
    value: "Additional Clinical Services - Call Operator",
  },
  {
    label: "Additional Clinical Services - Gateway Worker",
    value: "Additional Clinical Services - Gateway Worker",
  },
  {
    label: "Additional Clinical Services - Support, Time, Recovery Worker",
    value: "Additional Clinical Services - Support, Time, Recovery Worker",
  },
  {
    label:
      "Additional Clinical Services - Psychological Wellbeing Practitioner",
    value:
      "Additional Clinical Services - Psychological Wellbeing Practitioner",
  },
  {
    label:
      "Additional Clinical Services - Trainee Psychological Wellbeing Practitioner",
    value:
      "Additional Clinical Services - Trainee Psychological Wellbeing Practitioner",
  },
  {
    label: "Additional Clinical Services - Apprentice",
    value: "Additional Clinical Services - Apprentice",
  },
  {
    label: "Additional Clinical Services - Assistant Practitioner Nursing",
    value: "Additional Clinical Services - Assistant Practitioner Nursing",
  },
  {
    label:
      "Additional Clinical Services - Ambulance Care Assistant/Patient Transport Service Driver",
    value:
      "Additional Clinical Services - Ambulance Care Assistant/Patient Transport Service Driver",
  },
  {
    label: "Additional Clinical Services - Emergency Care Assistant",
    value: "Additional Clinical Services - Emergency Care Assistant",
  },
  {
    label: "Additional Clinical Services - Emergency Care Practitioner",
    value: "Additional Clinical Services - Emergency Care Practitioner",
  },
  {
    label: "Additional Clinical Services - Emergency Medical Dispatcher",
    value: "Additional Clinical Services - Emergency Medical Dispatcher",
  },
  {
    label: "Additional Clinical Services - Healthcare Science Associate",
    value: "Additional Clinical Services - Healthcare Science Associate",
  },
  {
    label: "Additional Clinical Services - Healthcare Science Assistant",
    value: "Additional Clinical Services - Healthcare Science Assistant",
  },
  {
    label: "Additional Clinical Services - Trainee Healthcare Scientist",
    value: "Additional Clinical Services - Trainee Healthcare Scientist",
  },
  {
    label:
      "Additional Clinical Services - Trainee Healthcare Science Practitioner",
    value:
      "Additional Clinical Services - Trainee Healthcare Science Practitioner",
  },
  {
    label:
      "Additional Clinical Services - Trainee Healthcare Science Associate",
    value:
      "Additional Clinical Services - Trainee Healthcare Science Associate",
  },
  {
    label: "Additional Clinical Services - Analyst",
    value: "Additional Clinical Services - Analyst",
  },
  {
    label: "Additional Clinical Services - Emergency Call Handler",
    value: "Additional Clinical Services - Emergency Call Handler",
  },
  {
    label: "Additional Clinical Services - Urgent Care Assistant",
    value: "Additional Clinical Services - Urgent Care Assistant",
  },
  {
    label: "Additional Clinical Services - Urgent Care Practitioner",
    value: "Additional Clinical Services - Urgent Care Practitioner",
  },
  {
    label:
      "Additional Clinical Services - Employment Support Worker or Advisor",
    value:
      "Additional Clinical Services - Employment Support Worker or Advisor",
  },
  {
    label: "Additional Clinical Services - Family Therapist",
    value: "Additional Clinical Services - Family Therapist",
  },
  {
    label: "Additional Clinical Services - Nursing Associate",
    value: "Additional Clinical Services - Nursing Associate",
  },
  {
    label: "Additional Clinical Services - Trainee Nursing Associate",
    value: "Additional Clinical Services - Trainee Nursing Associate",
  },
  {
    label: "Additional Clinical Services - Social Prescribing Link Worker",
    value: "Additional Clinical Services - Social Prescribing Link Worker",
  },
  {
    label: "Additional Clinical Services - Peer Support Worker",
    value: "Additional Clinical Services - Peer Support Worker",
  },
  {
    label: "Additional Clinical Services - Senior Peer Support Worker",
    value: "Additional Clinical Services - Senior Peer Support Worker",
  },
  {
    label: "Additional Clinical Services - Children's Wellbeing Practitioner",
    value: "Additional Clinical Services - Children's Wellbeing Practitioner",
  },
  {
    label:
      "Additional Clinical Services - Trainee Children's Wellbeing Practitioner",
    value:
      "Additional Clinical Services - Trainee Children's Wellbeing Practitioner",
  },
  {
    label:
      "Additional Clinical Services - Education Mental Health Practitioner",
    value:
      "Additional Clinical Services - Education Mental Health Practitioner",
  },
  {
    label:
      "Additional Clinical Services - Trainee Education Mental Health Practitioner",
    value:
      "Additional Clinical Services - Trainee Education Mental Health Practitioner",
  },
  {
    label: "Additional Clinical Services - Maternity Support Worker",
    value: "Additional Clinical Services - Maternity Support Worker",
  },
  {
    label: "Additional Clinical Services - Health and Wellbeing Coach",
    value: "Additional Clinical Services - Health and Wellbeing Coach",
  },
  {
    label: "Additional Clinical Services - Care Coordinator",
    value: "Additional Clinical Services - Care Coordinator",
  },
  {
    label: "Administrative and Clerical - Clerical Worker",
    value: "Administrative and Clerical - Clerical Worker",
  },
  {
    label: "Administrative and Clerical - Receptionist",
    value: "Administrative and Clerical - Receptionist",
  },
  {
    label: "Administrative and Clerical - Secretary",
    value: "Administrative and Clerical - Secretary",
  },
  {
    label: "Administrative and Clerical - Personal Assistant",
    value: "Administrative and Clerical - Personal Assistant",
  },
  {
    label: "Administrative and Clerical - Medical Secretary",
    value: "Administrative and Clerical - Medical Secretary",
  },
  {
    label: "Administrative and Clerical - Officer",
    value: "Administrative and Clerical - Officer",
  },
  {
    label: "Administrative and Clerical - Manager",
    value: "Administrative and Clerical - Manager",
  },
  {
    label: "Administrative and Clerical - Senior Manager",
    value: "Administrative and Clerical - Senior Manager",
  },
  {
    label: "Administrative and Clerical - Technician",
    value: "Administrative and Clerical - Technician",
  },
  {
    label: "Administrative and Clerical - Accountant",
    value: "Administrative and Clerical - Accountant",
  },
  {
    label: "Administrative and Clerical - Librarian",
    value: "Administrative and Clerical - Librarian",
  },
  {
    label: "Administrative and Clerical - Interpreter",
    value: "Administrative and Clerical - Interpreter",
  },
  {
    label: "Administrative and Clerical - Analyst",
    value: "Administrative and Clerical - Analyst",
  },
  {
    label: "Administrative and Clerical - Adviser",
    value: "Administrative and Clerical - Adviser",
  },
  {
    label: "Administrative and Clerical - Researcher",
    value: "Administrative and Clerical - Researcher",
  },
  {
    label: "Administrative and Clerical - Control Assistant",
    value: "Administrative and Clerical - Control Assistant",
  },
  {
    label: "Administrative and Clerical - Architect",
    value: "Administrative and Clerical - Architect",
  },
  {
    label: "Administrative and Clerical - Lawyer",
    value: "Administrative and Clerical - Lawyer",
  },
  {
    label: "Administrative and Clerical - Surveyor",
    value: "Administrative and Clerical - Surveyor",
  },
  {
    label: "Administrative and Clerical - Chair",
    value: "Administrative and Clerical - Chair",
  },
  {
    label: "Administrative and Clerical - Chief Executive",
    value: "Administrative and Clerical - Chief Executive",
  },
  {
    label: "Administrative and Clerical - Finance Director",
    value: "Administrative and Clerical - Finance Director",
  },
  {
    label: "Administrative and Clerical - Other Executive Director",
    value: "Administrative and Clerical - Other Executive Director",
  },
  {
    label: "Administrative and Clerical - Board Level director",
    value: "Administrative and Clerical - Board Level director",
  },
  {
    label: "Administrative and Clerical - Non-executive Director",
    value: "Administrative and Clerical - Non-executive Director",
  },
  {
    label: "Administrative and Clerical - Childcare Coordinator",
    value: "Administrative and Clerical - Childcare Coordinator",
  },
  {
    label: "Administrative and Clerical - Apprentice",
    value: "Administrative and Clerical - Apprentice",
  },
  {
    label: "Administrative and Clerical - Non-Emergency Call Handler",
    value: "Administrative and Clerical - Non-Emergency Call Handler",
  },
  {
    label: "Administrative and Clerical - Non-Emergency Medical Dispatcher",
    value: "Administrative and Clerical - Non-Emergency Medical Dispatcher",
  },
  {
    label:
      "Administrative and Clerical - Library and Knowledge Services Professional",
    value:
      "Administrative and Clerical - Library and Knowledge Services Professional",
  },
  {
    label:
      "Administrative and Clerical - Library and Knowledge Services Paraprofessional",
    value:
      "Administrative and Clerical - Library and Knowledge Services Paraprofessional",
  },
  {
    label: "Estates and Ancillary - Support Worker",
    value: "Estates and Ancillary - Support Worker",
  },
  {
    label: "Estates and Ancillary - Housekeeper",
    value: "Estates and Ancillary - Housekeeper",
  },
  {
    label: "Estates and Ancillary - Cook",
    value: "Estates and Ancillary - Cook",
  },
  {
    label: "Estates and Ancillary - Porter",
    value: "Estates and Ancillary - Porter",
  },
  {
    label: "Estates and Ancillary - Driver",
    value: "Estates and Ancillary - Driver",
  },
  {
    label: "Estates and Ancillary - Telephonist",
    value: "Estates and Ancillary - Telephonist",
  },
  {
    label: "Estates and Ancillary - Gardener/Groundsperson",
    value: "Estates and Ancillary - Gardener/Groundsperson",
  },
  {
    label: "Estates and Ancillary - Technician",
    value: "Estates and Ancillary - Technician",
  },
  {
    label: "Estates and Ancillary - Electrician",
    value: "Estates and Ancillary - Electrician",
  },
  {
    label: "Estates and Ancillary - Fitter",
    value: "Estates and Ancillary - Fitter",
  },
  {
    label: "Estates and Ancillary - Assistant",
    value: "Estates and Ancillary - Assistant",
  },
  {
    label: "Estates and Ancillary - Labourer",
    value: "Estates and Ancillary - Labourer",
  },
  {
    label: "Estates and Ancillary - Plumber",
    value: "Estates and Ancillary - Plumber",
  },
  {
    label: "Estates and Ancillary - Carpenter",
    value: "Estates and Ancillary - Carpenter",
  },
  {
    label: "Estates and Ancillary - Bricklayer",
    value: "Estates and Ancillary - Bricklayer",
  },
  {
    label: "Estates and Ancillary - Painter/Decorator",
    value: "Estates and Ancillary - Painter/Decorator",
  },
  {
    label: "Estates and Ancillary - Work Analyst",
    value: "Estates and Ancillary - Work Analyst",
  },
  {
    label: "Estates and Ancillary - Chargehand",
    value: "Estates and Ancillary - Chargehand",
  },
  {
    label: "Estates and Ancillary - Supervisor",
    value: "Estates and Ancillary - Supervisor",
  },

  {
    label: "Estates and Ancillary - Engineer",
    value: "Estates and Ancillary - Engineer",
  },
  {
    label: "Estates and Ancillary - Building Officer",
    value: "Estates and Ancillary - Building Officer",
  },
  {
    label: "Estates and Ancillary - Maintenance Craftsperson",
    value: "Estates and Ancillary - Maintenance Craftsperson",
  },
  {
    label: "Estates and Ancillary - Building Craftsperson",
    value: "Estates and Ancillary - Building Craftsperson",
  },
  {
    label: "Estates and Ancillary - Mechanic",
    value: "Estates and Ancillary - Mechanic",
  },
  {
    label: "Estates and Ancillary - Apprentice",
    value: "Estates and Ancillary - Apprentice",
  },
  {
    label: "Estates and Ancillary - Cleaner",
    value: "Estates and Ancillary - Cleaner",
  },
  {
    label: "Supplementary Role - Assessor",
    value: "Supplementary Role - Assessor",
  },
  {
    label: "Supplementary Role - Clinical Supervisor",
    value: "Supplementary Role - Clinical Supervisor",
  },
  {
    label: "Supplementary Role - Educational Supervisor",
    value: "Supplementary Role - Educational Supervisor",
  },
  { label: "Supplementary Role - Tutor", value: "Supplementary Role - Tutor" },
  {
    label: "Social Care - Deputy Care Home Manager",
    value: "Social Care - Deputy Care Home Manager",
  },
  {
    label: "Social Care - Deputy Manager",
    value: "Social Care - Deputy Manager",
  },
  {
    label: "Social Care - Senior Care Worker",
    value: "Social Care - Senior Care Worker",
  },
  { label: "Social Care - Team Leader", value: "Social Care - Team Leader" },
  { label: "Social Care - Care Worker", value: "Social Care - Care Worker" },
];
