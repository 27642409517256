const moment = require("moment-timezone");

export default function datechangeHandler(date) {
  const localDate = new Date(date);
  const localDateString = localDate.toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const localTimeString = localDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  let resultantstring = `${localDateString} ${localTimeString}` || "";

  return date ? resultantstring : '--';
}

export const getCurrentDateFormatted = () => {
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const getCurrentWeekDatesFormatted = () => {
  const today = new Date();
  const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
  const endOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay() + 6)
  );

  const startDate = formatDate(startOfWeek);
  const endDate = formatDate(endOfWeek);

  return { startDate, endDate };
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatToDateString = (date) => {
  const updateddate = new Date(date).toDateString();

  return updateddate;
};

export const getDatesInRange = (startDate, endDate) => {
  const dates = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates.map((date) => date.toISOString().split("T")[0]);
};

export function convertToUTCWithTimezone(dateString, startingTime, timezone) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const datetimeString = `${dateString}T${startingTime}:00`;
  const localDateTime = moment.tz(datetimeString, userTimeZone);

  // Convert to UTC
  const utcDateTime = localDateTime.utc().format();
  return utcDateTime;
}

export function add30MinutesToTime(timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);

  // Create a new Date object with the given time
  const dateObj = new Date();
  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);

  // Add 30 minutes to the time
  dateObj.setMinutes(dateObj.getMinutes() + 30);

  // Format the updated time as "hh:mm"
  const updatedTime = `${String(dateObj.getHours()).padStart(2, "0")}:${String(
    dateObj.getMinutes()
  ).padStart(2, "0")}`;

  return updatedTime;
}
